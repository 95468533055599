import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faG, faL, faM, faP, faS, faV} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import {ORIS_URL, TRACKING_URL} from "../../helpers/Constants";


const EventClassLinks = ({eventId, classId, className, applied=-1, startlists, results, isTeam=false, hasTracking=false, trackingId=""}) => {
  return <>
    <td title="Rozcestník kategorie">{className}</td>
    {applied !== -1 && (<td className="ps-0 pe-0">{applied !== 0 ? applied : ""}</td>)}
    <td title="Přihlášky" align="center"><Link to={ORIS_URL + "PrehledPrihlasenych?id=" + eventId + "&class=" + classId} target="_blank"><FontAwesomeIcon icon={faP} style={{color: "LimeGreen"}}/></Link></td>
    {isTeam ?
      <td title="Soupisky" align="center"><Link to={ORIS_URL + "PrehledPrihlasenych?id=" + eventId + "&class=" + classId + "&teams=1"} target="_blank"><FontAwesomeIcon icon={faS} style={{color: "DeepPink"}}/></Link></td> :
      <td title="Startovka" align="center">{startlists ?
        <Link to={ORIS_URL + "Startovka?id=" + eventId + "&class=" + classId} target="_blank"><FontAwesomeIcon icon={faS} style={{color: "DeepPink"}}/></Link> :
        <FontAwesomeIcon icon={faS} style={{color: "gray"}}/>}
      </td>}
    {hasTracking && <td title="GPS tracking" align="center">{trackingId !== "" ?
      <Link to={TRACKING_URL + trackingId} target="_blank"><FontAwesomeIcon icon={faG} style={{color: "Turquoise"}}/></Link> :
      <FontAwesomeIcon icon={faG} style={{color: "gray"}}/>}
    </td>}
    <td title="Výsledky" align="center">{results ?
      <Link to={ORIS_URL + (isTeam ? "VysledkyStafet?id=" : "Vysledky?id=") + eventId + "&class=" + classId} target="_blank"><FontAwesomeIcon icon={faV} style={{color: "DarkViolet"}}/></Link> :
      <FontAwesomeIcon icon={faV} style={{color: "gray"}}/>}</td>
    <td title="Mezičasy" align="center">{results ? <Link to={ORIS_URL + "Mezicasy?compid=" + eventId + "&classid=" + classId} target="_blank"><FontAwesomeIcon icon={faM} style={{color: "MediumBlue"}}/></Link> : <FontAwesomeIcon icon={faM} style={{color: "gray"}}/>}</td>
    <td title="Livelox" align="center">{results ? <Link
      to={"https://www.livelox.com/Viewer?eventExternalIdentifier=24%3a" + eventId + "&classExternalId=" + classId} target="_blank"><FontAwesomeIcon icon={faL} style={{color: "DarkOrange"}}/></Link> : <FontAwesomeIcon icon={faL} style={{color: "gray"}}/>}
    </td>
  </>
}

export default EventClassLinks;