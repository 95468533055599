import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faXmark} from "@fortawesome/free-solid-svg-icons";
import React from "react";


const NoYesIndicator = ({condition, large=false, allBlack=false}) => {
  if (large === true)
    return condition ? <FontAwesomeIcon icon={faCheck} color="green" size="lg" className="pointing"/> : <FontAwesomeIcon icon={faXmark} color="red" size="lg" className="pointing"/>
  return condition ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faXmark} color={allBlack ? "" : "red"} />
}

export default NoYesIndicator;