import Button from "react-bootstrap/Button";
import Form from 'react-bootstrap/Form';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {getUserDetail, editUser, setCsrfToken} from "../../dao";
import React, {useState} from "react";
import { useEffect } from "react";
import Alert from 'react-bootstrap/Alert';
import {Link, useParams} from "react-router-dom";
import SuccessAlert from "../../components/overlays/SuccessAlert";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faEyeSlash, faGavel, faQuestionCircle} from "@fortawesome/free-solid-svg-icons";
import ErrorAlertFullscreen from "../../components/overlays/ErrorAlertFullscreen";
import {handleErrorLoading, handleErrorSubmit, setBrowserTabText} from "../../helpers/Functions";
import InputGroup from "react-bootstrap/InputGroup";
import {AccountTypeOptions, GroupOptions, MembershipOptions, RegionCustomizeOptions, RoleOptions} from "../../helpers/Constants";
import PageContainer from "../../layout/PageContainer";
import {isMobileDevice} from "../../helpers/Device";
import FormField from "../../components/form/FormField";
import FormSelectDict from "../../components/form/FormSelectDict";
import FormCheckbox from "../../components/form/FormCheckbox";
import Loader from "../../components/overlays/Loader";
import Modal from "react-bootstrap/Modal";
import ModalContentAccount from "../../components/parts/ModalContentAccount";
import ModalContentGroups from "../../components/parts/ModalContentGroups";

const Settings = () => {
  const { user } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [errorRemote, setErrorRemote] = useState("");
  const [success, setSuccess] = useState("");

  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [regNumber, setRegNumber] = useState("");
  const [accountType, setAccountType] = useState("");
  const [role, setRole] = useState("");
  const [membership, setMembership] = useState("");
  const [group, setGroup] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [officialName, setOfficialName] = useState("");
  const [officialSurname, setOfficialSurname] = useState("");
  const [birthdate, setBirthdate] = useState(undefined);
  const [personalNumber, setPersonalNumber] = useState("");
  const [nationality, setNationality] = useState("");

  const [mail1, setMail1] = useState("");
  const [mail2, setMail2] = useState("");
  const [phone, setPhone] = useState("");
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [country, setCountry] = useState("");

  const [iofId, setIofId] = useState(0);
  const [siCards, setSiCards] = useState("");
  const [levelColors, setLevelColors] = useState(false);
  const [includedRegion, setIncludedRegion] = useState("");
  const [watched, setWatched] = useState("");
  const [data, setData] = useState({});
  const [generalHelp, setGeneralHelp] = useState(false);
  const [groupHelp, setGroupHelp] = useState(false);

  useEffect(() => {
    setLoading(true);
    getUserDetail(user)
      .then((response) => {
        if (response.ok)
          return response.json();
        setErrorRemote(handleErrorLoading(response));
      })
      .then((response) => {
        setName(response?.name);
        setSurname(response?.surname);
        setRegNumber(response?.reg_number);
        setIofId(response?.iof_id);
        if(response?.iof_id == null)
          setIofId(0);
        setSiCards(response?.si_cards);
        setLevelColors(response?.level_colors);
        setIncludedRegion(response?.included_region);
        setWatched(response?.watched);
        if (response?.extended) {
          setBirthdate(response?.birthdate);
          setPersonalNumber(response?.birth_number);
          setNationality(response?.nationality);

          setUsername(response?.username);
          setPhone(response?.phone);
          setMail1(response?.mail1);
          setMail2(response?.mail2);
          setStreet(response?.street);
          setCity(response?.city);
          setZipCode(response?.zip_code);
          setCountry(response?.country);
          if (response?.superuser) {
            setAccountType(response?.account);
            setRole(response?.role);
            setMembership(response?.membership);
            setGroup(response?.group);
            setOfficialName(response?.official_name);
            setOfficialSurname(response?.official_surname);
          }
        }
        setData(response);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  let handleSubmit = async (e) => {
    e.preventDefault();
    if(password !== password2){
      setError("Hesla se neshodují");
      return;
    }
    let body = {
      "iof_id": iofId,
      "si_cards": siCards,
      "level_colors": levelColors,
      "included_region": includedRegion,
      "watched": watched,
    };
    if (data?.extended) {
      body = Object.assign({}, body, {
        "username": username,
        "password": password,
        "mail1": mail1,
        "mail2": mail2,
        "phone": phone,
        "street": street,
        "city": city,
        "zip_code": zipCode,
        "country": country,
      })
      if (data?.superuser) {
        body = Object.assign({}, body, {
          "name": name,
          "surname": surname,
          "official_name": officialName,
          "official_surname": officialSurname,
          "reg_number": regNumber,
          "account": accountType,
          "role": role,
          "membership": membership,
          "group": group,
          "birthdate": birthdate,
          "birth_number": personalNumber,
          "nationality": nationality,
        })
      }
    }
    const res = await editUser(user, body);
    if(res.ok){
      const cookie = res.headers.get("X-Csrftoken");
      if (cookie != null)
        setCsrfToken(cookie);
      setError("");
      setSuccess("Uloženo");
    }else{
      setError(handleErrorSubmit(res, "Nezdařilo se uložit", "Uživatelské jméno"));
    }
  };

  const FormError = () => {
    if(error !== ""){
      return (<Alert variant="danger">{error}</Alert>);
    }
    return null;
  }

  if (loading)
    return <Loader />
  if (errorRemote !== "")
    return <ErrorAlertFullscreen error={errorRemote} />

  const SystemData = () => {
    return (<>
      <h4>Údaje Tapisu</h4>
      {data.superuser && <>
        <Row>
          <Col md xs={6}>
            <FormField label={"Jméno Tapis"} value={name} setValue={setName} valueLimit={64} digits={"none"} controlId={"name"}/>
          </Col>
          <Col md xs={6}>
            <FormField label={"Příjmení Tapis"} value={surname} setValue={setSurname} valueLimit={64} digits={"none"} controlId={"surname"}/>
          </Col>
          <Col md>
            <FormField label={"Registrační číslo"} value={regNumber} setValue={setRegNumber} valueLimit={7} digits={"none"} controlId={"registration"}/>
          </Col>
        </Row>
        <Row>
          <Col lg xs={7}>
            <FormSelectDict label={"Účet (dluh " + data?.difference + " Kč)"} value={accountType} setValue={setAccountType} options={AccountTypeOptions}
                            controlId={"account"} help={<FontAwesomeIcon className="pointing" icon={faQuestionCircle} onClick={() => setGeneralHelp(true)}/>}/>
          </Col>
          <Col lg xs={5}>
            <FormSelectDict label={"Role"} value={role} setValue={setRole} options={RoleOptions} controlId={"role"}
                            help={<FontAwesomeIcon className="pointing" icon={faQuestionCircle} onClick={() => setGeneralHelp(true)}/>}/>
          </Col>
          <Col lg xs={6}>
            <FormSelectDict label={"Členství"} value={membership} setValue={setMembership} options={MembershipOptions} controlId={"membership"}
                            help={<FontAwesomeIcon className="pointing" icon={faQuestionCircle} onClick={() => setGeneralHelp(true)}/>}/>
          </Col>
          <Col lg xs={6}>
            <FormSelectDict label={"Skupina"} value={group} setValue={setGroup} options={GroupOptions} controlId={"group"}
                            help={<FontAwesomeIcon className="pointing" icon={faQuestionCircle} onClick={() => setGroupHelp(true)}/>}/>
          </Col>
        </Row>
      </>}
      <Row>
        <Col md>
          <FormField label={"Uživatelské jméno"} value={username} setValue={setUsername} valueLimit={150} digits={"none"} controlId={"username"}/>
        </Col>
        <Col md>
          <Form.Group className="mb-3" controlId="password" key="password">
            <Form.Label>Nové heslo:</Form.Label>
            <InputGroup className="mb-3">
              <Form.Control
                type={showPassword ? 'text' : 'password'} value={password} autoComplete="new-password"
                onChange={(e) => setPassword(e.target.value)}
              />
              <InputGroup.Text style={{width: "45px", alignItems: "center"}}>
                <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} onClick={() => {
                  setShowPassword(!showPassword)
                }}/>
              </InputGroup.Text>
            </InputGroup>
          </Form.Group>
        </Col>
        <Col md>
          <FormField label={"Potvrdit heslo"} value={password2} setValue={setPassword2} type={"password"} digits={"none"} controlId={"confirm"}/>
        </Col>
        <hr/>
      </Row>
    </>)
  }

  const PersonalData = () => {
    return (<>
      <h4>Osobní údaje</h4>
      {data.superuser && <>
        <Row>
          <Col xs={6}>
            <FormField label={"Jméno oficiální"} value={officialName} setValue={setOfficialName} valueLimit={64} digits={"none"} controlId={"officialName"}/>
          </Col>
          <Col xs={6}>
            <FormField label={"Příjmení oficiální"} value={officialSurname} setValue={setOfficialSurname} valueLimit={64} digits={"none"} controlId={"officialSurname"}/>
          </Col>
        </Row>
      </>}
      <Row>
        <Col sm={5}>
          <FormField label={"Rodné číslo"} value={personalNumber} setValue={setPersonalNumber} type={"slashed"} valueLimit={11} digits={"none"} disabled={!data?.superuser} controlId={"personalNumber"}/>
        </Col>
        <Col sm={4} xs={7}>
          <FormField label={"Datum narození"} type={"date"} value={birthdate} setValue={setBirthdate} disabled={!data?.superuser} controlId={"birthdate"}/>
        </Col>
        <Col sm={3} xs={5}>
          <FormField label={"Občan. (zkr.)"} value={nationality} setValue={setNationality} valueLimit={2} digits={"none"} disabled={!data?.superuser} controlId={"nationality"}/>
        </Col>
        <hr/>
      </Row>
    </>)
  }

  const ContactData = () => {
    return (<>
      <h4>Kontaktní údaje</h4>
      <Row>
        <Col>
          <FormField label={"Email 1"} type={"email"} value={mail1} setValue={setMail1} valueLimit={64} digits={"none"} controlId={"email1"}/>
        </Col>
        <Col md>
          <FormField label={"Email 2"} type={"email"} value={mail2} setValue={setMail2} valueLimit={64} digits={"none"} controlId={"email2"}/>
        </Col>
        <Col md>
          <FormField label={"Telefon"} value={phone} setValue={setPhone} valueLimit={16} digits={"none"} controlId={"phone"}/>
        </Col>
      </Row>
      <Row>
        <Col lg>
          <FormField label={"Ulice a ČP"} value={street} setValue={setStreet} valueLimit={128} digits={"none"} controlId={"street"}/>
        </Col>
        <Col lg={3} sm>
          <FormField label={"Město"} value={city} setValue={setCity} valueLimit={64} digits={"none"} controlId={"city"}/>
        </Col>
        <Col lg={3} sm={4} xs={7}>
          <FormField label={"PSČ"} value={zipCode} setValue={setZipCode} valueLimit={6} digits={"none"} controlId={"zipCode"}/>
        </Col>
        <Col lg={2} sm={3} xs={5}>
          <FormField label={"Stát (zkr.)"} value={country} setValue={setCountry} valueLimit={2} digits={"none"} controlId={"country"}/>
        </Col>
        <hr/>
      </Row>
    </>)
  }

  setBrowserTabText(user == null ? 'Nastavení' : 'Nastavení | ' + name + ' ' + surname);
  return (
    <PageContainer>
      <Row>
        <Col xs={8}>
          {user == null ? <h1>Můj Tapis účet</h1> : <h1>Upravit: {name} {surname}</h1>}
        </Col>
        {data?.extended && <Col className="text-end"><Link to={"/ucet/" + (user == null ? "" : user + "/") + "prava/"}>
          <Button className="mb-3" variant="secondary"><FontAwesomeIcon icon={faGavel}/> Práva</Button></Link></Col>}
      </Row>
      <br/>
      <FormError/>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col md>
            <h4>Závody</h4>
            <Row>
              <Col md={8}>
                <FormField label={"Čipy (oddělené čárkou)"} value={siCards} setValue={setSiCards} controlId={"si"}/>
              </Col>
              <Col md={4}>
                <FormField label={"IOF ID"} type={"number"} value={iofId} setValue={setIofId} valueLimit={10} digits={"none"} controlId={"iof"}/>
              </Col>
              <hr/>
            </Row>
            {user == null && <>
              <h4>Personalizace</h4>
              <Row className="align-items-end">
                <Col md={12}>
                  <FormField label={"Sledované účty (registračky oddělené čárkou)"} help={"Sledované účty jsou přidány do seznamu klubových přihlášek/startovek"} value={watched} setValue={setWatched} controlId={"watched"}/>
                </Col>
                <Col md>
                  <FormSelectDict label={"Oblast navíc k \"Doporučeným\" (ČR+Č+P+StČ)"} value={includedRegion} setValue={setIncludedRegion}
                                  options={RegionCustomizeOptions} controlId={"includedRegion"}
                                  help={<FontAwesomeIcon icon={faQuestionCircle} title="Oblast navíc bude zahrnuta k ostatním doporučeným oblastem"/>}/>
                </Col>
                <Col md>
                  <FormCheckbox label={"Barevné označení úrovně závodů v kalendáři"} value={levelColors} setValue={setLevelColors} controlId={"levelColors"}/>
                </Col>
                <hr/>
              </Row>
            </>}
            {data?.extended && <>{SystemData()}</>}
            {data?.extended && <>{PersonalData()}</>}
            {data?.extended && <>{ContactData()}</>}
          </Col>
          <Col xs={isMobileDevice() ? 12 : "auto"} className="text-end">
            <Button className="mb-3" variant="primary" type="submit">Uložit změny</Button>
          </Col>
        </Row>
      </Form>
      <SuccessAlert title={success} handleClose={() => setSuccess("")}/>
      <Modal show={generalHelp} onHide={()=>setGeneralHelp(false)}>
        <ModalContentAccount />
      </Modal>
      <Modal show={groupHelp} onHide={()=>setGroupHelp(false)}>
        <ModalContentGroups />
      </Modal>
    </PageContainer>
  );
};

export default Settings;