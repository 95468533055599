
const getColor = ({ value, border }) => {
    if (value < 0)
        return `rgb(64, 128, 64)`;
    if (value === 0)
        return `rgb(0, 0, 0)`;
    const red = Math.min(255, Math.round(255 - (value - border) * 255 / border));
    const green = Math.min(255, Math.round((border - value) * 255 / border));
    const blue = Math.min(255, Math.round((value - border) * 255 / border / 2));

    return `rgb(${red}, ${green}, ${blue})`;
};
const textStyle = ({ value, border }) => {return value === 0 ? {} : {color: getColor({value, border}),}};

const ColorChangingText = ({ value, border }) => {
    return (
        <div style={textStyle({ value, border })}>
            {value}
        </div>
    );
};

export default ColorChangingText;