const themeConfig = {
  TAP: {
    SystemStartYear: 2024,
    HomeClub: "TAP",
    HomeClubBankAccount: "2802097915/2010",
    TabTitle: 'Tapis – Tretrácký informační systém',

    homeSection: 'Domů',
    clubSection: 'Klub',
    cupsSection: 'Žebříčky',
    myRacesSection: 'Mé závody',

    entryPageLocalCreate: 'Přihlásit se',
    entryPageRemoteCreate: 'Přihlásit se',
    entryPagePreliminaryCreate: 'Přihlásit se',
    entryPageEdit: 'Upravit přihlášku',
    entryOthersLocal: 'Přihlásit ostatní',
    entryOthersRemote: 'Přihlásit ostatní',

    servicesPage: 'Služby',

    clubRankingPage: 'Klubový ranking',
  },
  PGP: {
    SystemStartYear: 2024,
    HomeClub: "PGP",
    HomeClubBankAccount: "???",
    TabTitle: 'PragIS – Členská zóna SK Praga',

    homeSection: 'Kalendář akcí',
    clubSection: 'Seznam členů',
    cupsSection: '',
    myRacesSection: '',

    entryPageLocalCreate: 'Přihlásit se',
    entryPageRemoteCreate: '',
    entryPagePreliminaryCreate: '',
    entryPageEdit: 'Přihláška dopravy a ubytování',
    entryOthersLocal: 'Přihlásit ostatní',
    entryOthersRemote: '',

    servicesPage: '',

    clubRankingPage: 'Klubový ranking',
  },
};

const currentTheme = process.env.REACT_APP_THEME || 'TAP';
export const labels = themeConfig[currentTheme];
