import { useState } from 'react';

export default function useUserData() {
  const getUserData = () => {
    const userDataString = localStorage.getItem('userData');
    const userUserData = JSON.parse(userDataString);
    return userUserData;
  };
  
  const [userData, setUserData] = useState(getUserData());

  const saveUserData = userUserData => {
    localStorage.setItem('userData', JSON.stringify(userUserData));
    setUserData(userUserData);
  };

  return {
    setUserData: saveUserData,
    userData
  }
}