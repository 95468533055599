import Table from "react-bootstrap/Table";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {Link} from "react-router-dom";
import {getCups} from "../../dao";
import React, { useState, useEffect } from 'react';
import { isMobileDevice } from "../../helpers/Device";
import {
  handleErrorLoading, selectEnrollVariant, setBrowserTabText, YearOptions
} from "../../helpers/Functions"
import iconoris from "../../static/iconoris.png";
import {faInfo, faRankingStar} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TermIndicator from "../../components/visual/TermIndicator";
import ErrorAlertFullscreen from "../../components/overlays/ErrorAlertFullscreen";
import {HomeClub, ORIS_URL, RegionShortOptions, SportTypeOptions} from "../../helpers/Constants";
import PageContainer from "../../layout/PageContainer";
import LoadingIcon from "../../components/overlays/LoadingIcon";
import Button from "react-bootstrap/Button";
import FormSelectArray from "../../components/form/FormSelectArray";
import FormSelectDict from "../../components/form/FormSelectDict";


const Cups = () => {
  const [loading, setLoading] = useState(true);
  const [errorRemote, setErrorRemote] = useState("");
  const [year, setYear] = useState((new Date()).getFullYear());
  const [sport, setSport] = useState("0");
  const [region, setRegion] = useState("");
  const [data, setData] = useState({});

  useEffect(() => {
    setLoading(true);
    getCups(year, sport, region)
      .then((response) => {
        if (!response.ok) {
          setErrorRemote(handleErrorLoading(response));
          return [];
        }
        return response.json()
      })
      .then((response) => {
        setData(response);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, [year, sport, region]);

  const TableDataHiddenLink = ({children, id, title}) => {
    let alignment;
    if (children == null || children === "") {
      children = " ";
      alignment = "center";
    } else {
      alignment = "left";
    }
    return (
      <td align={alignment} title={title}><Link to={`/akce/${id}`} style={{textDecoration: "none", color: "inherit"}}>{children}</Link></td>);
  }

  const EventTable = () => {
    if (loading)
      return <LoadingIcon />;
    return isMobileDevice() ? <MobileTable events={data?.events} today={data?.today}/> : <DesktopTable events={data?.events} today={data?.today}/>;
  }

  if (errorRemote !== "")
    return <ErrorAlertFullscreen error={errorRemote}/>

  const Filters = () => {
    return (<Row className="align-items-end mb-1">
      <Col>
        <FormSelectArray label={"Rok"} size={"sm"} value={year} setValue={setYear} options={YearOptions()} className={"mb-2"} controlId={"year"} />
      </Col>
      <Col>
        <FormSelectDict label={"Sport"} size={"sm"} value={sport} setValue={setSport} options={SportTypeOptions} className={"mb-2"} controlId={"sport"} />
      </Col>
      <Col>
        <FormSelectDict label={"Oblast"} size={"sm"} value={region} setValue={setRegion} options={RegionShortOptions} className={"mb-2"} controlId={"region"} />
      </Col>
    </Row>)
  }

  setBrowserTabText('Žebříčky');
  return (
    <PageContainer>
      <Row>
        <Col><h1>Žebříčky</h1></Col>
        <Col xs="auto"><Link to={ORIS_URL + "KlubovyRankingVeteranu?club=" + HomeClub} target="_blank"><Button className="mb-2 tapis-95" variant="outline-secondary"><FontAwesomeIcon icon={faRankingStar} /> Ranking veteránů</Button></Link></Col>
        <Col xs="auto"><Link to={`/klub/ranking`}><Button variant="outline-primary" className="mb-2 tapis-95"><FontAwesomeIcon icon={faRankingStar} /> Klubový ranking</Button></Link></Col>
      </Row>
      <Filters/>
      <EventTable />
    </PageContainer>
  );

  function MobileTable(props) {
    return (
      <Table striped bordered hover responsive>
        <thead>
        <tr>
          <th>Název</th>
          <th>Obl.</th>
          <th>Úr.</th>
        </tr>
        </thead>
        <tbody>
        <MobileTableContent events={props.events} today={props.today}/>
        </tbody>
      </Table>
    );

    function MobileTableContent(props) {
      function MobileTableRow(props) {
        return (
          <tr className={selectEnrollVariant(props.element, "table-")}>
            <TableDataHiddenLink id={props.element.id}>{props.element.title}</TableDataHiddenLink>
            <TableDataHiddenLink id={props.element.id}>{props.element.region}</TableDataHiddenLink>
            <TableDataHiddenLink id={props.element.id}>{props.element.level_label}<br/><TermIndicator event={props.element}/></TableDataHiddenLink>
          </tr>
        )
      }

      const rows = [];
      for (const i in props?.events) {
        const element = props?.events[i];
        rows.push(<MobileTableRow element={element} key={element.id}/>);
      }
      return (rows);
    }
  }

  function DesktopTable(props) {
    return (
      <Table striped bordered hover responsive>
        <thead>
        <tr>
          <th>Název</th>
          <th title="Oblast">Obl.</th>
          <th title="Úroveň">Úr.</th>
          <th title="Přihlášení">Př.</th>
          <th></th>
          <th title="Termín přihlášek" style={{textAlign: "center"}}><FontAwesomeIcon icon={faInfo}/></th>
        </tr>
        </thead>
        <tbody>
        <DesktopTableContent events={props.events} today={props.today}/>
        </tbody>
      </Table>
    );

    function DesktopTableContent(props) {
      const rows = [];
      for (const i in props?.events) {
        const element = props?.events[i];
        rows.push(<DesktopTableRow element={element} key={element.id}/>);
      }
      return (rows);

      function DesktopTableRow(props) {
        return (
          <tr id={"eventrow" + props.element.id} className={selectEnrollVariant(props.element, "table-")}>
            <TableDataHiddenLink id={props.element.id}>{props.element.title}</TableDataHiddenLink>
            <TableDataHiddenLink id={props.element.id}>{props.element.region}</TableDataHiddenLink>
            <TableDataHiddenLink id={props.element.id}>{props.element.level_label}</TableDataHiddenLink>
            <td align="center">
              <Link to={ORIS_URL + "Vysledky?id=" + props.element.oris_id + "&club=" + HomeClub} className="table-link no-decoration">{props.element.club_entries !== 0 ? props.element.club_entries : ""}</Link>
            </td>
            <td align="center">{parseEventType(props.element.oris_id)}</td>
            <td style={{paddingRight: "2px"}}><TermIndicator event={props.element} date={true}/></td>
          </tr>
        )
      }
    }
  }

  function parseEventType(orisId) {
    return (
      <a href={ORIS_URL + "Zavod?id=" + orisId} target="_blank" rel="noreferrer">
        <img
          src={iconoris}
          height="20"
          className="d-inline-block align-top"
          alt="ORIS"
        />
      </a>);
  }
}

export default Cups;