import Button from "react-bootstrap/Button";
import Form from 'react-bootstrap/Form';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Link, useParams} from "react-router-dom";
import {deleteEnroll, sendCampEnroll} from "../../dao";
import React, { useState, useEffect, useRef } from 'react';
import {SelectOptionsDict, SelectOptionsSet} from "../../components/form/SelectOptions";
import { useNavigate } from "react-router-dom";
import {faCircleInfo, faLightbulb, faQuestionCircle} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ErrorAlert from "../../components/overlays/ErrorAlert";
import SuccessAlert from "../../components/overlays/SuccessAlert";
import {handleErrorSubmit, setBrowserTabText} from "../../helpers/Functions";
import PageContainer from "../../layout/PageContainer";
import {InterestExtendedOptions, PunchingOptions} from "../../helpers/Constants";
import Loader from "../../components/overlays/Loader";
import InputGroup from "react-bootstrap/InputGroup";
import OverlayTooltip from "../../components/overlays/OverlayTooltip";
import FormField from "../../components/form/FormField";
import EventDate from "../../components/parts/EventDate";
import PageHeading from "../../layout/PageHeading";
import Modal from "react-bootstrap/Modal";

const EnrollCamp = (props) => {
  const { eventId } = useParams();
  const { userId } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [data, setData] = useState({});
  const [form, setForm] = useState([]);
  const navigate = useNavigate();

  const [si, setSi] = useState("");
  const [siNumber, setSiNumber] = useState("");
  const [transport, setTransport] = useState(0);
  const [carCapacity, setCarCapacity] = useState(1);
  const [showSITip, setShowSITip] = useState(false);
  const [note, setNote] = useState("");
  const [confirmDelete, setConfirmDelete] = useState(false);
  const target = useRef(null);

  useEffect(() => {
    setData(props.data);
    setForm(props.data.detail.form);
    setSi(props.si);
    setSiNumber(props.siNumber);
    setTransport(props.transport);
    setCarCapacity(props.carCapacity);
    setNote(props.note);
    setLoading(false);
  }, [setData, data, setForm, setLoading, setSi, setSiNumber, setNote]);

  let handleSubmit = async (e) => {
    e.preventDefault();
    if(!checkRequiredFields()){
      setError("Nevyplněna všechna povinná pole");
      return;
    }
    var rentSi = si === 'rent';
    var o_si;
    if(si === 'rent'){
      o_si = 0;
    }else if(si === 'custom'){
      o_si = siNumber;
    }else{
      o_si = si;
    }
    let mode = data.applied ? "PUT" : "POST";

    const res = await sendCampEnroll(eventId, data.user.id, form, o_si, rentSi, transport, carCapacity, note, mode);
    if (res.ok)
      (mode === "POST") ? setSuccess("Přihláška přidána") : setSuccess("Přihláška upravena");
    else
      setError(handleErrorSubmit(res, (mode === 'POST' ? "Nepodařilo se přihlásit" : "Nepodařilo se upravit přihlášku")));
  };

  function checkRequiredFields(){
    let result = true;
    form.forEach((field)=> {
      let invalid = false;
      if (field.required) {
        if (!field.closed_ended) {
          if (isEmpty(field.value)) {
            invalid = true;
          }
        } else {
          let isAnythingSelected = false;
          if (field.multichoice) // multiselect
            field.choices.forEach((choice) => {isAnythingSelected = (choice.checked ? true : isAnythingSelected)});
          else // single-select
            field.choices.forEach((choice) => {isAnythingSelected = (choice.id === field.selected ? true : isAnythingSelected)});
          if (field?.selected === 0 && !isEmpty(field?.value))
            isAnythingSelected = true;
          if (!isAnythingSelected) {
            invalid = true;
          }
        }
      }
      field.invalid = invalid;
      if (invalid)
        result = false;
    });
    return result;
  }

  function isEmpty(item){
    return typeof item === "undefined" || item == null || item === "";
  }

  function redirectToUserList(value){
    if (value==="others") {
      navigate("/akce/"+eventId,{state:{showEnrollList:true}});
    }
  }
  const CreatorEditorText = ({person, label}) => {
    const labelText = label + ": ";
    if (person == null)
      return "";
    return <i>{labelText} {person.reg_number} – {person.full_name}</i>;
  }

  if (loading)
    return <Loader />;

  setBrowserTabText('Přihláška | ' + data?.event?.title)
  return (
    <PageContainer background={getBackground()}>
      <PageHeading heading={"Přihláška: " + data?.event?.title} id={eventId} />
      <Row>
        <Col><p className="mb-2"><EventDate event={data?.event} /></p></Col>
      </Row>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col>
            <Form.Group className="mb-3" controlId="user">
              <Form.Label>Uživatel:</Form.Label>
              <Form.Select className="tapis-95" defaultValue={"me"} onChange={(e) => redirectToUserList(e.target.value)}>
                <option
                  value="me">{[data?.user?.full_name + " - " + data?.user?.reg_number]}</option>
                <option value="others">Přihlásit ostatní</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md>
            <Form.Group className="mb-3" controlId="si">
              <Row>
                <Col xs="auto"><Form.Label>Čip:</Form.Label></Col>
                <Col className="text-end">Ražení: {PunchingOptions[data?.event?.punching]}</Col>
              </Row>
              <Form.Select className="tapis-95" value={si} disabled={data.event.term < 0}
                           onChange={(e) => {
                             setSi(e.target.value);
                             setShowSITip(false);
                           }}>
                <SelectOptionsSet options={siCardOptions(data?.user?.si_cards)}/>
              </Form.Select>
            </Form.Group>
            {si === "custom" && (<><Form.Group className="mb-3" controlId="siNumber">
              <Form.Label id="card-label">Číslo čipu: <Link to="#" ref={target} onClick={() => setShowSITip(!showSITip)}><FontAwesomeIcon icon={faLightbulb} color="gold"/></Link>
              </Form.Label>
              <OverlayTooltip content={"Své čipy si můžeš uložit v nastavení účtu"} target={target.current} show={showSITip}/>
              <Form.Control className="tapis-95" type="number" disabled={data.event.term < 0}
                            value={siNumber} onChange={(e) => setSiNumber(e.target.value)}/>

            </Form.Group></>)
            }
          </Col>
          <Col>
            <Form.Group className="mb-3" controlId="transport">
              <Form.Label>Společná doprava{transport > 1 && " | počet míst"}:</Form.Label>
              <InputGroup>
                <Form.Select className="tapis-95" style={{ width: '70%' }}
                             disabled={data?.event?.transport !== 0 || data.event.term < 0}
                             value={transport} onChange={(e) => setTransport(Number(e.target.value))}>
                  <SelectOptionsDict options={InterestExtendedOptions} />
                </Form.Select>
                {transport > 1 &&
                  <Form.Control
                    type="number" className="tapis-95" style={{ maxWidth: '30%' }}
                    disabled={data?.event?.transport !== 0 || data.event.term < 0}
                    value={carCapacity} onChange={(e) => {
                    setCarCapacity(Math.min(9, Math.max(1, Number(e.target.value) % 10)))
                  }}/>
                }
              </InputGroup>
            </Form.Group>
            {data?.event?.transport_info !== "" && <><FontAwesomeIcon icon={faCircleInfo} color="gray"/> <i>{data?.event?.transport_info}</i></>}
          </Col>
        </Row>
        <hr/>
        <h2>Formulář pro účastníky</h2>
        <p className="mb-4"><i>Pole s hvězdičkou jsou povinná</i></p>
        <div>
          {CampForm()}
        </div>
        <Row>
          {data?.applied &&
            <Col md className="mb-2">
              <CreatorEditorText person={data.detail.submission.created_by} label={"Vytvořil/a"}/><br/>
              <CreatorEditorText person={data.detail.submission.edited_by} label={"Upravil/a"}/>
            </Col>
          }
          <Col className="text-end">
            <EnrollDelete/>
            <EnrollSubmit/>
          </Col>
        </Row>
      </Form>
      <br/>
      <Modal show={confirmDelete} onHide={() => setConfirmDelete(false)}>
        <Modal.Header closeButton>
          <FontAwesomeIcon icon={faQuestionCircle} size='3x' bounce/> &nbsp;
          <Modal.Title>Skutečně smazat přihlášku?</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setConfirmDelete(false)}>Zavřít</Button>
          <Button variant="primary" onClick={tryDeleteEnroll}>Smazat</Button>
        </Modal.Footer>
      </Modal>
      <ErrorAlert error={error} handleClose={() => setError("")}/>
      <SuccessAlert title={success} handleClose={() => {
        setSuccess("");
        window.location.reload();
      }}/>
    </PageContainer>
  );

  function siCardOptions(siCards){
    const res = [];
    siCards?.forEach((card) => {res.push({name: card.si_number, value: card.si_number})});
    res.push({name: "Půjčit čip", value: "rent"});
    res.push({name: "Neuložený čip", value: "custom"});
    return res;
  }

  function EnrollSubmit(){
    if(data?.applied)
      return <Button style={{"marginLeft": "10px"}} variant="primary" type="submit">Upravit přihlášku</Button>;
    return <Button variant="primary" type="submit">Přihlásit</Button>;
  }

  function EnrollDelete(){
    if(data?.applied && data.event.term > 0){
      return(<Button variant="secondary" onClick={() => setConfirmDelete(true)}>Smazat přihlášku</Button>);
    }
  }
  function tryDeleteEnroll(){
    deleteEnroll(eventId, userId)
      .then((response)=>{
        if (response.ok) {
          setSuccess("Přihláška smazána");
        } else {
          setError(handleErrorSubmit(response, "Nepodařilo se smazat"));
        }
        setConfirmDelete(false);
      })
  }

  function getBackground(){
    if(data?.applied)
      return "rgba(2,200,30,0.2)";
    return "rgba(0,0,0,0)";
  }

  function CampForm(){
    const res = [];
    for (const fieldId in form) {
      const field = form[fieldId];
      res.push(
        <Row key={"r"+field.id} className="mb-3" style={{marginLeft: "6%", marginRight: "6%"}}>
          <Form.Group>
            <Form.Label className="mb-0"><b>{field.index + ". " + field.title}{field.required && <i style={{color: "red"}}>*</i>}</b></Form.Label>
            {field.note !== "" && <p className="ms-2 mt-1 mb-1"><i>{field.note}</i></p>}
            <div className="ms-2 mt-2" key={"q-"+field.id}>
              {CampField({field: field})}
            </div>
            <hr/>
          </Form.Group>
          <br/>
        </Row>
      );
    }
    res.push(
      <Row key={"r0"} className="mb-3" style={{marginLeft: "6%", marginRight: "6%"}}>
        <FormField label={"Poznámka"} value={note} setValue={setNote} valueLimit={128} controlId={"note"} />
        <br/>
      </Row>);
    return res;
  }

  function CampField(props){
    let invalid = props.field?.invalid == null ? false : props.field?.invalid;
    if (!props.field.closed_ended) {
      return <Form.Control value={props.field.value} isInvalid={invalid} onChange={(e)=>{setFieldValue(props.field.id, e.target.value)}}/>;
    } else {
      const res = [];
      for (let choiceId in props.field.choices) {
        let choice = props.field.choices[choiceId];
        if (props.field.multichoice) {
          res.push(<Form.Check type="checkbox" label={choice.value} key={"c-"+choice.id} isInvalid={invalid} checked={choice.checked}
                               onChange={(e) => handleCheckboxChange(e, choice.id, props.field.id)}/>);
        } else {
          res.push(<Form.Check type="radio" label={choice.value} key={"c-"+choice.id} isInvalid={invalid} checked={props.field.selected === choice.id}
                               onChange={(e) => handleRadioChange(e, choice.id, props.field.id)}/>)
        }
      }
      if (props.field.other_choice) {
        res.push(
          <InputGroup key={0}>
            <Form.Check type={props.field.multichoice ? "checkbox" : "radio"} label={"Jiné: "} checked={props.field.selected === 0} isInvalid={invalid}
                        onChange={(e) => {
                          props.field.multichoice ? handleCheckboxChange(e, 0, props.field.id) : handleRadioChange(e, 0, props.field.id)}}/>
            <Form.Control style={{marginLeft: "1em"}} value={props.field.value} disabled={props.field?.selected !== 0} isInvalid={invalid}
                          onChange={(e) => {setFieldValue(props.field.id, e.target.value)}}/>
          </InputGroup>
        );
      }
      return res;
    }
  }

  function handleCheckboxChange(e, choiceId, fieldId){
    let tempForm = JSON.parse(JSON.stringify(form));
    for(let fieldIndex in tempForm){
      if(tempForm[fieldIndex].id === fieldId){
        for (let choiceIndex in tempForm[fieldIndex].choices){
          if(tempForm[fieldIndex].choices[choiceIndex].id === choiceId){
            tempForm[fieldIndex].choices[choiceIndex].checked = !tempForm[fieldIndex].choices[choiceIndex].checked;
          }
        }
        if (choiceId === 0)
          tempForm[fieldIndex].selected = (tempForm[fieldIndex]?.selected === 0 ? -1 : 0);
        tempForm[fieldIndex].invalid = false;
      }
    }
    
    setForm(tempForm);
  }
  function handleRadioChange(e, choiceId, fieldId){
    let tempForm = JSON.parse(JSON.stringify(form));
    for(let fieldIndex in tempForm){
      if(tempForm[fieldIndex].id === fieldId){
        tempForm[fieldIndex].selected = choiceId;
        tempForm[fieldIndex].invalid = false;
      }
    }
    setForm(tempForm);
  }
  function setFieldValue(fieldId, value){
    let tempForm = JSON.parse(JSON.stringify(form));
    for(let fieldIndex in tempForm){
      if(tempForm[fieldIndex].id === fieldId){
        tempForm[fieldIndex].value = value;
        tempForm[fieldIndex].invalid = false;
      }
    }
    setForm(tempForm);
  }
};

export default EnrollCamp;