import Button from "react-bootstrap/Button";
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import { getCookie, resetPassword } from "../../dao";
import { useState } from "react";
import {useNavigate} from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { faLock, } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LogoSvg from "../../components/visual/LogoSvg";
import {isMobileDevice} from "../../helpers/Device";
import ErrorAlert from "../../components/overlays/ErrorAlert";
import SuccessAlert from "../../components/overlays/SuccessAlert";

const PasswordReset = () => {
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    const urlParams = new URLSearchParams(window.location.search);
    var token = urlParams.get("token");
    getCookie()
      .then((response) => {
        resetPassword(password, token, response.headers.get("X-Csrftoken"))
          .then((res) => {
            if (res.ok)
              setSuccess("Heslo obnoveno");
            else
              setError("Nepodařilo se obnovit");
          })
      })
  }

  return (
    <Container fluid="sm">
      <div style={{height: 100}} />
      <Card className="loginCard">
        <Card.Body>
          <Row className="align-items-end mb-3">
            <Col><h1 className="mb-2" style={{whiteSpace: "nowrap"}}>Reset hesla</h1></Col>
            <Col className="text-end"><LogoSvg zoom={isMobileDevice() ? 1 : 1.7}/></Col>
          </Row>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label><FontAwesomeIcon icon={faLock} /> Nové heslo:</Form.Label>
              <Form.Control type="password" placeholder="Heslo" value={password} onChange={(e) => setPassword(e.target.value)} />
            </Form.Group>
            <Row className="mt-3">
              <Col className="text-end">
                <Button variant="primary" type="submit"> Nastavit heslo</Button>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>

      <ErrorAlert error={error} handleClose={()=>setError("")}/>
      <SuccessAlert title={success} handleClose={()=>navigate("/")}/>
    </Container>);

}; 

export default PasswordReset;
