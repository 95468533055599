import React, {useEffect, useState} from "react";
import {getServerEmailQueue} from "../../dao";
import {handleErrorLoading, parseDateTimeSec, setBrowserTabText} from "../../helpers/Functions";
import Loader from "../../components/overlays/Loader";
import ErrorAlertFullscreen from "../../components/overlays/ErrorAlertFullscreen";
import PageContainer from "../../layout/PageContainer";
import Table from "react-bootstrap/Table";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import NoYesIndicator from "../../components/visual/NoYesIndicator";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBug, faCoins, faHand, faUnlock, faUserSecret} from "@fortawesome/free-solid-svg-icons";
import FormCheckbox from "../../components/form/FormCheckbox";


const ServerEmailQueue = () => {
  const [data, setData] = useState([]);
  const [errorRemote, setErrorRemote] = useState("");
  const [loading, setLoading] = useState(true);
  const [showAll, setShowAll] = useState(false);

  useEffect(() => {
    setLoading(true);
    getServerEmailQueue(showAll)
      .then((response) => {
        if (!response.ok) {
          setErrorRemote(handleErrorLoading(response));
          return [];
        }
        return response.json();
      })
      .then((response) => {
        setData(response);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, [showAll]);

  if (loading)
    return <Loader />;
  if (errorRemote !== "")
    return <ErrorAlertFullscreen error={errorRemote} />;

  setBrowserTabText('Fronta emailů');
  return (
    <PageContainer>
      <h1>Fronta emailů – {showAll ? "historie" : "aktuální"}</h1>
      <Row>
        <Col xs>
          <p className="mb-0">čeká: <b>{data?.waiting}</b></p>
          <p>stav: {parseDateTimeSec(data?.timestamp)}</p>
        </Col>
        <Col xs="auto">
          <FormCheckbox label="Všechny záznamy" type="switch" value={showAll} setValue={setShowAll} controlId="showAll"/>
        </Col>
      </Row>
      <EmailQueueTable />
    </PageContainer>
  )

  function EmailQueueTable() {
    const rows = [];
    
    const EmailType = ({type}) => {
      switch (type) {
        case 1:
          return <FontAwesomeIcon icon={faHand} color="green"/>
        case 2:
        case 3:
          return <FontAwesomeIcon icon={faCoins} color="red"/>
        case 4:
          return <FontAwesomeIcon icon={faUnlock} color="blue"/>
        case 5:
          return <FontAwesomeIcon icon={faBug}/>
        case 6:
          return <FontAwesomeIcon icon={faUserSecret} color="orange"/>
        default:
          return <></>;
      }
    }

    for (const i in data?.queue) {
      const element = data?.queue[i];
      rows.push(
        <tr key={i}>
          <td>{element?.id}</td>
          <td>{element?.recipient?.full_name} <i>{element?.recipient?.reg_number}</i></td>
          <td className="align-center"><EmailType type={element?.email_type}/></td>
          <td>{parseDateTimeSec(element?.created)} / <i>{parseDateTimeSec(element?.processed)}</i></td>
          {setShowAll ? <>
            <td className="align-center"><NoYesIndicator condition={element?.processing} allBlack={true}/></td>
            <td className="align-center"><NoYesIndicator condition={element?.sent} allBlack={true}/></td>
          </> : <>
            <td>{i + 1}</td>
          </>}
        </tr>);

    }

    if (rows.length > 0) {
      return (
        <>
          <Table size="sm" striped bordered hover responsive>
            <thead>
            <tr>
              <th>ID</th>
              <th>Příjemce</th>
              <th>T</th>
              <th title="created / processed">C / P</th>
              {setShowAll ? <>
                <th title="processing">P</th>
                <th title="done, email odeslán">D</th>
              </> : <>
                <th title="estimated processing time">ET [min]</th>
              </>}
            </tr>
            </thead>
            <tbody>
            {rows}
            </tbody>
          </Table>
          <Row>
            <p className="mb-0"><b>Vysvětlivky:</b></p>
            <p className="mb-0"><EmailType type={1}/> – uvítání a přihlašovací údaje</p>
            <p className="mb-0"><EmailType type={2}/> – vyúčtování</p>
            <p className="mb-0"><EmailType type={4}/> – zapomenuté heslo</p>
            <p className="mb-0"><EmailType type={5}/> – nahlášení chyby</p>
            <p className="mb-0"><EmailType type={6}/> – anonymní příhláška</p>
          </Row>
        </>
      );
    }
    return (<p><i>Žádné emaily ve frontě</i></p>);
  }
}

export default ServerEmailQueue;