import Table from "react-bootstrap/Table";
import React from "react";
import EventClassLinks from "./EventClassLinks";


const EventClassesTable = ({event}) => {
  if (event.class_data.length < 1 || event.type !== 0) {
    return (<><p><i>Žádné kategorie k zobrazení</i></p><br/></>);
  }
  const rows = [];
  for (let i = 0; i < event.class_data.length; i++) {
    const cls = event.class_data[i];
    rows.push(<tr key={i}>
      <EventClassLinks eventId={event.oris_id} classId={cls.id} className={cls.name} applied={cls.entries} startlists={event?.startlists} results={event?.results} isTeam={event?.is_relay} hasTracking={event?.has_tracking} trackingId={cls.tracking} />
    </tr>);
  }

  return (
    <>
      <Table striped hover responsive>
        <tbody>
        {rows}
        </tbody>
      </Table>
    </>
  )
}

export default EventClassesTable