import Table from "react-bootstrap/Table";
import React, { useState, useEffect } from 'react'
import {getClubBilling, sendEmails} from "../../dao";
import { Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowsDownToPeople,
  faCircleDollarToSlot,
  faCircleUser,
  faEnvelope,
  faEuro,
  faList,
  faMoneyBills,
  faPaperPlane,
  faQuestionCircle,
  faTimeline,
  faUsersRectangle
} from '@fortawesome/free-solid-svg-icons';
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import {YearOptions, handleErrorLoading, setBrowserTabText, handleErrorSubmit} from "../../helpers/Functions";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import NoYesIndicator from "../../components/visual/NoYesIndicator";
import ColorChangingText from "../../components/visual/ColorChangingText";
import ErrorAlert from "../../components/overlays/ErrorAlert";
import SuccessAlert from "../../components/overlays/SuccessAlert";
import Modal from "react-bootstrap/Modal";
import PageContainer from "../../layout/PageContainer";
import LoadingIcon from "../../components/overlays/LoadingIcon";
import {isMobileDevice} from "../../helpers/Device";
import SearchBar from "../../components/form/SearchBar";
import FormField from "../../components/form/FormField";
import FormSelectArray from "../../components/form/FormSelectArray";

const ClubBilling = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [errorRemote, setErrorRemote] = useState("");
  const [failed, setFailed] = useState(false);
  const [success, setSuccess] = useState("");
  const [members, setMembers] = useState([]);
  const [header, setHeader] = useState([]);
  const [year, setYear] = useState((new Date()).getFullYear());
  const [debtsOnly, setDebtsOnly] = useState(false);
  const [negativesOnly, setNegativesOnly] = useState(false);
  const [emailSending, setEmailSending] = useState(0);
  const [emailTo, setEmailTo] = useState(null);
  const [debtBoundary, setDebtBoundary] = useState((new Date()).toISOString().split('T')[0]);

  useEffect(() => {
    setLoading(true);
    setNegativesOnly(false);
    setDebtsOnly(false);
    getClubBilling(year)
      .then((response) => {
        setLoading(false);
        if (response.ok) {
          setFailed(false);
          return response.json();
        }
        setErrorRemote(handleErrorLoading(response));
        setFailed(true);
      })
      .then((response) => {
        setMembers(response?.members)
        setHeader(response?.header)
      })
      .catch((err) => console.log(err));
  }, [year]);

  const handleSendEmails = () => {
    const param = emailTo != null ? 'user=' + emailTo.id : emailSending === 1 ? 'all=1' : 'debts=1&boundary=' + debtBoundary;
    sendEmails(param)
      .then((response) => {
        if (response.ok)
          setSuccess("Požadavek přijat");
        else
          setError(handleErrorSubmit(response, "Operace se nezdařila"));
        setEmailSending(0);
        setEmailTo(null);
      })
  }

  if (errorRemote !== "")
    return  <ErrorAlert error={errorRemote} handleClose={() => setErrorRemote("")}/>

  setBrowserTabText('Účetnictví')
  return (
    <PageContainer>
      <h1>Účetnictví</h1>
      <MainControlPanel/>
      <hr/>
      <Row><TableControlPanel/></Row>
      <MemberTable />
      <Modal show={emailSending !== 0 || emailTo != null} onHide={() => {
        setEmailSending(0);
        setEmailTo(null);
      }}>
        <Modal.Header closeButton>
          <FontAwesomeIcon icon={faQuestionCircle} size='3x' bounce/> &nbsp;
          <Modal.Title>Odeslat {emailTo != null ? "uživateli " + emailTo?.full_name : emailSending === 1 ? "všem členům" : "dlužníkům"} email s výzvou k zaplacení?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{emailTo != null ? emailTo?.full_name : emailSending === 1 ? "Všichni členové" : "Dlužníci"} obdrží email s výzvou k zaplacení dluhů k dnešnímu
            datu a aktuálnímu období uvedených v tabulce níže.</p>
          {emailSending === 2 && <FormField label={"Dluhy starší než (včetně)"} type={"date"} value={debtBoundary} setValue={setDebtBoundary} controlId={"debtBoundary"} />}
          <p>Před potvrzením odeslání zkontroluj, zda je zúčtovací období <b>{header?.period}</b> správné, případně
            ho <Link to={`/ucetnictvi/detail`}>změň zde</Link>.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => {
            setEmailSending(0);
            setEmailTo(null);
          }}>
            Zavřít
          </Button>
          <Button variant="primary" onClick={handleSendEmails}>
            Potvrdit
          </Button>
        </Modal.Footer>
      </Modal>
      <ErrorAlert error={error} handleClose={() => setError("")}/>
      <SuccessAlert title={success} handleClose={() => setSuccess("")}/>
    </PageContainer>
  );

  function MainControlPanel() {
    return (<Row>
      <Col lg md={6} sm={12} className="mt-2 mb-1">
        <Card className="h-100">
          <Card.Body>
            <Card.Title>Pohledávky</Card.Title>
            <Row><Col xs="auto" className="pe-1">
              <Link to={`/ucetnictvi/pohledavky`}><Button className="mb-2 tapis-95"><FontAwesomeIcon icon={faUsersRectangle} /> Klubové</Button></Link>
            </Col><Col xs="auto">
              <Link to={`/ucetnictvi/pohledavky/cizi-meny`}><Button className="mb-2 tapis-95" variant="outline-primary"><FontAwesomeIcon icon={faEuro} /> Cizí měny</Button></Link>
            </Col></Row>
          </Card.Body>
        </Card>
      </Col>
      <Col lg md={6} sm={12} className="mt-2 mb-1">
        <Card className="h-100">
          <Card.Body>
            <Card.Title>Platby za pohledávky</Card.Title>
            <Row><Col xs="auto">
              <Link to={`/ucetnictvi/platby`}><Button className="mb-2 tapis-95"><FontAwesomeIcon icon={faMoneyBills} /> Vstoupit</Button></Link>
            </Col></Row>
          </Card.Body>
        </Card>
      </Col>
      <Col lg md={6} sm={12} className="mt-2 mb-1">
        <Card className="h-100">
          <Card.Body>
            <Card.Title><Row><Col>Období:</Col><Col>{header?.period}</Col></Row></Card.Title>
            <Row><Col xs="auto">
              <Link to={`/ucetnictvi/detail`}><Button className="mb-2 tapis-95"><FontAwesomeIcon icon={faList} /> Detaily</Button></Link>
            </Col><Col xs="auto">
              <Link to={`/ucetnictvi/obdobi`}><Button className="mb-2 tapis-95" variant="secondary"><FontAwesomeIcon icon={faTimeline} /> Správa</Button></Link>
            </Col></Row>
          </Card.Body>
        </Card>
      </Col>
      <Col lg md={6} sm={12} className="mt-2 mb-1">
        <Card className="h-100">
          <Card.Body>
            <Card.Title>Emailové výzvy</Card.Title>
            <Row>
              {/*<Col xs="auto"><Button onClick={() => {setEmailSending(1)}} className="mb-2 tapis-95"><FontAwesomeIcon icon={faPaperPlane}/> Všichni</Button></Col> TODO uncomment when template ready*/}
              <Col xs="auto"><Button className="mb-2 tapis-95"><FontAwesomeIcon icon={faPaperPlane}/> Všichni</Button></Col>
              <Col xs="auto"><Button title="Upomínka všem s dluhem vyšším než 0" onClick={() => {setEmailSending(2)}} className="mb-2 tapis-95"><FontAwesomeIcon icon={faCircleDollarToSlot}/> Dlužníci</Button></Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>)
  }

  function TableControlPanel() {
    return (<Col md><Card className="mb-3">
      <Card.Body>
        <div className="mb-2 mt-1">
          <FormSelectArray label={"Rok"} inline={true} size="sm" value={year} setValue={setYear} options={YearOptions()} disabled={loading} controlId={"year"} />
          <Row>
            <Col><Form.Check id="check1" size="sm" type="checkbox" label="Pouze dlužníci" defaultChecked={debtsOnly} disabled={loading} onChange={(e) => {
              setDebtsOnly(e.target.checked);
              setNegativesOnly(false);
            }}/></Col>
            <Col><Form.Check id="check2" size="sm" type="checkbox" label="Pouze přeplatky" defaultChecked={negativesOnly} disabled={loading} onChange={(e) => {
              setNegativesOnly(e.target.checked);
              setDebtsOnly(false);
            }}/></Col>
          </Row>
        </div>
      </Card.Body>
    </Card></Col>)
  }

  function MemberTable() {
    const [filteredPeople, setFilteredPeople] = useState(members);

    if (failed)
      return;
    if (loading)
      return <LoadingIcon />;

    const TableRow = ({element}) => {
      const style = isMobileDevice() ? {paddingBottom: 2, paddingTop: 2} : {paddingBottom: 1, paddingTop: 1};
      const membershipElement = element?.membership ? element.membership : element; // made for backwards compatibility

      return <>
        <tr>
          <td rowSpan={2} style={style}><Link to={`/ucet/${element.id}/vyuctovani/`}>{element.full_name}</Link>
            {membershipElement.difference > 0 && <a href="#"><FontAwesomeIcon icon={faEnvelope} style={{marginLeft: "5px"}} onClick={() => {
              setEmailTo(element)
            }} title="Odeslat upomínku k uhrazení"/></a>}
            {element?.wardship?.wards && <FontAwesomeIcon icon={faArrowsDownToPeople} style={{marginLeft: "5px"}} title={element?.wardship?.wards?.string}/>}
            {element?.wardship?.guardian && <FontAwesomeIcon icon={faCircleUser} style={{marginLeft: "5px"}} color="gray" title={element?.wardship?.guardian?.string}/>}
            <br/><i>{element.reg_number} – {membershipElement.type}</i></td>
          <td style={style}>{membershipElement.sum_oris}</td>
          <td style={style}><ColorChangingText value={membershipElement.sum_dns_fines} border={2000}/></td>
          <td style={style}><ColorChangingText value={membershipElement.sum_late_term} border={2000}/></td>
          <td style={style}>{membershipElement.sum_services}</td>
          <td style={style}>{membershipElement.sum_camps}</td>
          <td rowSpan={2} style={style}>{element?.wardship?.guardian ? <b title="Uživatel přidružen" style={{color: "purple"}}>0</b> : membershipElement.expenses}</td>
          <td rowSpan={2} style={style} align="center"><NoYesIndicator condition={membershipElement.difference <= 0} /></td>
          <td rowSpan={2} style={style}><ColorChangingText value={membershipElement.difference} border={10000}/></td>
        </tr>
        <tr>
          <td colSpan={4} align="right" style={style}>{membershipElement.sum_oris + membershipElement.sum_dns_fines + membershipElement.sum_late_term + membershipElement.sum_services}</td>
          <td align="right" style={style}>{membershipElement.sum_club}</td>
        </tr>
      </>
    }

    const rows = [];
    for (const i in filteredPeople) {
      const person = filteredPeople[i];
      const membershipElement = person?.membership ? person.membership : person; // made for backwards compatibility
      if (negativesOnly || debtsOnly) {
        if (negativesOnly && membershipElement.difference < 0)
          rows.push(<TableRow element={person} key={person.id}/>);
        if (debtsOnly && membershipElement.difference > 0)
          rows.push(<TableRow element={person} key={person.id}/>);
      } else {
        rows.push(<TableRow element={person} key={person.id}/>);
      }
    }

    return (<>
      <SearchBar data={members} setFilteredPeople={setFilteredPeople}/>
      <Table bordered hover responsive>
        <thead>
        <tr>
          <th rowSpan={2}>Osoba</th>
          <th>Start.</th>
          <th>DNS</th>
          <th>T2/3</th>
          <th>Služ.</th>
          <th>Sous.</th>
          <th rowSpan={2} title="Celkové výdaje">Σ cel.</th>
          <th rowSpan={2} title="Výdaje uhrazeny">OK</th>
          <th rowSpan={2} title="Aktuální dluh, přeplatky s mínus">DLUH</th>
        </tr>
        <tr>
          <th colSpan={4} style={{textAlign: "right"}}>Σ Oris akce</th>
          <th style={{textAlign: "right"}}>Klub</th>
        </tr>
        </thead>
        <tbody>
        {rows}
        </tbody>
      </Table>
      {!negativesOnly && <p className="text-end">Celkový čistý dluh: <b>{header?.debt_sum}</b> Kč</p>}
      {!debtsOnly && <p className="text-end">Celkový čistý přeplatek: <b>{header?.negative_sum}</b> Kč</p>}
    </>)
  }
};


export default ClubBilling;