// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  letter-spacing: 0.03em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.leaflet-container{
  width:100%;
  height: 40vh;
}

.loginCard {
  box-shadow: 0 10px 30px 0 rgba(44, 69, 44, 0.43);
  max-width: 650px;
  margin: auto;
  padding: 1%;

}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1) translateX(3%);
  }
  100% {
    transform: scale(1);
  }
}

.pulse {
  animation: pulse 1.5s infinite; /* Adjust the duration as needed */
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,sBAAsB;EACtB,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE;aACW;AACb;;AAEA;EACE,UAAU;EACV,YAAY;AACd;;AAEA;EAIE,gDAAgD;EAChD,gBAAgB;EAChB,YAAY;EACZ,WAAW;;AAEb;;AAEA;EACE;IACE,mBAAmB;EACrB;EACA;IACE,oCAAoC;EACtC;EACA;IACE,mBAAmB;EACrB;AACF;;AAEA;EACE,8BAA8B,EAAE,kCAAkC;AACpE","sourcesContent":["body {\n  margin: 0;\n  letter-spacing: 0.03em;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n\n.leaflet-container{\n  width:100%;\n  height: 40vh;\n}\n\n.loginCard {\n\n  -webkit-box-shadow: 0 10px 30px 0 rgba(71, 98, 71, 0.43);\n  -moz-box-shadow: 0 10px 30px 0 rgba(58, 80, 58, 0.43);\n  box-shadow: 0 10px 30px 0 rgba(44, 69, 44, 0.43);\n  max-width: 650px;\n  margin: auto;\n  padding: 1%;\n\n}\n\n@keyframes pulse {\n  0% {\n    transform: scale(1);\n  }\n  50% {\n    transform: scale(1.1) translateX(3%);\n  }\n  100% {\n    transform: scale(1);\n  }\n}\n\n.pulse {\n  animation: pulse 1.5s infinite; /* Adjust the duration as needed */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
