import Form from "react-bootstrap/Form";
import React from "react";
import {SelectOptionsDict} from "./SelectOptions";


const FormSelectDict = ({label="", value, setValue, options, disabled=false, className="mb-3", size="", help=undefined, controlId}) => {
  return (
    <Form.Group className={className} controlId={controlId}>
      {label !== "" && <Form.Label column={""}>{label}: {help}</Form.Label>}
      <Form.Select style={{fontSize: "0.9rem"}} value={value} disabled={disabled} size={size} onChange={(e) => setValue(e.target.value)}>
        <SelectOptionsDict options={options}/>
      </Form.Select>
    </Form.Group>
  )
}

export default FormSelectDict;