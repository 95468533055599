import React, { useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useParams } from "react-router-dom";
import Loader from "../../components/overlays/Loader";
import { editTransport, getTransportEditInfo } from "../../dao";
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import { useState } from "react";
import {faCar, faCarSide, faCircleInfo, faFloppyDisk, faPencil, faPeopleGroup} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Button from "react-bootstrap/esm/Button";
import {handleErrorLoading, handleErrorSubmit, parseTime, setBrowserTabText} from "../../helpers/Functions";
import ErrorAlertFullscreen from "../../components/overlays/ErrorAlertFullscreen";
import {Badge, Form} from "react-bootstrap";
import PageContainer from "../../layout/PageContainer";
import ErrorAlert from "../../components/overlays/ErrorAlert";
import SuccessAlert from "../../components/overlays/SuccessAlert";
import PageHeading from "../../layout/PageHeading";
import EventDate from "../../components/parts/EventDate";

export default function TransportAdmin() {
  const { eventId } = useParams();
  const [data, setData] = useState([]);
  const [visibleToUsers, setVisibleToUsers] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [errorRemote, setErrorRemote] = useState("");
  const [editVehicle, setEditVehicle] = useState(null);

  useEffect(() => {
    setLoading(true);
    getTransportEditInfo(eventId)
      .then((response) => {
        if (!response.ok) {
          setErrorRemote(handleErrorLoading(response));
          return [];
        }
        return response.json()
      })
      .then((response) => {
        setData(response);
        setVisibleToUsers(response?.transport_handled);
        setLoading(false);
      })
  }, [eventId]);

  async function saveChanges() {
    var outVehicles = JSON.parse(JSON.stringify(data.vehicles));
    outVehicles.forEach((vehicle, vehicleId) => {
      vehicle.crew.forEach((passanger, passangerId)=>{
        outVehicles[vehicleId].crew[passangerId] = passanger.id;
      });
    });
    var res = await editTransport(eventId, outVehicles, visibleToUsers);
    if(res.ok){
      setSuccess("Rozřazení uloženo");
    }else{
      setError(handleErrorSubmit(res, "Nepodařilo se uložit rozdělení"));
    }
  }

  function handleOnDragEnd(result) {
    var tempData = JSON.parse(JSON.stringify(data));
    if (result.destination != null) {
      if(isVehicleAvailable(result.destination.droppableId)){
        const removedPerson = removePerson(result.draggableId, result.source.droppableId, tempData);
        tempData = addPerson(removedPerson, result.destination.droppableId, tempData);
      }
    }
    setData(tempData);
  }

  function isVehicleAvailable(id){
    if(id === 'people'){
      return true;
    }else{
      var res = false;
      data.vehicles.forEach((vehicle) => {
        if (vehicle.id.toString() === id) {
          res = !(vehicle.capacity <= vehicle.crew.length);
          if(!vehicle.used){
            res = false;
          }
        }
      });
      return res;
    }
  }

  function removePerson(personId, location, tempData) {
    var res = {};
    if (location === 'people') {
      tempData.unassigned.forEach((person, index) => {
        if (person.id.toString() === personId) {
          res = (tempData.unassigned.splice(index, 1)[0]);
        }
      });
    } else {
      tempData.vehicles.forEach((vehicle) => {
        if (vehicle.id.toString() === location) {
          vehicle.crew.forEach((person, index) => {
            if (person.id.toString() === personId) {
              res = vehicle.crew.splice(index, 1)[0];
            }
          });
        }
      });
    }
    return res;
  }
  function addPerson(person, location, tempData) {
    if (location === 'people') {
      tempData.unassigned.push(person);
      tempData.unassigned.sort(function(a, b)
      {
        const x1 = a.start_time; const y1 = b.start_time;
        return ((x1 < y1) ? -1 : ((x1 > y1) ? 1 : 0));
      });
    } else {
      tempData.vehicles.forEach((vehicle) => {
        if (vehicle.id.toString() === location) {
          vehicle.crew.push(person);
        }
      });
    }
    return tempData;
  }

  function freeSpacesSum() {
    let sum = 0;
    data.vehicles.forEach((vehicle) => {
      if (vehicle.used) {
        sum += (vehicle.capacity - vehicle.crew.length);
      }
    });
    return sum;
  }

  if (loading)
    return <Loader />
  if (errorRemote !== "")
    return <ErrorAlertFullscreen error={errorRemote} />

  setBrowserTabText('Rozdělení dopravy | ' + data?.event?.title);
  return (<PageContainer>
    <PageHeading heading={"Rozdělení dopravy: " + data?.event?.title} label={"Doprava"} link={"/akce/"+eventId+"/doprava/detail"} />
    <p><EventDate event={data?.event} /></p>
    <Row>
      <Col xs="auto">
        <Button onClick={saveChanges}>
          <FontAwesomeIcon icon={faFloppyDisk} /> Uložit změny
        </Button>
      </Col>
      <Col xs="auto" />
      <Col>
        <Form.Check className={`mt-2 ${visibleToUsers ? '' : 'pulse'}`} type="switch" id="visible-switch" label="Zveřejnit uživatelům"
                    title="Pokud není rozdělení zveřejněno, pak ho běžní uživatelé nevidí" defaultChecked={visibleToUsers}
                    value={visibleToUsers} onChange={(e)=>setVisibleToUsers(e.target.checked)}
        />
      </Col>
    </Row>
    <br />
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <h3><FontAwesomeIcon icon={faPeopleGroup} /> Zájemci ({data?.unassigned.length})</h3>
      <Droppable droppableId="people">
        {(provided) =>
          (<Row {...provided.droppableProps}
                ref={provided.innerRef} className="ms-1 me-1"><Unassigned people={data?.unassigned} />
            {provided.placeholder}
          </Row>)}
      </Droppable>
      <br/>
      <Row>
        <Col xs="auto">
          <h3><FontAwesomeIcon icon={faCarSide} /> Vozidla ({freeSpacesSum()})</h3>
        </Col>
        <Col xs="auto">
          <Button className="mb-2" onClick={addVehicle}>+ <FontAwesomeIcon icon={faCarSide} /></Button>
        </Col>
      </Row>
      <Row>
        {VehicleList()}
      </Row>
    </DragDropContext>
    <ErrorAlert error={error} handleClose={()=>setError("")}/>
    <SuccessAlert title={success} handleClose={()=>setSuccess("")}/>
  </PageContainer>)

  function addVehicle(){
    var tempData = JSON.parse(JSON.stringify(data));
    var vehicleId = -1;
    tempData.vehicles.forEach((vehicle)=>{
      if(vehicleId >= vehicle.id){
        vehicleId = vehicle.id - 1;
      }
    });
    tempData.vehicles.push({
      capacity: 2,
      crew: [],
      driver: null,
      id: vehicleId,
      if_needed_only: true,
      info: "",
      title: "Nové auto",
      used: true
    });
    setData(tempData);
    setEditVehicle(vehicleId);
  }

  function handleDetailChange(vehicleId, detail, value){
    var tempData = JSON.parse(JSON.stringify(data));
    tempData.vehicles.forEach(vehicle =>{
      if(vehicle.id === vehicleId){
        vehicle[detail] = value;
      }
    });
    setData(tempData);
  }

  function handleUsedChange(vehicleId){
    var tempData = JSON.parse(JSON.stringify(data));
    tempData.vehicles.forEach((vehicle, vehiclePos) => {
      if(vehicleId === vehicle.id){
        const used = vehicle.used;
        tempData.vehicles[vehiclePos].used = !vehicle.used;
        if(used) {
          vehicle.crew.forEach((person)=> {
            tempData.unassigned.push(person);
          });
          tempData.vehicles[vehiclePos].crew = [];
          if (vehicle.driver != null)
            tempData.unassigned.push(vehicle.driver);
          else
            tempData.vehicles.splice(vehiclePos, 1);
        }else{
          if (vehicle.driver != null)
            tempData = removeDriver(vehicle.driver.id, tempData);
        }
      }
    });
    setData(tempData);
  }

  function removeDriver(driverId, tempData){
    tempData.unassigned.forEach((person, personPos)=>{
      if(person.id === driverId){
        tempData.unassigned.splice(personPos, 1);
        return tempData;
      }
    });
    tempData.vehicles.forEach((vehicle, vehiclePos) =>{
      vehicle.crew.forEach((person, personPos)=>{
        if(person.id === driverId){
          tempData.vehicles[vehiclePos].crew.splice(personPos, 1);
        }
      });
    });
    return tempData;
  }

  function VehicleList() {
    const res = [];
    data?.vehicles?.forEach(vehicle => {
      let hiddenStyle = {maxWidth: "340px"};
      if (!vehicle.used) {
        hiddenStyle = {color: "grey", maxWidth: "340px"}
      }
      res.push(
        <Col xs="auto"  key={vehicle.id + ""} >
          <Card className="mb-3" style={hiddenStyle}>
            <Card.Body>
              { editVehicle === vehicle.id ?
                (<>
                  <Form>
                    <Form.Group className="mb-3" controlId="vehicletitle">
                      <Form.Label>Název:</Form.Label>
                      <Form.Control name="title" type="text" value={vehicle.title}
                                    onChange={(e) => handleDetailChange(vehicle.id, e.target.name, e.target.value)}/>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="vehicleinfo">
                      <Form.Label>Info:</Form.Label>
                      <Form.Control name="info" type="text" value={vehicle.info}
                                    onChange={(e) => handleDetailChange(vehicle.id, e.target.name, e.target.value)}/>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="vehiclecapacity">
                      <Form.Label>Kapacita:</Form.Label>
                      <Form.Control name="capacity" type="number" value={vehicle.capacity}
                                    onChange={(e) => handleDetailChange(vehicle.id, e.target.name, e.target.value)}/>
                    </Form.Group>
                    <Button onClick={()=>setEditVehicle(null)}>Hotovo</Button>
                  </Form>
                </>)
                :
                (<>
                  <Row className="align-items-baseline mb-2">
                    <Col>
                      <b><FontAwesomeIcon icon={faCar} />&nbsp;{vehicle.title}</b>
                    </Col>
                    {vehicle.if_needed_only &&
                      <Col xs="auto">
                        <Form.Check type="switch" id="visible-switch" defaultChecked={vehicle.used} title="Potřebné?"
                                    value={vehicle.used} onChange={() => handleUsedChange(vehicle.id)}/>
                      </Col>}
                    <Col xs="auto">
                      <FontAwesomeIcon icon={faPencil} className="pointing" onClick={() => {if (vehicle.used) setEditVehicle(vehicle.id)}}/>
                    </Col>
                  </Row>
                  {vehicle.driver != null &&
                    <ListGroup>
                      <ListGroup.Item disabled={!vehicle.used}>
                        <Row className="align-items-baseline">
                          <Col xs={7} style={{whiteSpace: "nowrap"}}>{vehicle.driver?.full_name}</Col>
                          {vehicle.driver?.start_time != null &&
                            <Col className="text-end"><Badge style={{fontSize: "12px"}} bg="primary">{parseTime(vehicle.driver?.start_time)}</Badge></Col>}
                        </Row>
                      </ListGroup.Item>
                    </ListGroup>
                  }
                  <p className="mb-1 mt-1"><FontAwesomeIcon icon={faCircleInfo} color="gray" /> {vehicle.info}</p>
                </>)
              }
              <ListGroup variant="flush" style={{whiteSpace: "nowrap"}}>
                <Droppable droppableId={vehicle.id + ""} key={vehicle.id + ""}>
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      <Crew people={vehicle.crew}/>
                      {provided.placeholder}
                      <p className="mt-2">Zbývající místa: {vehicle.capacity - vehicle.crew.length}</p>
                    </div>
                  )}
                </Droppable>
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>);
    });
    return (res);
  }

  function Crew(props) {
    const crew = [];
    props.people?.forEach((person, index) => {
      crew.push(
        <Draggable key={person.id + ""} draggableId={person.id + ""} index={index}>
          {(provided) => (
            <ListGroup.Item ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
              <Row className="align-items-baseline">
                <Col xs={7} style={{whiteSpace: "nowrap"}}>{person.full_name}</Col>
                {person.start_time != null && <Col className="text-end"><Badge style={{fontSize: "12px"}} bg="primary">{parseTime(person.start_time)}</Badge></Col>}
              </Row>
            </ListGroup.Item>
          )}
        </Draggable>
      );
    });
    if(crew.length < 1){
      return (<Card className="mt-2"><i></i></Card>)
    }
    return crew;
  }

  function Unassigned(props) {
    const crew = [];
    props.people?.forEach((person, index) => {
      crew.push(
        <Col xs="auto" className="ps-1 pe-1" key={person.id + ""}>
          <Draggable key={person.id + ""} draggableId={person.id + ""} index={index}>
            {(provided) => (
              <Card className="mt-2" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                <div className="m-2">{person.full_name} {person.start_time != null && <Badge style={{fontSize: "12px"}} bg="primary">{parseTime(person.start_time)}</Badge>}</div>
              </Card>
            )}
          </Draggable>
        </Col>
      );
    });
    return crew;
  }
}