import {useMapEvents} from "react-leaflet/hooks";
import {MapMarker} from "./MapUtilities";
import {isMobileDevice} from "../../helpers/Device";
import {MapContainer, Marker, TileLayer} from "react-leaflet";
import {MAP_API_KEY} from "../../helpers/Constants";
import React from "react";

const MapPositionSelect = ({GPSlat, GPSlong, setGPSlat, setGPSlong, setShowMap}) => {
  function MapClickHandler() {
    useMapEvents({
      click: (e) => {
        const {lat, lng} = e.latlng;
        setGPSlong(parseFloat(lng.toFixed(4)));
        setGPSlat(parseFloat(lat.toFixed(4)));
        setShowMap(false);
      }
    })
    return null
  }

  let position = [GPSlat, GPSlong];
  if (GPSlong == null || GPSlong === 0)
    position = [50.0, 15.0];

  let markerIcon = MapMarker();

  const container_height = isMobileDevice() ? '200px': '400px';
  return (
    <MapContainer center={position} zoom={13} style={{height: container_height, width: '100%', zIndex: 1}}>
      <TileLayer
        url={`https://api.mapy.cz/v1/maptiles/outdoor/256/{z}/{x}/{y}?apikey=${MAP_API_KEY}`}
        attribution={"<a href='https://api.mapy.cz/copyright' target='_blank'>&copy; Seznam.cz a.s. a další</a>"}
        minZoom={0}
        maxZoom={19}
      />
      <MapClickHandler />
      <Marker position={position} icon={markerIcon} />
    </MapContainer>
  );
}

export default MapPositionSelect;