import {Link, useParams} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import {exportAppliedMails} from "../../dao";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import copy from 'clipboard-copy';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCopy} from "@fortawesome/free-solid-svg-icons";
import Form from "react-bootstrap/Form";
import {handleCsvExport} from "../../helpers/Exports";
import LoadingCard from "../../components/overlays/LoadingCard";
import {handleErrorLoading, setBrowserTabText} from "../../helpers/Functions";
import ErrorAlertFullscreen from "../../components/overlays/ErrorAlertFullscreen";
import PageContainer from "../../layout/PageContainer";
import PageHeading from "../../layout/PageHeading";
import {API_URL} from "../../helpers/Constants";


const EventExport =() => {
  const { eventId } = useParams();
  const [errorRemote, setErrorRemote] = useState("");
  const [data, setData] = useState([]);
  const [delim, setDelim] = useState(',');
  const method = "export-applied?event="
  const [isExporting, setIsExporting] = useState(false);

  useEffect(() => {
    exportAppliedMails(API_URL + method + eventId)
      .then((response) => {
        if (!response.ok) {
          setErrorRemote(handleErrorLoading(response));
          return [];
        }
        return response.json()})
      .then((response) => {
        setData(response);
      })
      .catch((err) => console.log(err));
  }, [eventId]);

  const downloadLinkRef = useRef(null);

  const handleCopyToClipboard = (data) => {
    copy(data);
    // You can add additional logic or UI feedback for successful copy
    console.log('Copied to clipboard:', data);
  };

  if (errorRemote !== "")
    return <ErrorAlertFullscreen error={errorRemote} />
  if (isExporting)
    return <LoadingCard text={"Exportování dat"} />

  setBrowserTabText('Exporty | ' + data?.event?.title);
  return (
    <PageContainer>
      <PageHeading heading={"Exporty: " + data?.event?.title} id={eventId} />
      <Card className="mb-3">
        <Card.Body>
          <Row>
            <Card.Title>Exporty do CSV</Card.Title>
            <Col xs="auto">
              <Form.Group controlId="form">
                <Row>
                  <Col xs="auto mt-1"><Form.Label size="sm">Oddělovač:</Form.Label></Col>
                  <Col xs="auto"><Form.Select size="sm" className="mb-2" onChange={(e) => setDelim(e.target.value)} value={delim}>
                    <option value=",">, (čárka)</option>
                    <option value=";">; (středník)</option>
                  </Form.Select></Col>
                </Row>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs="auto">
              <a href="#" ref={downloadLinkRef} style={{ display: 'none' }} download="emails_primary.csv"></a>
              <Button onClick={() => handleCsvExport(API_URL + method + eventId + "&applied=1&delim=" + delim, 'prihlasky_' + eventId + '.csv', setIsExporting)} className="mb-2">Přihlášky</Button>
            </Col>
            <Col xs="auto">
              <a href="#" ref={downloadLinkRef} style={{ display: 'none' }} download="emails_primary.csv"></a>
              <Button variant="secondary" onClick={() => handleCsvExport(API_URL + method + eventId + "&mails=1&delim=" + delim, 'maily_' + eventId + '.csv', setIsExporting)} className="mb-2">Emaily primární</Button>
            </Col>
            <Col xs="auto">
              <a href="#" ref={downloadLinkRef} style={{ display: 'none' }} download="emails_primary_extended.csv"></a>
              <Button variant="secondary" onClick={() => handleCsvExport(API_URL + method + eventId + "&mails=1&guardians=1&delim=" + delim, 'maily_ucty_nadrazeni_' + eventId + '.csv', setIsExporting)} className="mb-2">Emaily primární + nadřazení</Button>
            </Col>
            <Col xs="auto">
              <a href="#" ref={downloadLinkRef} style={{ display: 'none' }} download="emails_all.csv"></a>
              <Button variant="secondary" onClick={() => handleCsvExport(API_URL + method + eventId + "&mails=1&all=1&delim=" + delim, 'maily_vse_' + eventId + '.csv', setIsExporting)} className="mb-2">Emaily všechny</Button>
            </Col>
            <Col xs="auto">
              <a href="#" ref={downloadLinkRef} style={{ display: 'none' }} download="si_droid.csv"></a>
              <Button onClick={() => handleCsvExport(API_URL + method + eventId + "&si=1&delim=" + delim, 'si_droid_' + eventId + '.txt', setIsExporting)} className="mb-2">SI Droid</Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Card className="mb-3">
        <Card.Body>
          <Card.Title>Emaily primární</Card.Title>
          <p><i>- primární emailové adresy přihlášených</i></p>
          <MailRows emails={data.primary} />
        </Card.Body>
      </Card>
      <Card className="mb-3">
        <Card.Body>
          <Card.Title>Emaily primární + nadřazení</Card.Title>
          <p><i>- primární emailové adresy přihlášených + email nadřazených účtů (pro přihlášené s nadřazeným účtem)</i></p>
          <MailRows emails={data.wardship} />
        </Card.Body>
      </Card>
      <Card className="mb-3">
        <Card.Body>
          <Card.Title>Emaily všechny</Card.Title>
          <p><i>- primární a sekundární emailové adresy přihlášených</i></p>
          <MailRows emails={data.all} />
        </Card.Body>
      </Card>
      <p><i>Emaily jsou dělené do bloků po maximálně 98 adresách, adresy jsou bez duplicit.</i></p>
    </PageContainer>
  );

  function MailRows(props) {
    if(props.emails != null){
      const rows = [];
      for(let element in props.emails) {
        if (rows.length !== 0)
          rows.push(<br/>)
        rows.push(<div key={element} style={props.style}>{props.emails[element]}<Link title={"Kopírovat blok"} to={undefined} onClick={() => handleCopyToClipboard(props.emails[element])}><FontAwesomeIcon icon={faCopy}/></Link></div>);
      }
      return(rows);
    }
  }
}

export default EventExport