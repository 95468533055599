import Form from "react-bootstrap/Form";
import React from "react";


const FormCheckbox = ({label, value, setValue, type="checkbox", disabled=false, tooltip="", condensed=false, controlId}) => {
  return (
    <Form.Group className={condensed ? "mb-0" : "mb-3"} controlId={controlId}>
      <Form.Check type={type} label={label} defaultChecked={value} disabled={disabled} title={tooltip}
                  onChange={(e) => setValue(e.target.checked)}/>
    </Form.Group>
  )
}

export default FormCheckbox;