import PageContainer from "../../layout/PageContainer";
import {handleErrorLoading, handleErrorSubmit, parseDateTime, setBrowserTabText} from "../../helpers/Functions";
import {deleteBugReport, getBugReports, putBugReport} from "../../dao";
import React, {useEffect, useState} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import SuccessAlert from "../../components/overlays/SuccessAlert";
import ErrorAlert from "../../components/overlays/ErrorAlert";
import ErrorAlertFullscreen from "../../components/overlays/ErrorAlertFullscreen";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBoxArchive, faBoxOpen, faQuestionCircle, faTrash} from "@fortawesome/free-solid-svg-icons";
import Modal from "react-bootstrap/Modal";
import AutoFormat from "../../components/visual/AutoFormat";
import FormCheckbox from "../../components/form/FormCheckbox";


const BugsReported = () => {
  const [data, setData] = useState([]);
  const [error, setError] = useState("");
  const [errorRemote, setErrorRemote] = useState("");
  const [success, setSuccess] = useState("");
  const [archived, setArchived] = useState(false);
  const [toDelete, setToDelete] = useState(null);

  useEffect(() => {
    loadData();
  }, [archived]);

  function loadData() {
    getBugReports(archived)
      .then((response) => {
        if (!response.ok) {
          setErrorRemote(handleErrorLoading(response));
          return [];
        }
        return response.json();
      })
      .then((response) => {
        setData(response);
      })
      .catch((err) => console.log(err));
  }

  function deleteReport(id) {
    deleteBugReport(id)
      .then((response) => {
        if (response.ok) {
          setSuccess("Smazáno / Archivováno");
          loadData();
        } else {
          setError(handleErrorSubmit(response));
        }
        setToDelete(null);
      })
  }

  function unarchiveReport(id) {
    putBugReport(id)
      .then((response) => {
        if (response.ok) {
          setSuccess("Archivovace zrušena");
          loadData();
        } else {
          setError(handleErrorSubmit(response));
        }
        setToDelete(null);
      })
  }

  if (errorRemote !== "")
    return <ErrorAlertFullscreen error={errorRemote} />

  setBrowserTabText('Nahlášené chyby');
  return (
    <PageContainer>
      <h1>Nahlášené chyby</h1>
      <Row className="mb-2">
        <Col>
          <FormCheckbox type={"switch"} label={"Archivované"} value={archived} setValue={setArchived}/>
        </Col>
      </Row>
      <ReportsSection />
      <Modal show={toDelete != null} onHide={()=>setToDelete(null)}>
        <Modal.Header closeButton>
          <FontAwesomeIcon icon={faQuestionCircle} size='3x' bounce /> &nbsp;
          <Modal.Title>{toDelete?.archived ? "Smazat" : "Archivovat"}?</Modal.Title>
        </Modal.Header>
        <Modal.Body>{toDelete != null && <>{toDelete.title}</>}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={()=>setToDelete(null)}>
            Zavřít
          </Button>
          <Button variant="primary" onClick={()=>deleteReport(toDelete.id)}>
            {toDelete?.archived ? "Smazat" : "Archivovat"}
          </Button>
        </Modal.Footer>
      </Modal>
      <ErrorAlert error={error} handleClose={()=> setError("")}/>
      <SuccessAlert title={success} handleClose={()=> setSuccess("")}/>
    </PageContainer>
  )

  function ReportsSection() {
    const rows = [];
    data.forEach((record)=>{
      rows.push(
        <div key={record.id}>
          <Row><Col>{record?.archived && <><FontAwesomeIcon icon={faBoxArchive}/> </>}<b>{record.title}</b></Col></Row>
          <Row className="mb-2"><Col><AutoFormat text={record.detail}/></Col></Row>
          <Row>
            <Col><i>{record.reporter.reg_number} – {record.reporter.full_name}</i></Col>
            <Col className="text-end">{parseDateTime(record.timestamp)} {record?.archived && <><FontAwesomeIcon className="pointing" icon={faBoxOpen} onClick={() => unarchiveReport(record.id)}/> </>}<FontAwesomeIcon className="pointing" icon={record?.archived ? faTrash : faBoxArchive} onClick={() => setToDelete(record)}/></Col>
          </Row>
          <hr/>
        </div>
      );
    });
    return rows;
  }
}

export default BugsReported;