import React, {useEffect, useState} from "react";
import {getForeignEvents, putExchangeRate} from "../../dao";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencil} from "@fortawesome/free-solid-svg-icons";
import Table from "react-bootstrap/Table";
import {handleErrorLoading, handleErrorSubmit, parseDate, parseDateBreakYear, setBrowserTabText, YearOptions} from "../../helpers/Functions";
import {Link} from "react-router-dom";
import Loader from "../../components/overlays/Loader";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import ErrorAlert from "../../components/overlays/ErrorAlert";
import SuccessAlert from "../../components/overlays/SuccessAlert";
import ErrorAlertFullscreen from "../../components/overlays/ErrorAlertFullscreen";
import PageContainer from "../../layout/PageContainer";
import LoadingCard from "../../components/overlays/LoadingCard";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormField from "../../components/form/FormField";
import FormSelectArray from "../../components/form/FormSelectArray";


const ForeignEvents = () => {
  const [data, setData] = useState([]);
  const [error, setError] = useState("");
  const [errorRemote, setErrorRemote] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [year, setYear] = useState((new Date()).getFullYear());
  const [eventToChange, setEventToChange] = useState(null);
  const [exchangeRate, setExchangeRate] = useState("1");

  useEffect(() => {
    loadData(year);
  }, [year]);

  function loadData(year) {
    setLoading(true);
    getForeignEvents(year)
      .then((response) => {
        if (!response.ok) {
          setErrorRemote(handleErrorLoading(response));
          return [];
        }
        return response.json();
      })
      .then((response) => {
        setData(response);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }

  function changeExchangeRate(id) {
    setProcessing(true);
    putExchangeRate(id, exchangeRate)
      .then((response) => {
        if (response.ok) {
          setSuccess("Nastavení kurzu změněno");
          loadData(year);
        } else {
          setError(handleErrorSubmit(response, "Nastavení nešlo změnit"));
        }
        setEventToChange(null);
        setProcessing(false);
      })
  }

  if (loading)
    return <Loader />;
  if (processing)
    return <LoadingCard />;
  if (errorRemote !== "")
    return <ErrorAlertFullscreen error={errorRemote} />

  setBrowserTabText('Pohledávky v cizí měně');
  return (
    <PageContainer>
      <Row className="align-items-baseline">
        <Col sm>
          <h1>Pohledávky v cizí měně</h1>
        </Col>
        <Col sm>
          <FormSelectArray label={"Rok"} inline={true} size="sm" value={year} setValue={setYear} options={YearOptions()} controlId={"year"} />
        </Col>
      </Row>
      <RequisitionEventsTable/>
      <Modal show={eventToChange != null} onHide={()=>setEventToChange(null)}>
        <Modal.Header closeButton>
          <Modal.Title>Změnit kurz akce</Modal.Title>
          <Modal.Body>{eventToChange != null && <>{parseDate(eventToChange.date)}<br/>{eventToChange.title}</>}</Modal.Body>
        </Modal.Header>
        <Modal.Body>
          <Row className="align-items-baseline">
            <Col xs={3}>1 {eventToChange?.currency} = </Col>
            <Col xs={9}>
              <Row className="align-items-baseline">
                <Col>
                  <FormField label={""} type={"decimal"} value={exchangeRate} setValue={setExchangeRate} controlId={"rate"} />
                </Col>
                <Col xs="auto">CZK</Col>
              </Row>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={()=>setEventToChange(null)}>
            Zavřít
          </Button>
          <Button variant="primary" onClick={()=>changeExchangeRate(eventToChange.id)}>
            Změnit
          </Button>
        </Modal.Footer>
      </Modal>
      <ErrorAlert error={error} handleClose={() => setError("")}/>
      <SuccessAlert title={success} handleClose={()=>setSuccess("")}/>
    </PageContainer>
  );

  function RequisitionEventsTable() {
    if (data.events.length < 1){
      return(<p><i>Žádné pohledávky v historii</i></p>)
    }
    const rows = [];
    for (const i in data?.events) {
      const event = data.events[i];
      rows.push(<tr key={event.id}>
        <td>{parseDateBreakYear(event.date)}</td>
        <td><Link to={`/akce/${event.id}/pohledavky`} >{event.title}</Link></td>
        <td>{event.reqs}</td>
        <td>{event.currency}</td>
        <td>{event.exchange_rate}</td>
        <td className="td-w-icon pointing"><FontAwesomeIcon icon={faPencil} onClick={() => {
          setEventToChange(event);
          setExchangeRate(event.exchange_rate);
        }}/></td>
      </tr>);
    }

    return (
      <Table striped bordered hover responsive>
        <thead>
        <tr>
          <th>Datum</th>
          <th>Název</th>
          <th>Př.</th>
          <th>Měna</th>
          <th>Kurz</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        {rows}
        </tbody>
      </Table>
    );
  }
}

export default ForeignEvents;