import { Outlet } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import useColormode from "../helpers/Colormode";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSun,
  faMoon,
} from '@fortawesome/free-solid-svg-icons';
import LogoSvg from "../components/visual/LogoSvg";

const PageNavAnonym = () => {
  const { colormode, switchColormode, initColorMode } = useColormode();

  initColorMode();
  function ModeButton() {
    return colormode === "dark" ? <FontAwesomeIcon icon={faSun} onClick={switchMode} /> : <FontAwesomeIcon icon={faMoon} onClick={switchMode} />;

    function switchMode() {
      switchColormode();
    }
  }

  return (
    <>
      <Navbar bg={colormode} expand="lg" fixed="top">
        <Container>
          <Navbar.Brand>
            <LogoSvg zoom={1} />
          </Navbar.Brand>
          <Navbar.Text >
            <Nav style={{ width: "100%" }}>
              <Nav.Link className="ms-auto mt-auto me-3"><ModeButton /></Nav.Link>
            </Nav>
          </Navbar.Text>
        </Container>
      </Navbar>
      <Outlet />
    </>
  );
};

export default PageNavAnonym;