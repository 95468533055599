import { useState } from "react";
import Container from "react-bootstrap/Container";
import { useEffect } from "react";
import { getServices } from "../../dao";
import { useParams } from "react-router-dom";
import useUserData from "../../helpers/Auth";
import Loader from "../../components/overlays/Loader";
import Table from "react-bootstrap/Table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartPlus, faClipboardCheck } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";
import { Navigate } from "react-router-dom";
import {parseDate} from "../../helpers/Functions";

const ClothesDetail = () => {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [serviceToBuy, setServiceToBuy] = useState("");
  const { userData, setUserData } = useUserData();

  useEffect(() => {
    setLoading(true);
    /*getServices(id)
      .then((response) => {
        if (!response.ok) {
          setErrorRemote(handleErrorRemote(response));
          return [];
        }
        return response.json()}) 
      .then((response) => {*/
        setData([{
            title:"Bundička",
            img:"",
            price:3000
        },
        {
            title:"Dres",
            img:"",
            price:1500
        }]);
        setLoading(false);
      /*})
    .catch((err) => console.log(err));*/
  }, []);

  console.log(JSON.stringify(data));
  console.log(JSON.stringify(data.services));
  if(data?.services == undefined){
    return (<p>Žádné doplňkové služby</p> );
  }
  return(
  <>
    <br/>
    {loading ?
        (<Loader />)
        :
        (<Container>
        <h1>Doplňkové služby</h1>
        <p className="mb-4">
          <Link to={"/akce/"+id+"/prihlaska/me"}>
            <FontAwesomeIcon icon={faClipboardCheck} /> Přihláška
          </Link>
        </p>
        {
          data.services.length == 0 ?
          (
            <p>Žádné doplňkové služby</p>
          ):(
            <ServiceTable services={data.services}/>
          )
        }    
      
      </Container>
    )}
  </>);

  function ServiceTable(props){
    var rows = [];
    props.services.forEach(service => {
      rows.push(<tr>
        <td>{service.title}</td>
        <td>{service.fee}</td>
        <td>{service.ordered}/{service.amount}</td>
        {service.can_add?(<td><FontAwesomeIcon icon={faCartPlus} onClick={setServiceToBuy(service.id)}/></td>):(<td></td>)}
      </tr>)
    });

    return (
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Popis</th>
            <th>Cena</th>
            <th>Objednáno</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
      </Table>
    )

  }
};
  
export default ClothesDetail;