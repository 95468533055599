import Button from "react-bootstrap/Button";
import Form from 'react-bootstrap/Form';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { createCamp } from "../../dao";
import React, { useState } from 'react';
import { faCalendarPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ErrorAlert from "../../components/overlays/ErrorAlert";
import SuccessAlert from "../../components/overlays/SuccessAlert";
import { Card } from "react-bootstrap";
import PageContainer from "../../layout/PageContainer";
import {handleErrorSubmit} from "../../helpers/Functions";
import FormField from "../../components/form/FormField";
import FormSelectDict from "../../components/form/FormSelectDict";
import {PunchingOptions, QuestionTypeOptions, TernaryOptionsTransport} from "../../helpers/Constants";
import {SelectOptionsDict} from "../../components/form/SelectOptions";
import {useNavigate} from "react-router-dom";

const CreateCamp = () => {
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [processing, setProcessing] = useState(false);

  const [title, setTitle] = useState("");
  const [date, setDate] = useState("");
  const [dateTo, setDateTo] = useState("");
  
  const [place, setPlace] = useState("");
  const [info, setInfo] = useState("");
  const [eventInfo, setEventInfo] = useState("");

  const [warning, setWarning] = useState("");
  const [termFirst, setTermFirst] = useState("");
  const [visible, setVisible] = useState(true);

  const [punching, setPunching] = useState(1);
  const [transport, setTransport] = useState(1);
  const [transportInfo, setTransportInfo] = useState("");
  
  const [form, setForm] = useState([]);
  const navigate = useNavigate();

  const CampFormQuestion = (props) => {
    return (
    <Col md={12}>
      <Card className="m-2" key={props.questionId}>
        <Card.Body>
          <Row>
            <Col md>
              <Row>
                <Col md>
                  <Form.Group className="mb-3" controlId={"question-title"}>
                    <Form.Label>Otázka {props.questionId + 1}:</Form.Label>
                    <Form.Control type="text" value={props.question["title"]}
                                  onChange={(e)=>{
                                    handleChange(e, props.questionId, "title");
                                  }}
                    />
                  </Form.Group>
                </Col>
                <Col md>
                  <Form.Group className="mb-3" controlId={"question-title"}>
                    <Form.Label>Typ otázky:</Form.Label>
                    <Form.Select value={props.question.type}
                                 onChange={(e) => {
                                   handleQuestionTypeChange(e, props.questionId);
                                 }}>
                      <SelectOptionsDict options={QuestionTypeOptions} />
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md>
                  <Form.Group className="mb-2" controlId="qNote">
                    <Form.Label>Poznámka k otázce:</Form.Label>
                    <Form.Control type="text" value={props.question["note"]}
                                  onChange={(e)=>{
                                    handleChange(e, props.questionId, "note");
                                  }}
                    />
                  </Form.Group>
                </Col>
                <Col md>
                  {props.question.type !== 0 &&
                    <Form.Group className="mb-2" controlId="choices">
                      <Form.Label>Možnosti (oddělené středníkem ;):</Form.Label>
                      <Form.Control type="text" value={props.question["choices"]}
                                    onChange={(e)=>{
                                      handleChange(e, props.questionId, "choices");
                                    }}
                      />
                    </Form.Group>
                  }
                </Col>
              </Row>
            </Col>
            <Col md={2}>
              <Row>
                <Col style={{minWidth: "130px"}} className="mb-4">
                  <Form.Group className="mb-3" controlId="visible">
                    <br/>
                    <Form.Check type="checkbox" label="Povinná" defaultChecked={true}
                                value={form[props.questionId]["required"]} onChange={(e) => {
                      var tempForm = [...form];
                      tempForm[props.questionId]["required"] = e.target.checked;
                      setForm(tempForm);
                    }}/>
                  </Form.Group>
                </Col>
                <Col style={{minWidth: "90px"}}>
                  {props.question.type !== 0 &&
                  <Form.Group className="mb-3" controlId="other">
                    <br/>
                    <Form.Check type="checkbox" label="Jiné" defaultChecked={false}
                                value={form[props.questionId]["other_choice"]} onChange={(e) => {
                      var tempForm = [...form];
                      tempForm[props.questionId]["other_choice"] = e.target.checked;
                      setForm(tempForm);
                    }}/>
                  </Form.Group>}
                </Col>
                <Col style={{minWidth: "70px"}} className="text-end">
                  <Button variant="secondary" className="mt-4" onClick={()=>{var tempForm = [...form]; tempForm.splice(props.questionId, 1); setForm(tempForm)}}>
                    <FontAwesomeIcon icon={faTrash} />
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Col>
    );
  }

  const CampFormCreator = () => {
    var res = [];
    form.forEach((question, questionId) => {
      const nprops={questionId: questionId, question:question}
      res.push(CampFormQuestion(nprops));
    });
    return res;
  }

  let handleSubmit = async (e) => {
    e.preventDefault();
    setProcessing(true);
    let tempForm = JSON.parse(JSON.stringify(form));
    var res = await createCamp(title, date, dateTo, place, info, eventInfo, warning, punching, transport, transportInfo, visible, termFirst, tempForm);
    if (res.ok) {
      setSuccess("Akce vytvořena");
      setTitle("");
    } else {
      setError(handleErrorSubmit(res, "Nepodařilo se vytvořit"));
    }
    setProcessing(false);
  };

  let handleSubmitRedirectToCopy = async (e) => {
    e.preventDefault();
    setProcessing(true);
    var res = await createCamp(title, date, dateTo, place, info, eventInfo, warning, punching, transport, transportInfo, visible, termFirst, []);
    if (res.ok) {
      setSuccess("Akce vytvořena");
      setTitle("");
      res.json().then((r) => {
        navigate("/akce/"+r.id+"/kopirovat");
      });
    } else {
      setError(handleErrorSubmit(res, "Nepodařilo se vytvořit"));
    }
    setProcessing(false);
  };

  return (
    <PageContainer>
      <h1><FontAwesomeIcon icon={faCalendarPlus} /> Vytvořit soustředění</h1>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col>
            <FormField label={"Název"} value={title} setValue={setTitle} valueLimit={512} controlId={"title"} />
          </Col>
          <Col md>
            <FormField label={"Datum od"} type={"date"} value={date} setValue={setDate} controlId={"date"} />
          </Col>
          <Col md>
            <FormField label={"Datum do"} type={"date"} value={dateTo} setValue={setDateTo} controlId={"dateTo"} />
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <FormField label={"Termín přihlášek"} type={"datetime-local"} value={termFirst} setValue={setTermFirst} controlId={"termFirst"} />
          </Col>
          <Col md={4}>
            <FormField label={"Místo"} value={place} setValue={setPlace} valueLimit={512} controlId={"place"} />
          </Col>
          <Col md>
            <Form.Group className="mb-3" controlId="visible">
              <br/>
              <Form.Check type="checkbox" label="Viditelná pro běžné uživatele" defaultChecked={visible}
                          value={visible} onChange={(e) => setVisible(e.target.checked)}/>
            </Form.Group>
          </Col>
          <hr/>
        </Row>
        <Row>
          <Col>
            <FormSelectDict label={"Ražení"} value={punching} setValue={setPunching} options={PunchingOptions} controlId={"punching"} />
          </Col>
          <Col md>
            <FormSelectDict label={"Klubová doprava"} value={transport} setValue={setTransport} options={TernaryOptionsTransport} controlId={"transport"} />
          </Col>
          <Col md>
            <FormField label={"Info o dopravě"} value={transportInfo} setValue={setTransportInfo} valueLimit={128} controlId={"transportInfo"} />
          </Col>
        </Row>
        <Row>
          <Col>
            <FormField label={"Informace"} value={info} setValue={setInfo} valueLimit={2048} controlId={"info"} />
          </Col>
          <Col md>
            <FormField label={"Modré info"} value={eventInfo} setValue={setEventInfo} valueLimit={2048} controlId={"eventInfo"} />
          </Col>
          <Col md>
            <FormField label={"Varování"} value={warning} setValue={setWarning} valueLimit={2048} controlId={"eventWarning"} />
          </Col>
        </Row>
        <hr />
        <h3>Formulář pro účastníky</h3>
        <Row>
            {CampFormCreator()}
        </Row>
        <Row>
          <Col className="ms-2 mb-2" xs="auto">
            <Button variant="secondary" onClick={addQuestion}>+ Přidat otázku</Button>
          </Col>
        </Row>
        <Row>
          <Col className="me-2 text-end">
            <Button variant="primary" type="submit" disabled={title === "" || date === "" || processing}>Vytvořit</Button>
          </Col>
          {form.length === 0 && <Col xs="auto" className="me-2 text-end">
            <Button variant="outline-primary" disabled={title === "" || date === "" || processing} onClick={(e) => {handleSubmitRedirectToCopy(e)}}>Vytvořit a zkopírovat formulář</Button>
          </Col>}
        </Row>
        <br/>
      </Form>
      <ErrorAlert error={error} handleClose={()=>setError("")}/>
      <SuccessAlert title={success} handleClose={()=>setSuccess("")}/>
    </PageContainer>
  );

  function handleQuestionTypeChange(e, questionId){
    let prevform = JSON.parse(JSON.stringify(form));
    const type = Number(e.target.value)
    switch(type){
      case 1: //single choice
        prevform[questionId]["multichoice"] = false;
        break;
      case 2: //multi choice
        prevform[questionId]["multichoice"] = true;
        break;
      default: //open
        prevform[questionId]["choices"] = "";
        break;
    }
    prevform[questionId]["type"] = type;
    setForm(prevform);
  }

  function handleChange(e, questionId, key){
    setForm((prevState)=>{
        const prevform = [...prevState];
        prevform[questionId][key] = e.target.value;
        return prevform;
    });
  }

  function addQuestion(){
    var tempForm = [...form];
    tempForm.push({
      title: "",
      note: "",
      choices: "",
      type: 0,
      other_choice: false,
      required: true
    });
    setForm(tempForm);
  }
};

export default CreateCamp;