import Button from "react-bootstrap/Button";
import Form from 'react-bootstrap/Form';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { createTraining } from "../../dao";
import React, { useState } from 'react';
import { faCalendarPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ErrorAlert from "../../components/overlays/ErrorAlert";
import SuccessAlert from "../../components/overlays/SuccessAlert";
import {DisciplineBasicOptions, PunchingOptions, TernaryOptionsTransport} from "../../helpers/Constants";
import PageContainer from "../../layout/PageContainer";
import {handleErrorSubmit} from "../../helpers/Functions";
import FormField from "../../components/form/FormField";
import FormSelectDict from "../../components/form/FormSelectDict";
import FormCheckbox from "../../components/form/FormCheckbox";

const CreateTraining = () => {
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [processing, setProcessing] = useState(false);

  const [title, setTitle] = useState("");
  const [date, setDate] = useState("");
  const [place, setPlace] = useState("");

  const [info, setInfo] = useState("");
  const [eventInfo, setEventInfo] = useState("");
  const [warning, setWarning] = useState("");

  const [termFirst, setTermFirst] = useState("");
  const [start, setStart] = useState("");
  const [visible, setVisible] = useState(true);

  const [categories, setCategories] = useState("");
  const [discipline, setDiscipline] = useState("AK");
  const [organiser, setOrganiser] = useState("");
  const [punching, setPunching] = useState(1);

  const [transport, setTransport] = useState(0);
  const [transportInfo, setTransportInfo] = useState("");

  let handleSubmit = async (e) => {
    e.preventDefault();
    setProcessing(true);
    const formData = {
      "title": title,
      "date": date,
      "place" : place,
      "info": info,
      "event_info": eventInfo,
      "event_warning": warning,
      "visible": visible,
      "term_first": termFirst,
      "start": start,
      "discipline": discipline,
      "organiser": organiser,
      "punching": punching,
      "classes": categories,
      "transport": transport,
      "transport_info": transportInfo
    }
    const res = await createTraining(formData);
    if (res.ok) {
      setSuccess("Akce vytvořena");
      setTitle("");
      setDate("");
    } else {
      setError(handleErrorSubmit(res,"Nepodařilo se vytvořit"));
    }
    setProcessing(false);
  };

  return (
    <PageContainer>
      <h1><FontAwesomeIcon icon={faCalendarPlus} /> Vytvořit akci</h1>
      <br/>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col>
            <FormField label={"Název"} value={title} setValue={setTitle} valueLimit={512} controlId={"title"} />
          </Col>
          <Col md>
            <FormField label={"Datum"} type="date" value={date} setValue={setDate} controlId={"date"} />
          </Col>
          <Col md>
            <FormField label={"Místo"} value={place} setValue={setPlace} valueLimit={512} controlId={"place"} />
          </Col>
          <hr/>
        </Row>
        <Row>
          <Col>
            <FormField label={"Informace"} value={info} setValue={setInfo} valueLimit={2048} controlId={"info"} />
          </Col>
          <Col md>
            <FormField label={"Modré info"} value={eventInfo} setValue={setEventInfo} valueLimit={2048} controlId={"eventInfo"} />
          </Col>
          <Col md>
            <FormField label={"Varování"} value={warning} setValue={setWarning} valueLimit={2048} controlId={"eventWarning"} />
          </Col>
          <hr />
        </Row>
        <Row className="align-items-end">
          <Col md>
            <FormField label={"Start (hh:mm:ss)"} value={start} setValue={setStart} valueLimit={8} digits={"none"} controlId={"start"} />
          </Col>
          <Col md>
            <FormField label={"Termín přihlášek"} type="datetime-local" value={termFirst} setValue={setTermFirst} controlId={"term"} />
          </Col>
          <Col md>
            <FormCheckbox label={"Viditelná pro běžné uživatele"} value={visible} setValue={setVisible} controlId={"visible"}/>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormSelectDict label={"Disciplína"} value={discipline} setValue={setDiscipline} options={DisciplineBasicOptions} controlId={"discipline"} />
          </Col>
          <Col md>
            <FormField label={"Pořadatel"} value={organiser} setValue={setOrganiser} valueLimit={128} controlId={"organiser"} />
          </Col>
          <Col md>
            <FormSelectDict label={"Ražení"} value={punching} setValue={setPunching} options={PunchingOptions} controlId={"punching"} />
          </Col>
        </Row>
        <Row>
          <Col>
            <FormField label={"Kategorie (odděleny středníkem, formát: název|info;název,...)"} value={categories} setValue={setCategories} valueLimit={500} controlId={"categories"} />
          </Col>
          <hr/>
        </Row>
        <Row>
          <Col>
            <FormSelectDict label={"Klubová doprava"} value={transport} setValue={setTransport} options={TernaryOptionsTransport} controlId={"transport"} />
          </Col>
          <Col md={8}>
            <FormField label={"Info o dopravě"} value={transportInfo} setValue={setTransportInfo} valueLimit={64} controlId={"transportInfo"} />
          </Col>
        </Row>
        <Row>
          <Col className="col-md-12 text-end">
            <Button variant="primary" type="submit" disabled={title === "" || date === "" || categories === "" || processing}>Vytvořit</Button>
          </Col>
        </Row>
        <br/>
      </Form>
      <ErrorAlert error={error} handleClose={()=>setError("")}/>
      <SuccessAlert title={success} handleClose={()=>setSuccess("")}/>
    </PageContainer>
  );
};

export default CreateTraining;