import Table from "react-bootstrap/Table";
import React, { useState, useEffect } from 'react'
import {getMembers} from "../../dao";
import { Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown, faAngleUp, faChartColumn, faChartSimple, faMedal, faSignHanging, faRankingStar, faToolbox,
} from '@fortawesome/free-solid-svg-icons';
import Col from "react-bootstrap/Col";
import useUserData from "../../helpers/Auth";
import {isMobileDevice} from "../../helpers/Device";
import {parseDate, setBrowserTabText} from "../../helpers/Functions";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import PageContainer from "../../layout/PageContainer";
import SearchBar from "../../components/form/SearchBar";
import LoadingIcon from "../../components/overlays/LoadingIcon";
import {GroupOptions} from "../../helpers/Constants";
import {Badge} from "react-bootstrap";

const Club = () => {
  const { userData, setUserData } = useUserData();
  const [loading, setLoading] = useState(true);
  const [members, setMembers] = useState([]);
  useEffect(() => {
    setLoading(true);
    getMembers()
      .then((response) => response.json())
      .then((response) => {
        setMembers(response);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  setBrowserTabText('Klub')
  return (
    <PageContainer>
      <h1>Členové klubu</h1>
      <ClubCard/>
      <MemberTable />
    </PageContainer>
    );

  function ClubCard() {
    let unseenPostsBadge = <Badge style={{fontSize: "0.9em"}} pill bg="secondary">0</Badge>;
    const unseenPosts = localStorage.getItem('unseenPosts');
    if (unseenPosts != null) {
      if (unseenPosts > 0)
        unseenPostsBadge = <Badge style={{fontSize: "0.9rem"}} pill bg="warning">{unseenPosts}</Badge>
    }

    return (
      <Card className="mb-3">
        <Card.Body>
          <Row className="align-items-baseline">
            <Col md={6} sm={12}>
              <Link to={`/klub/nastenka`}><Button className="btn-primary mb-1 mt-1 me-3 tapis-95"><FontAwesomeIcon icon={faSignHanging} /> Nástěnka {unseenPostsBadge}</Button></Link>
              <Link to={`/klub/ranking`}><Button variant="outline-primary" className="mb-1 mt-1 tapis-95"><FontAwesomeIcon icon={faRankingStar} /> Klubový ranking</Button></Link>
            </Col>
            {userData.is_staff && <Col md={6} sm={12} className="text-end">
              <Link to={`/klub/statistiky`}><Button className="mb-1 mt-1 me-3 tapis-95" variant="outline-primary"><FontAwesomeIcon icon={faChartColumn} /> Statistiky</Button></Link>
              <Link to={`/klub/admin`}><Button className="mb-1 mt-1 tapis-95"><FontAwesomeIcon icon={faToolbox} /> Administrace</Button></Link>
            </Col>}
          </Row>
        </Card.Body>
      </Card>
    )
  }

  function MemberTable() {
    const [filteredPeople, setFilteredPeople] = useState(members);

    if (loading)
      return <LoadingIcon />;

    function groupLabelByShortcut(shortcut) {
      if (shortcut == null)
        return ""
      return shortcut + " – " + GroupOptions[shortcut]
    }

    function MemberDetail(elem) {
      const MemberDetailRow = ({label, value}) => {
        return (
          <Row className="m-0">
            <Col className="col-4">{label}</Col>
            <Col className="col-8">{value}</Col>
          </Row>
        )
      }
      return (<>
        <MemberDetailRow key="mail1" label="Email 1:" value={elem.data.mail1}/>
        {userData.is_staff && <>
          {elem.data.mail2 != null && <MemberDetailRow key="mail2" label="Email 2:" value={elem.data.mail2}/>}
          <MemberDetailRow key="phone" label="Telefon:" value={elem.data.phone}/>
          <MemberDetailRow key="address" label="Adresa:" value={elem.data.address}/>
          <MemberDetailRow key="birthdate" label="Datum nar.:" value={parseDate(elem.data.birthdate)}/>
        </>}
        <MemberDetailRow key="group" label="Skupina:" value={groupLabelByShortcut(elem.data.group)}/>
        <MemberDetailRow key="si" label="Čipy:" value={elem.data.si_cards}/>
      </>);
    }

    const TableRow = ({element}) => {
      const [showDetail, setShowDetail] = useState(false);

      function changeState() {
        setShowDetail(current => !current);
      }

      return (<>
        <tr>
          <td>{userData.is_staff ? <Link to={`/ucet/${element.id}`} className="no-decoration">{element.reg_number}</Link> : element.reg_number}</td>
          <td><Link to={`/ucet/${element.id}/zavody/`} className="no-decoration">{element.full_name}</Link></td>
          {!isMobileDevice() && <td>{element.mail1}</td>}
          <td align={"center"} className="pointing" onClick={changeState}><FontAwesomeIcon icon={showDetail ? faAngleUp : faAngleDown}/></td>
        </tr>
        {showDetail && <tr><td colSpan="6" className="p-0 pt-1 pb-1"><MemberDetail data={element}/></td></tr>}
      </>)
    }


    const rows = [];
    filteredPeople.forEach(element => {
      rows.push(<TableRow element={element} key={element.id}/>);
    });

    return (<>
      <SearchBar data={members} setFilteredPeople={setFilteredPeople} />
      <Table striped bordered responsive>
        <thead>
        <tr>
          <th>Reg. {userData?.is_staff && <FontAwesomeIcon icon={faChartSimple}/>}</th>
          <th>Jméno <FontAwesomeIcon icon={faMedal}/></th>
          {!isMobileDevice() && <th>Email primární</th>}
          <th></th>
        </tr>
        </thead>
        <tbody>
        {rows}
        </tbody>
      </Table>
    </>)
  }
};


export default Club;