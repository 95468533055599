import {faMotorcycle, faTree} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import { ReactComponent as FootR } from '../../static/footR.svg';
import { ReactComponent as FootL } from '../../static/footL.svg';
import {Theme} from "../../helpers/Constants";

const Loader = () => {
  if (Theme === 'PGP')
    return <Motorcycle />;
  return <Footprints />;
};

function Motorcycle() {
  return (
    <div className="loader-container" style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      <FontAwesomeIcon icon={faMotorcycle} size='5x' color={"#F04E37"} spin/>
    </div>
  )
}

function Footprints() {
  return (
    <div className="loader-container" style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      <div>
        <Row>
          <Col style={{ paddingRight: 0 }}>
            <FootL style={{
              height: "2.3em",
              width: "2em",
              opacity: 0,
              animationName: 'appearing',
              animationDuration: '1.2s',
              animationIterationCount: 'infinite',
              animationDelay: '1.1s'
            }}></FootL>
          </Col>
          <Col style={{ paddingLeft: 0, paddingTop: '15px' }}>
            <FootR style={{
              height: "2em",
              width: "2em",
              opacity: 0,
              animationName: 'appearing',
              animationDuration: '1.2s',
              animationIterationCount: 'infinite',
              animationDelay: '0.95s'
            }}></FootR>
          </Col>
        </Row>
        <Row>
          <Col style={{ paddingRight: 0 }}>
            <FootL style={{
              height: "2.3em",
              width: "2em",
              opacity: 0,
              animationName: 'appearing',
              animationDuration: '1.2s',
              animationIterationCount: 'infinite',
              animationDelay: '0.8s'
            }}></FootL>
          </Col>
          <Col style={{ paddingLeft: 0, paddingTop: '15px' }}>
            <FootR style={{
              height: "2em",
              width: "2em",
              opacity: 0,
              animationName: 'appearing',
              animationDuration: '1.2s',
              animationIterationCount: 'infinite',
              animationDelay: '0.6s'
            }}></FootR>
          </Col>
        </Row>
        <Row>
          <Col style={{ paddingRight: 0 }}>
            <FootL style={{
              height: "2.3em",
              width: "2em",
              opacity: 0,
              animationName: 'appearing',
              animationDuration: '1.2s',
              animationIterationCount: 'infinite',
              animationDelay: '0.4s'
            }}></FootL>
          </Col>
          <Col style={{ paddingLeft: 0, paddingTop: '15px' }}>
            <FootR style={{
              height: "2em",
              width: "2em",
              opacity: 0,
              animationName: 'appearing',
              animationDuration: '1.2s',
              animationIterationCount: 'infinite',
              animationDelay: '0.2s'
            }}></FootR>
          </Col>
        </Row>
      </div>
    </div>
  );
}
function LoadingTree() {
  return (
    <div className="loader-container" style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: 'green'
    }}>
      <FontAwesomeIcon icon={faTree} size='5x' spin />
    </div>
  );
}

export default Loader;