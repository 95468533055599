import {labels} from "../themeLabels";

const MAP_API_KEY = 'g7MvvtrQRW8QQf4r4NIrvxuJX68RSCvu9cyRxqnsGds';
const API_URL = process.env.REACT_APP_API_URL;
const ORIS_URL = "https://oris.orientacnisporty.cz/"
const TRACKING_URL = "https://tulospalvelu.fi/gps/"
const Theme = process.env.REACT_APP_THEME;
const SystemStartYear = labels.SystemStartYear;
const HomeClub = labels.HomeClub;
const HomeClubBankAccount = labels.HomeClubBankAccount;

const MonthOptions = {
  1: "Leden",
  2: "Únor",
  3: "Březen",
  4: "Duben",
  5: "Květen",
  6: "Červen",
  7: "Červenec",
  8: "Srpen",
  9: "Září",
  10: "Říjen",
  11: "Listopad",
  12: "Prosinec",
}

const MonthOptionsExtended = {
  0: "Aktuální",
  ...MonthOptions
}

const MonthOptionsPrevious = {
  0: "Předchozí",
  ...MonthOptions
}

const GroupOptions = {
  "--" : "--",
  "DO" : "Dorost",
  "SZ" : "Starší žáci",
  "MZ" : "Mladší žáci",
  "DE" : "Děti",
  "AD" : "Aktivní dospělí",
  "PD" : "Pasivní dospělí / rodiče",
  "VE" : "Veteráni",
  "NN" : "nezvolena",
  "UN" : "universal",
}

const MembershipOptions = {
  "R": "Řadový člen",
  "P": "Přispívající člen",
  "O": "Ostatní",
};
const RoleOptions = {
  "U": "Uživatel",
  "H": "Hospodářka",
  "T": "Trenér",
  "X": "Trenér + hospodářka",
  "S": "Správce",
};

const AccountTypeBasicOptions = {
  "A": "A – Aktivní",
  "B": "B – Bez přístupu",
};

const AccountTypeOtherOptions = {
  "C": "C – Lokální",
  "D": "D – Deaktivovaný",
  "E": "E – Externí připojený",
  "F": "F – Externí lokální",
};

const AccountTypeOptions = {
  ...AccountTypeBasicOptions,
  ...AccountTypeOtherOptions,
};

const AdminRoleOptions = {
  "*": "vše",
  ...RoleOptions
}

const AdminAccountTypeOptions = {
  "*": "A + B",
  ...AccountTypeOptions
}

const RegionShortOptions = {
  "" : "Doporučená",
  "*" : "Všechny",
}

const RegionOptions = {
  ...RegionShortOptions,
  "ČR" : "ČR - celá ČR",
  "Č" : "Č - Čechy",
  "M" : "M - Morava",
  "HA" : "HA - Hanácká",
  "JČ" : "JČ - Jihočeská",
  "JE" : "JE - Ještědská",
  "JM" : "JM - Jihomoravská",
  "MSK": "MSK - MS kraj",
  "P" : "P - Pražská",
  "StČ" : "StČ - Středočeská",
  "VA" : "VA - Valašská",
  "VČ" : "VČ - Východočeská",
  "VY" : "VY - Vysočina",
  "ZČ" : "ZČ - Západočeská",
}

const RegionCustomizeOptions = {
  "" : "žádná",
  "M" : "M - Morava",
  "HA" : "HA - Hanácká",
  "JČ" : "JČ - Jihočeská",
  "JE" : "JE - Ještědská",
  "JM" : "JM - Jihomoravská",
  "MSK": "MSK - MS kraj",
  "VA" : "VA - Valašská",
  "VČ" : "VČ - Východočeská",
  "VY" : "VY - Vysočina",
  "ZČ" : "ZČ - Západočeská",
}

const SportTypeOptions = {
  "0": "OB",
  "1": "LOB",
  "2": "MTBO",
  "3": "Trail",
}

const CancelledOptions = {
  "0" : "Ne",
  "1" : "Ano",
}

const EventTypeOptions = {
  "0" : "Oficiální a klubové",
  "1" : "Všechny",
  "2" : "Pouze klubové",
}

const ReqEventTypeOptions = {
  "10" : "Soustředění",
  "11" : "Členský příspěvek",
  "12" : "Jiný",
}

const DisciplineBasicOptions = {
  "KL" : "klasika",
  "KT" : "krátká",
  "SP" : "sprint",
  "ST" : "štafety",
  "SS" : "sprintové štafety",
  "SC" : "scorelauf",
  "MS" : "hromadný start",
  "NOB" : "noční OB",
  "AK" : "jiná akce",
  "MT": "mapový trénink",
  "SO": "soustředění"
}

const DisciplineAllOptions = {
  ...DisciplineBasicOptions,
  "DT" : "dlouhá trať",
  "DR" : "družstva",
  "S" : "školení, seminář",
  "ET" : "etapový",
  "KO" : "knock-out sprint",
  "STK" : "stacionární tréninkový kemp",
  "Z" : "dlouhodobý žebříček",
  "D" : "dráha",
}

const TernaryOptionsTransport = {
  "-1" : "Není",
  "0" : "Je možná",
  "1" : "Je povinná",
}

const TernaryOptionsAccommodation = {
  "-1" : "Není",
  "0" : "Je možné",
  "1" : "Je povinné",
}

const InterestBasicOptions = {
  "0" : "Nemám zájem",
  "1" : "Mám zájem",
}

const InterestExtendedOptions = {
  ...InterestBasicOptions,
  "2" : "Vezmu auto, když bude třeba",
  "3" : "Vezmu auto",
}

const PunchingOptions = {
  "1" : "SI kontaktní",
  "2" : "SI bezkontaktní",
  "4" : "manuální",
}

const BillingModeOptions = {
  "0" : "klasický (pouze P)",
  "1" : "placeno přímo členy",
  "2" : "placeno klubem",
  "3" : "pořádáno klubem",
}

const QuestionTypeOptions = {
  0 : "Otevřená",
  1 : "Jedna možnost",
  2 : "Více možností"
}

const StatsMetrics = {
  "UV" : "účast >=",
  "UM" : "účast <=",
  "OM" : "oficiální <=",
  "DV" : "DNS >=",
  "DM" : "DNS <=",
}

const MobileWidthLimit = 767;

export {
  API_URL, MAP_API_KEY, ORIS_URL, TRACKING_URL, MobileWidthLimit, SystemStartYear, Theme,
  HomeClub, HomeClubBankAccount, MonthOptionsExtended, MonthOptionsPrevious, MonthOptions, GroupOptions, MembershipOptions, RoleOptions, AccountTypeOptions,
  AccountTypeBasicOptions, AccountTypeOtherOptions, AdminRoleOptions, AdminAccountTypeOptions, BillingModeOptions, QuestionTypeOptions, StatsMetrics,
  RegionShortOptions, RegionOptions, RegionCustomizeOptions, SportTypeOptions, CancelledOptions, EventTypeOptions, ReqEventTypeOptions,
  DisciplineBasicOptions, DisciplineAllOptions,
  TernaryOptionsTransport, TernaryOptionsAccommodation, PunchingOptions, InterestBasicOptions, InterestExtendedOptions};