import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Table from "react-bootstrap/Table";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faMagnifyingGlass, faRotate} from '@fortawesome/free-solid-svg-icons';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { useState } from 'react';
import {filterByFullName} from "../../helpers/Functions";

export default function SelectUserModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <PeopleTable people={props.people} error={props.error} id={props?.id} loading={props?.loading}/>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Zavřít</Button>
      </Modal.Footer>
    </Modal>
  );

  function PeopleTable(props){
    const [filteredPeople, setFilteredPeople] = useState(props.people);

    if(props.people?.length > 0){
      const rows = [];
      for(var i in filteredPeople){
        const person = filteredPeople[i];
        rows.push(
          <tr key={person.id}>
            <td>{person.reg_number}</td>
            <td>{person.full_name}</td>
            <td><Link to={props?.id ? "/akce/"+props.id+"/prihlaska/"+person.id : "prihlaska/"+person.id}><Button >Přihlásit</Button></Link></td>
          </tr>
        );
      }
      return(
        <>
          <InputGroup className="mb-3">
            <InputGroup.Text id="search-icon"> <FontAwesomeIcon icon={faMagnifyingGlass}/></InputGroup.Text>
            <Form.Control
              placeholder="Vyhledat dle jména"
              aria-label="Vyhledat"
              aria-describedby="search-icon"
              onChange={(e)=>setFilteredPeople(filterByFullName(e.target.value, props.people))}
            />
          </InputGroup>
          <Table>
            <tbody>
            {rows}
            </tbody>
          </Table>
        </>
      )
    }
    return(<>
      {props?.loading ? <p className="text-center">Probíhá načítání uživatelů... <FontAwesomeIcon icon={faRotate} spin/></p> : <><p>Žádní uživatelé k přihlášení nenalezeni</p>
        <p><i>Nemáš udělena žádná přihlašovací práva nebo jsou všichni tito uživatelé již přihlášeni</i></p></>}</>);
  }
}