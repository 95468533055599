import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const SuccessAlert = (props) => {
  return (
    <Modal show={props.title != null && props.title !== ""} onHide={props.handleClose}>
      <Modal.Header closeButton>
        <FontAwesomeIcon icon={faCheck} size='4x' bounce style={{ color: "green" }} />
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      {props.body !== undefined && <Modal.Body>{props.body}</Modal.Body>}
      <Modal.Footer>
        <Button variant="primary" onClick={props.handleClose}>
          Zavřít
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SuccessAlert;