import {Link} from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope} from "@fortawesome/free-solid-svg-icons";


const PageFooter = () => {
  return (
    <footer>
      <Container>
        <Row className="mt-3 mb-4">
          <Col xs="auto">&copy;  2024 KOB Tretra Praha</Col>
          <Col xs="auto"><Link to='mailto:tapis@tretra.org'><FontAwesomeIcon icon={faEnvelope} /></Link> <Link to='mailto:tapis@tretra.org'>Admin</Link></Col>
        </Row>
      </Container>
    </footer>
  );
};

export default PageFooter;