import Button from "react-bootstrap/Button";
import Form from 'react-bootstrap/Form';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {deleteMirroring, editEvent, putMirroring} from "../../dao";
import React, { useState } from 'react';
import ErrorAlert from "../../components/overlays/ErrorAlert";
import SuccessAlert from "../../components/overlays/SuccessAlert";
import {BillingModeOptions, TernaryOptionsAccommodation, TernaryOptionsTransport} from "../../helpers/Constants";
import {handleErrorSubmit, parseDateTime} from "../../helpers/Functions";
import FormField from "../../components/form/FormField";
import FormSelectDict from "../../components/form/FormSelectDict";
import Table from "react-bootstrap/Table";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faQuestionCircle, faRotate, faTrash} from "@fortawesome/free-solid-svg-icons";
import Modal from "react-bootstrap/Modal";
import {Link} from "react-router-dom";
import FormCheckbox from "../../components/form/FormCheckbox";

const EditOrisEvent = (props) => {
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [processing, setProcessing] = useState(false);

  const [transport, setTransport] = useState(props.event.transport);
  const [transportInfo, setTransportInfo] = useState(props.event.transport_info);
  const [transportFee, setTransportFee] = useState(props.event.transport_fee);

  const [accommodation, setAccommodation] = useState(props.event.accommodation);
  const [accommodationInfo, setAccommodationInfo] = useState(props.event.accommodation_info);
  const [accommodationFee, setAccommodationFee] = useState(props.event.accommodation_fee);

  const [billingMode, setBillingMode] = useState(props.event.billing_mode);
  const [preliminaryTerm, setPreliminaryTerm] = useState(props.event.term_preliminary);
  const [allowPreliminaryTerm, setAllowPreliminaryTerm] = useState(props.event.allow_preliminary);
  const [manualTerm, setManualTerm] = useState(props.event.term_manual);

  const [mirrorData, setMirrorData] = useState(props.mirrorData);
  const [selectMirror, setSelectMirror] = useState(false);
  const [mirrorToRemove, setMirrorToRemove] = useState(null);

  let handleSubmit = async (e) => {
    e.preventDefault();
    const formData = {
      "transport": transport,
      "transport_info": transportInfo,
      "transport_fee": transportFee,
      "accommodation": accommodation,
      "accommodation_info": accommodationInfo,
      "accommodation_fee": accommodationFee,
      "billing_mode": billingMode,
      "allow_preliminary": allowPreliminaryTerm,
      "term_preliminary" : preliminaryTerm,
      "term_manual": manualTerm
    }
    setProcessing(true);
    const res = await editEvent(props.event.id, formData);
    if (res.ok)
      setSuccess("Akce upravena");
    else
      setError(handleErrorSubmit(res, "Nepodařilo se upravit"));
    setProcessing(false);
  };

  function addMirroring(mirror) {
    putMirroring(props.event.id, mirror)
      .then((response) => {
        if (response.ok) {
          setSuccess("Přidáno");
        } else {
          setError(handleErrorSubmit(response, "Nezdařilo se přidat"));
        }
        window.location.reload();
      })
  }

  function removeMirroring(mirror){
    deleteMirroring(props.event.id, mirror)
      .then((response) => {
        if (response.ok) {
          setSuccess("Smazáno");
        } else {
          setError(handleErrorSubmit(response, "Nezdařilo se smazat"));
        }
        setMirrorToRemove(null);
        window.location.reload();
      })
  }

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col md={3} xs={6}>
            <FormSelectDict label={"Klubová doprava"} value={transport} setValue={setTransport} options={TernaryOptionsTransport} controlId={"transport"} />
          </Col>
          <Col md={3} xs={6}>
            <FormField label={"Cena dopravy"} value={transportFee} setValue={setTransportFee} type={"number"} controlId={"transportFee"} />
          </Col>
          <Col md>
            <FormField label={"Info o dopravě"} value={transportInfo} setValue={setTransportInfo} valueLimit={128} controlId={"transportInfo"} />
          </Col>
        </Row>
        <Row>
          <Col md={3} xs={6}>
            <FormSelectDict label={"Klubové ubytování"} value={accommodation} setValue={setAccommodation} options={TernaryOptionsAccommodation} controlId={"accommodation"} />
          </Col>
          <Col md={3} xs={6}>
            <FormField label={"Cena ubytování"} value={accommodationFee} setValue={setAccommodationFee} type={"number"} controlId={"accommodationFee"} />
          </Col>
          <Col md>
            <FormField label={"Info k ubytování"} value={accommodationInfo} setValue={setAccommodationInfo} valueLimit={128} controlId={"accommodationInfo"} />
          </Col>
        </Row>
        <Row>
          <Col md>
            <FormSelectDict label={"Režim vyúčtování"} value={billingMode} setValue={setBillingMode} options={BillingModeOptions} controlId={"billingMode"} />
          </Col>
          <Col md>
            <FormField label={"Předběžný termín"} type={"datetime-local"} value={preliminaryTerm} setValue={setPreliminaryTerm} disabled={!props.event?.edit_preliminary} controlId={"preliminaryTerm"} />
            <FormCheckbox label={"Předběžný termín povolen"} value={allowPreliminaryTerm} setValue={setAllowPreliminaryTerm} disabled={!props.event?.edit_preliminary} controlId={"allowPreliminaryTerm"}/>
          </Col>
          <Col md>
            <FormField label={"Manuální termín"} type={"datetime-local"} value={manualTerm} setValue={setManualTerm} help={"1. termín: " + parseDateTime(props.event.term_first) + ", manuální termín slouží pro štafety pro dřívější ukončení přihlášek"} controlId={"manualTerm"} />
          </Col>
        </Row>
        <Row className="align-items-end">
          <Col className="text-end">
            <Button variant="primary" disabled={processing} type="submit">{processing ? <> <FontAwesomeIcon icon={faRotate} spin/> </> : "Upravit"}</Button>
          </Col>
        </Row>
        <br/>
      </Form>
      <hr/>
      <Row className="mb-2">
        <Col><h4>Zrcadlení dopravy</h4></Col>
        <Col xs="auto">{(!mirrorData.mirrors || mirrorData.mirrors.length === 0) && <Button className="tapis-95" variant="secondary" onClick={() => setSelectMirror(true)}>Přidat akci</Button>}</Col>
      </Row>
      <MirroringTable />
      <SelectMirror show={selectMirror} onHide={() => {setSelectMirror(false);}}/>
      <Modal show={mirrorToRemove != null} onHide={() => setMirrorToRemove(null)}>
        <Modal.Header closeButton>
          <FontAwesomeIcon icon={faQuestionCircle} size='3x' bounce/> &nbsp;
          <Modal.Title>Odebrat zrcadlení?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {mirrorToRemove?.oris_id} – {mirrorToRemove?.title} ({mirrorToRemove?.organiser})
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setMirrorToRemove(null)}>
            Zavřít
          </Button>
          <Button variant="primary" onClick={() => removeMirroring(mirrorToRemove.id)}>
            Smazat
          </Button>
        </Modal.Footer>
      </Modal>
      <ErrorAlert error={error} handleClose={()=>setError("")}/>
      <SuccessAlert title={success} handleClose={()=>setSuccess("")}/>
    </div>
  );

  function MirroringTable() {
    const rows = [];
    if (!mirrorData.mirrors || mirrorData.mirrors.length === 0)
      return <i>Akce není zrcadlena</i>;

    mirrorData.mirrors.forEach((row) => {
      rows.push(<tr key={row.id}>
        <td>{row.oris_id}</td>
        <td><Link to={"/akce/" + row.id} target="_blank">{row.title}</Link></td>
        <td>{row.organiser}</td>
        <td onClick={() => setMirrorToRemove(row)} className="text-center pointing">
          <FontAwesomeIcon icon={faTrash}/></td>
      </tr>);
    });

    return (
      <Table striped bordered hover responsive>
        <thead>
        <tr>
          <th>ORIS</th>
          <th>Název</th>
          <th>Poř.</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        {rows}
        </tbody>
      </Table>
    );
  }

  function SelectMirror(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Vybrat cíl zrcadlení
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EventTable />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setSelectMirror(false)}>Zavřít</Button>
        </Modal.Footer>
      </Modal>
    );

    function EventTable(){
      if(mirrorData.options.length > 0){
        const rows = [];
        mirrorData.options.forEach((row) => {
          rows.push(<tr key={row.id}>
            <td>{row.oris_id}</td>
            <td><Link to={"/akce/" + row.id} target="_blank">{row.title}</Link></td>
            <td>{row.organiser}</td>
            <td>
              <Button onClick={() => {
                setSelectMirror(false);
                addMirroring(row.id);
              }}>Vybrat</Button>
            </td>
          </tr>);
        });

        return (
          <Table>
            <thead>
            <tr>
              <th>ORIS</th>
              <th>Název</th>
              <th>Poř.</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            {rows}
            </tbody>
          </Table>
        )
      }
      return (<p>Nenalezeny žádné možnosti.</p>);
    }
  }
};

export default EditOrisEvent;