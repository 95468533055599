import React, {useEffect, useState} from "react";
import PageContainer from "../../layout/PageContainer";
import {getPostDashboard} from "../../dao";
import {
  handleErrorLoading,
  parseDateBreakYear,
  parseTime,
  replaceTabsWithSpaces,
  setBrowserTabText,
} from "../../helpers/Functions";
import ErrorAlertFullscreen from "../../components/overlays/ErrorAlertFullscreen";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faBoxArchive,
  faPencil,
  faThumbtack
} from "@fortawesome/free-solid-svg-icons";
import FormCheckbox from "../../components/form/FormCheckbox";
import LoadingIcon from "../../components/overlays/LoadingIcon";
import WarningCard from "../../components/overlays/WarningCard";
import PageSwitch from "../../components/visual/PageSwitch";


const PostDashboard = () => {
  const [data, setData] = useState({posts: []});
  const [errorRemote, setErrorRemote] = useState("");
  const [loading, setLoading] = useState(true);
  const [archived, setArchived] = useState(false);
  const [page, setPage] = useState(1);

  useEffect(() => {
    setLoading(true);
    getPostDashboard(archived)
      .then((response) => {
        if (!response.ok) {
          setErrorRemote(handleErrorLoading(response));
          return {posts: [], unseen_posts: 0};
        }
        return response.json()
      })
      .then((response) => {
        setData(response);
        localStorage.setItem('unseenPosts', response?.unseen_posts);
        setLoading(false);
        setPage(1);
      })
      .catch((err) => console.log(err));
  }, [archived]);

  if (errorRemote !== "")
    return <ErrorAlertFullscreen error={errorRemote} />;
  const postsPerPage = 10;

  setBrowserTabText('Klubová nástěnka');
  return (
    <PageContainer>
      <h1>Klubová nástěnka</h1>
      {data.staff && <Row className="mb-2">
        <Col>
          <FormCheckbox type={"switch"} label={"Archivované"} value={archived} setValue={setArchived}/>
        </Col>
        <Col className="text-end">
          <Link to={"pridat"}><Button>+ Přidat</Button></Link>
        </Col>
      </Row>}
      <PostsSection/>
    </PageContainer>
  )


  function PostsSection() {
    if (loading)
      return <LoadingIcon />;
    if (data?.posts.length === 0)
      return <WarningCard text={"Žádné příspěvky nebyly přidány"} />;
    return (<>
      <PageSwitch page={page} setPage={setPage} rowsPerPage={postsPerPage} rowCount={data.posts.length} />
      <PostRows />
      <PageSwitch page={page} setPage={setPage} rowsPerPage={postsPerPage} rowCount={data.posts.length} />
    </>)

    function PostRows() {
      const rows = [];
      const postsPage = data.posts.slice((page - 1) * postsPerPage, page * postsPerPage)
      for (const i in postsPage) {
        const post = postsPage[i];
        rows.push(
          <Row key={post.id}>
            <Col xs={"auto"} className="text-center align-content-center bg-primary m-2 p-2">
              <a href={"/klub/prispevek/" + post.id} className="hide-link tapis-85 p-1" style={{display: 'block', color: 'white'}}>
                <b>{parseDateBreakYear(post.published)}<hr className="mt-1 mb-1"/>{parseTime(post.published.split('T')[1])}</b>
              </a>
            </Col>
            <Col className="mt-2">
              <Row>
                <Col><a href={"/klub/prispevek/" + post.id} className="hide-link"><h3>{post.pinned && <FontAwesomeIcon icon={faThumbtack}/>}{post.archived && <FontAwesomeIcon icon={faBoxArchive}/>} {post.title}</h3></a></Col>
              </Row>
              <Row>
                <div className="post-dashboard" dangerouslySetInnerHTML={{__html: replaceTabsWithSpaces(post.content)}}/>
              </Row>
              <Row style={{ marginTop: '-0.5rem' }}>
                <Col className="text-end tapis-80">
                  <i>{post.can_edit && <a href={"/klub/prispevek/" + post.id + "/upravit"}><FontAwesomeIcon icon={faPencil} className="link-like"/></a>} {post.created_by.reg_number} – {post.created_by.full_name}</i>
                </Col>
              </Row>
            </Col>
            <hr className="mt-1 mb-1"/>
          </Row>
        );
      }
      return rows;
    }
  }
}

export default PostDashboard;