import {MapMarker} from "./MapUtilities";
import {MapContainer, Marker, TileLayer, Tooltip} from "react-leaflet";
import {isMobileDevice} from "../../helpers/Device";
import Card from "react-bootstrap/Card";
import {MAP_API_KEY} from "../../helpers/Constants";
import {Image} from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React from "react";


const EventMap = ({event, fixedHeight=""}) => {
  if ((event?.GPS_longitude != null && event?.GPS_longitude !== 0) || event?.locations != null) {
    let position = [event.GPS_latitude, event.GPS_longitude];
    const plainMarkerIcon = MapMarker(true);
    const customMarkers = () => {
      let items = [];
      for (let key in event?.locations) {
        let location = event?.locations[key];
        let locPosition = [location.GPSLat, location.GPSLon];
        if (position[0] === 0)
          position = locPosition;
        items.push(<Marker position={locPosition} icon={plainMarkerIcon}>
          <Tooltip className="tapis-75">{location.Letter} – {location.NameCZ}</Tooltip>
        </Marker>)
      }
      return items;
    }

    const markerIcon = MapMarker();
    const markers = customMarkers();
    const map_url = "https://mapy.cz/turisticka?source=coor&id="+position[1]+"%2C"+position[0]+"&x="+position[1]+"&y="+position[0]+"&z=16";
    const container_height = fixedHeight !== "" ? fixedHeight : (isMobileDevice() ? '220px': '400px');
    return (
      <Card className="mb-3">
        <Card.Body className="tapis-85 shrink-letters">
          <MapContainer center={position} zoom={13} style={{height: container_height, width: '100%', zIndex: 1}}>
            <TileLayer
              url={`https://api.mapy.cz/v1/maptiles/outdoor/256/{z}/{x}/{y}?apikey=${MAP_API_KEY}`}
              attribution={"<a class='tapis-85 shrink-letters' href='https://api.mapy.cz/copyright' target='_blank'>&copy; Seznam.cz a.s. a další</a>"}
              minZoom={0}
              maxZoom={19}
            />
            {event.GPS_latitude !== 0 && <Marker position={position} icon={markerIcon}>
              <Tooltip className="tapis-75">{event?.title}</Tooltip>
            </Marker>}
            {markers}
          </MapContainer>
          <a href={map_url} target='_blank' rel='noreferrer'><Image style={{zIndex: 2, position: "absolute", inset: "auto auto 40px 20px"}} src='https://api.mapy.cz/img/api/logo.svg' width='85px' height='30px'/></a>
          <Row>
            <Col xs="auto" className="keep-all">N: {position[0]}, E: {position[1]}</Col>
          </Row>
        </Card.Body>
      </Card>
    );
  }
}

export default EventMap;