import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import React from "react";
import Button from "react-bootstrap/Button";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleChevronLeft} from "@fortawesome/free-solid-svg-icons";


const PageHeading = ({heading, label, link, secondLabel, secondLink, id}) => {
  const BackButton = ({id, text, link}) => {
    const buttonText = text !== undefined ? text : "Stránka akce";
    const buttonLink = link !== undefined ? link : "/akce/"+id;
    return <Link to={buttonLink}><Button className="mb-2 ms-3" variant="secondary"><FontAwesomeIcon icon={faCircleChevronLeft} /> {buttonText}</Button></Link>;
  }

  const SecondaryLink = () => {
    if (secondLabel === undefined)
      return <></>;
    return <Link to={secondLink}><Button variant="link" className="mb-2 ms-3">{secondLabel}</Button></Link>;
  }

  return (<>
    <Row className="mb-2">
      <Col xl={8} lg={12}>
        <h1>{heading}</h1>
      </Col>
      <Col className="text-end">
        <SecondaryLink />
        <BackButton link={link} text={label} id={id}/>
      </Col>
    </Row>
  </>)
}


export default PageHeading;