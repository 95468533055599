import React, {useEffect, useState} from "react";
import {changeGrantLimit, getBillingYears, postNewBillingYear, putBillingYears, stepPeriod} from "../../dao";
import {getNextDate, handleErrorLoading, handleErrorSubmit, setBrowserTabText} from "../../helpers/Functions";
import ErrorAlertFullscreen from "../../components/overlays/ErrorAlertFullscreen";
import PageContainer from "../../layout/PageContainer";
import Loader from "../../components/overlays/Loader";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import {isMobileDevice} from "../../helpers/Device";
import Button from "react-bootstrap/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faArrowRight, faPencil, faQuestionCircle} from "@fortawesome/free-solid-svg-icons";
import Table from "react-bootstrap/Table";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import PageHeading from "../../layout/PageHeading";
import ErrorAlert from "../../components/overlays/ErrorAlert";
import SuccessAlert from "../../components/overlays/SuccessAlert";
import FormField from "../../components/form/FormField";
import LoadingCard from "../../components/overlays/LoadingCard";


const ClubBillingYears = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [errorRemote, setErrorRemote] = useState("");
  const [success, setSuccess] = useState("");
  const [data, setData] = useState({});
  const [periodStep, setPeriodStep] = useState(0);
  const [limitEditYear, setLimitEditYear] = useState(null);
  const [limitOldest, setLimitOldest] = useState(0);
  const [limitYoungest, setLimitYoungest] = useState(0);
  const [isProcessing, setIsProcessing] = useState(false);
  const [periodEditYear, setPeriodEditYear] = useState(null);
  const [periods, setPeriods] = useState([]);

  useEffect(() => {
    loadData();
  }, []);

  function loadData() {
    setLoading(true);
    getBillingYears()
      .then((response) => {
        if (!response.ok) {
          setErrorRemote(handleErrorLoading(response));
          return []
        }
        return response.json()
      })
      .then((response) => {
        setData(response);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }



  const Period = (period) => {
    return (<>
      <Row key={period.index}>
        <Col sm>
          <b>P{period.index}</b>
        </Col>
        <Col sm>
          <Form.Group className="mb-3" controlId={"dateFrom" + period.index} key={"dateFrom" + period.index}>
            <Form.Control type="date" value={period.date_from} disabled={true}/>
          </Form.Group>
        </Col>
        <Col sm>
          <Form.Group className="mb-3" controlId={"dateTo" + period.index} key={"dateTo" + period.index}>
            <Form.Control type="date" value={period.date_to} disabled={period.index === periods.length} onChange={(e) => {
              changePeriodEnd(e.target.value, period.index)
            }}/>
          </Form.Group>
        </Col>
      </Row>
    </>)
  }

  const PeriodsForm = () => {
    const res = [];
    for (let p in periods)
      res.push(Period(periods[p]));
    return res;
  }

  if (loading)
    return <Loader loading={loading} />
  if (isProcessing)
    return <LoadingCard text={"Probíhá přepočet..."} />
  if (errorRemote !== "")
    return <ErrorAlertFullscreen error={errorRemote}/>

  setBrowserTabText('Účetní období');
  return (
    <PageContainer>
      <PageHeading heading={"Účetní roky a období"} link={"/ucetnictvi/"} label={"Účetnictví"} secondLink={"/ucetnictvi/detail"} secondLabel={"Detail období"}/>
      <MainControlPanel/>
      <YearSection/>

      <Modal show={periodStep !== 0} onHide={()=>setPeriodStep(0)}>
        <Modal.Header closeButton>
          <FontAwesomeIcon icon={faQuestionCircle} size='3x' bounce /> &nbsp;
          <Modal.Title>Skutečně změnit období zúčtované uživatelům na {periodStep === 1 ? "následující" : "předchozí"}?</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="secondary" onClick={()=>setPeriodStep(0)}>
            Zavřít
          </Button>
          <Button variant="primary" onClick={()=>step(periodStep)}>
            Potvrdit
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={limitEditYear != null} onHide={()=> setLimitEditYear(null)}>
        <Modal.Header closeButton>
          <Modal.Title>Limit dotace pro rok {limitEditYear}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormField label={"Nejstarší ročník narození započítatelný do dotace"} type={"number"} value={limitOldest} setValue={setLimitOldest} controlId={"limitOldest"} />
          <FormField label={"Nejmladší ročník narození započítatelný do dotace"} type={"number"} value={limitYoungest} setValue={setLimitYoungest} controlId={"limitYoungest"} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={()=> setLimitEditYear(null)}>Zavřít</Button>
          <Button variant="primary" onClick={changeLimit}>Změnit</Button>
        </Modal.Footer>
      </Modal>
      <Modal show={periodEditYear != null} onHide={()=> setPeriodEditYear(null)}>
        <Modal.Header closeButton>
          <Modal.Title>Období roku {periodEditYear}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {PeriodsForm()}
          <Row>
            <Col className="text-end">
              <Button variant="outline-primary" onClick={addPeriod}>+ Období</Button>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={()=> setPeriodEditYear(null)}>
            Zavřít
          </Button>
          <Button variant="primary" onClick={savePeriods}>
            Uložit
          </Button>
        </Modal.Footer>
      </Modal>
      <ErrorAlert error={error} handleClose={() => setError("")}/>
      <SuccessAlert title={success} handleClose={() => setSuccess("")}/>
    </PageContainer>
  );

  function step(step) {
    setIsProcessing(true);
    stepPeriod(step)
      .then((response) => {
        if (response.ok) {
          setSuccess("Období změněno");
          setPeriodStep(0);
          loadData();
        } else {
          setError(handleErrorSubmit(response, "Nezdařilo se změnit"));
        }
        setIsProcessing(false);
      })
  }
  function changeLimit() {
    setLoading(true);
    changeGrantLimit(limitEditYear, limitOldest, limitYoungest)
      .then(() => {
        setLimitEditYear(null)
      });
    loadData();
  }
  function addYear() {
    postNewBillingYear()
      .then(() => {loadData()})
  }
  function addPeriod(){
    const tempPeriods = [...periods];
    if (periods.length === 0) {
      tempPeriods.push({
        index: periods.length + 1,
        date_from: periodEditYear + "-01-01",
        date_to: periodEditYear + "-12-31",
      });
    } else {
      tempPeriods.push({
        index: periods.length + 1,
        date_from: periodEditYear + "-12-31",
        date_to: periodEditYear + "-12-31",
      });
    }
    setPeriods(tempPeriods);
  }
  function changePeriodEnd(newDate, periodNo) {
    if (newDate < periodEditYear + "-01-01" || newDate > periodEditYear + "-12-31")
      return;
    const idx = periodNo - 1;
    if (idx !== 0) {
      const prevPeriod = periods[idx - 1];
      if (newDate <= prevPeriod.date_to)
        return;
    }
    if (periodNo !== periods.length) {
      const nextPeriod = periods[idx + 1];
      if (newDate >= nextPeriod.date_to)
        return;
    }
    const tempPeriods = [...periods];
    tempPeriods[idx].date_to = newDate;
    tempPeriods[idx + 1].date_from = getNextDate(new Date(newDate));
    setPeriods(tempPeriods);
  }
  function savePeriods() {
    putBillingYears(periodEditYear, periods)
      .then((response) => {
        if (response.ok) {
          setSuccess("Upraveno");
          loadData();
        } else {
          setError(handleErrorSubmit(response, "Nezdařilo se upravit"));
        }
        setPeriodEditYear(null);
      })
  }

  function MainControlPanel() {
    return (
      <Card className="mb-3">
        <Card.Body>
          <Row>
            <Col sm>
              <Card.Title><Row><Col>Aktuální období:</Col><Col>{data?.period}</Col></Row></Card.Title>
            </Col>
            <Col sm>
              <Row className="mb-3"><Col className="align-center">Změnit systémem zúčtované
                období:</Col>
              </Row>
              {isMobileDevice() && <Col className="align-center">{data?.period}</Col>}
              <Row>
                <Col className="align-center"><Button className="mb-2" onClick={() => setPeriodStep(-1)} title="Předchozí"><FontAwesomeIcon
                  icon={faArrowLeft}/></Button></Col>
                {!isMobileDevice() && <Col className="align-center">{data?.period}</Col>}
                <Col className="align-center"><Button className="mb-2" onClick={() => setPeriodStep(1)} title="Následující"><FontAwesomeIcon
                  icon={faArrowRight}/></Button></Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    )
  }

  function YearSection() {
    function EventTable({data}) {
      const rows = [];
      for (const i in data) {
        const element = data[i];
        rows.push(
          <tr key={element.year}>
            <td>{element.year}</td>
            <td className="td-sep"></td>
            <td>{element.period_count}</td>
            <td title="Upravit období" onClick={() => {
              setPeriods(element.periods);
              setPeriodEditYear(element.year);
            }} className="td-w-icon pointing"><FontAwesomeIcon icon={faPencil} className="link-like"/></td>
            <td className="td-sep"></td>
            <td>[{element?.grant_limit_oldest}; {element?.grant_limit_youngest}]</td>
            <td title="Změnit limit dotace" onClick={() => {
              setLimitEditYear(element.year);
              setLimitOldest(element.grant_limit_oldest);
              setLimitYoungest(element.grant_limit_youngest);
            }} className="td-w-icon pointing"><FontAwesomeIcon icon={faPencil} className="link-like"/></td>
          </tr>);
      }

      if (rows.length > 0) {
        return (
          <Table striped bordered hover responsive>
            <thead>
            <tr>
              <th>Rok</th>
              <th className="td-sep"></th>
              <th title="Počet období v roce">Počet období</th>
              <th></th>
              <th className="td-sep"></th>
              <th title="Limity dotace">Limity</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            {rows}
            </tbody>
          </Table>
        );
      }
      return (<p><i>Nedefinovány žádné roky</i></p>);
    }

    return (<>
      <EventTable data={data?.years}/>
      <Row>
        <Col className="text-end">
          <Button variant="outline-primary" onClick={addYear}>+ Následující rok</Button>
        </Col>
      </Row>
    </>)
  }
};

export default ClubBillingYears;