import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const ErrorAlert = (props) => {
  return (
    <Modal show={props.error != null && props.error !== ""} onHide={props.handleClose}>
      <Modal.Header closeButton>
        <FontAwesomeIcon icon={faTriangleExclamation} size='4x' bounce style={{ color: "red" }} />
        <Modal.Title>{props.error}</Modal.Title>
      </Modal.Header>
      <Modal.Footer>
        <Button variant="primary" onClick={props.handleClose}>
          Zavřít
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ErrorAlert;