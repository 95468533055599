import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendar} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import {parseDate} from "../../helpers/Functions";


const EventDate = ({event}) => {
  const dateTo = event?.date_to == null ? "" : " – " + parseDate(event.date_to);
  return <><FontAwesomeIcon icon={faCalendar}/> {parseDate(event?.date)}{dateTo}</>;
}

export default EventDate;