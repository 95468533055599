import {faCircleChevronLeft, faTriangleExclamation} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Card from 'react-bootstrap/Card';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import React from "react";

const ErrorAlertFullscreen = (props) => {
  if(props.error != null && props.error !== ""){
    return (
      <div className="loader-container" style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
        <Card>
          <Card.Body style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
            <FontAwesomeIcon icon={faTriangleExclamation} size='4x' bounce style={{color: "red"}}/>
            <h3>Ups! {props.error}</h3>
          </Card.Body>
          <Card.Footer>
            <Row>
              <Col className="text-end pe-1 ps-1">
                <Button onClick={() => {window.history.back()}} variant="secondary"><FontAwesomeIcon icon={faCircleChevronLeft} /> Zpět</Button>
              </Col>
            </Row>
          </Card.Footer>
        </Card>
      </div>
    );
  }
};

export default ErrorAlertFullscreen;