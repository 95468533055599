import Container from "react-bootstrap/Container";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTriangleExclamation} from "@fortawesome/free-solid-svg-icons";
import Alert from "react-bootstrap/Alert";
import PageNavAnonym from "./PageNavAnonym";
import PageFooterAnonym from "./PageFooterAnonym";

const PageContainerAnonym = ({ children, background, message="" }) => {
  const backgroundColorStyle = background !== undefined ? {background: background} : {}
  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <PageNavAnonym />

      <main style={{ flex: '1', marginTop: "70px" }}>
        <div style={backgroundColorStyle}>
          <Container className="pt-3">
            {(message && message !== "") && (<Alert variant="warning"><FontAwesomeIcon icon={faTriangleExclamation} /> {message}</Alert>)}
            {children}
          </Container>
        </div>
      </main>

      <PageFooterAnonym />
    </div>
  );
}

export default PageContainerAnonym;