import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import React from "react";

const FormCoordinates = ({GPSlat, GPSlong, setGPSlat, setGPSlong, setShowMap}) => {
  const coordinateRegex = /^[-+]?\d*\.?\d*$/;
  return (
    <Form.Group className="mb-3" controlId="gps">
      <Form.Label>Souřadnice:</Form.Label>
      <InputGroup>
        <Form.Control type="text" size="sm" title="North" value={GPSlat} onChange={(e) => {
          const inputValue = e.target.value.replace(',','.');
          const isValidLatitude = coordinateRegex.test(inputValue);

          if (isValidLatitude || inputValue === '')
            setGPSlat(inputValue);
          else
            e.target.value = GPSlat;
        }}/>
        <Form.Control type="text" size="sm" title="East" value={GPSlong} onChange={(e) => {
          const inputValue = e.target.value.replace(',','.');
          const isValidLongitude = coordinateRegex.test(inputValue);

          if (isValidLongitude || inputValue === '')
            setGPSlong(inputValue);
          else
            e.target.value = GPSlong;
        }}/>
        <Button style={{ width: '20%' }} onClick={() => setShowMap(true)}>Mapa</Button>
      </InputGroup>
    </Form.Group>
  )
}

export default FormCoordinates;