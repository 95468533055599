import { useState } from "react";

export default function useColormode(){
  const [colormode, setColormode] = useState("light");
  return {
    colormode,
    switchColormode : switchMode,
    initColorMode : initMode
  };

  function initMode(){
    if(colormode !== getMode()){
      setColormode(getMode());
    }
    document.documentElement.setAttribute('data-bs-theme', getMode());
  }
  
  function switchMode(){
    if(getMode()==="dark"){
      setMode("light");
    }else{
      setMode("dark");
    }
  }
  
  function setMode(mode){
    localStorage.setItem('colormode', mode);
    document.documentElement.setAttribute('data-bs-theme', mode);
    setColormode(mode);
  }
  
  function getMode(){
    if(localStorage.getItem('colormode') != null){
      return localStorage.getItem('colormode');
    }
    return 'light';
  }
}


