import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faRotate} from "@fortawesome/free-solid-svg-icons";
import React from "react";

const LoadingIcon = () => {
  return (
    <div style={{textAlign: "center", marginTop: "5%"}}>
      <FontAwesomeIcon icon={faRotate} size='3x' spin/>
    </div>
  )
}

export default LoadingIcon;