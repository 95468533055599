import Button from "react-bootstrap/Button";
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Alert from 'react-bootstrap/Alert';
import Card from 'react-bootstrap/Card';
import {postLogin, getCookie, setCsrfToken, requestPasswordReset, getServerInfo} from "../../dao";
import {useEffect, useState} from "react";
import PropTypes from 'prop-types';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import {faLock, faRepeat, faRightToBracket, faTriangleExclamation, faUser, faXmark} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LogoSvg from "../../components/visual/LogoSvg";
import {isMobileDevice} from "../../helpers/Device";
import ErrorAlert from "../../components/overlays/ErrorAlert";
import SuccessAlert from "../../components/overlays/SuccessAlert";

const Login = ({ setUserData }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState(0);
  const [showForgotten, setShowForgotten] = useState(false);
  const [forgottenError, setForgottenError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [serverInfo, setServerInfo] = useState({});

  useEffect(() => {
    getServerInfo()
      .then((response) => {
        if (response.ok)
          response.json().then((r) => setServerInfo(r))
        else
          setError(502);
      })
      .catch(() => setError(502))
  }, [])

  const handleSubmit = (e) => {
    e.preventDefault();
    getCookie()
      .then((response) => {
        postLogin(username, password, response.headers.get("X-Csrftoken"))
          .then((response)=> {
            if (response.ok) {
              setCsrfToken(response.headers.get("X-Csrftoken"));
              response.json().then((userData)=>{
                setUserData(userData);
              });
            } else {
              setError(response.status);
            }
          })
          .catch(() => setError(403));
      });
  }

  const sendNewPassword = () => {
    setProcessing(true);
    getCookie()
      .then((response) => {
        requestPasswordReset(username, response.headers.get("X-Csrftoken"))
          .then((res) => {
            if (res.ok) {
              setSuccess("Email odeslán!");
              setShowForgotten(false);
            } else
              setForgottenError("Nepodařilo se odeslat email");
            setProcessing(false);
          })
      })
  }

  const FailedLogin = () => {
    if(error !== 0){
      switch (error) {
        case 401:
          return (<Alert variant="danger"><FontAwesomeIcon icon={faXmark} /> Špatné uživatelské jméno nebo heslo</Alert>);
        case 403:
          return (<Alert variant="danger"><FontAwesomeIcon icon={faXmark} /> Přihlášení se nezdařilo</Alert>);
        default:
          return (<Alert variant="danger"><FontAwesomeIcon icon={faXmark} /> Server dočasně nedostupný</Alert>);
      }
    }
    return null;
  }

  const SystemMessage = () => {
    if (serverInfo?.message && serverInfo?.message !== "")
      return (<Alert variant="warning"><FontAwesomeIcon icon={faTriangleExclamation} /> {serverInfo.message}</Alert>);
    return <></>;
  }

  return (
    <Container fluid="sm">
      <div style={{height: 100}} />
      <Card className="loginCard">
        <Card.Body>
          <Row className="align-items-end mb-3">
            <Col><h1 className="mb-2">Přihlášení</h1></Col>
            <Col className="text-end"><LogoSvg zoom={isMobileDevice() ? 1.15 : 1.7}/></Col>
          </Row>
          <SystemMessage/>
          <FailedLogin/>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label><FontAwesomeIcon icon={faUser} /> Uživatelské jméno:</Form.Label>
              <Form.Control type="text" placeholder="Tretrak" value={username} onChange={(e) => setUsername(e.target.value)} />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label><FontAwesomeIcon icon={faLock} /> Heslo:</Form.Label>
              <Form.Control type="password" placeholder="Heslo" value={password} onChange={(e) => setPassword(e.target.value)} />
            </Form.Group>
            <Row className="mt-3">
              <Col>
                <a className="link" onClick={()=>setShowForgotten(true)}><FontAwesomeIcon icon={faRepeat} /> Zapomenuté heslo</a>
              </Col>
              <Col className="text-end">
                <Button variant="primary" type="submit" disabled={error === 502}><FontAwesomeIcon icon={faRightToBracket} /> Přihlásit se</Button>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>

      <Modal show={showForgotten} onHide={()=>setShowForgotten(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Zapomenuté heslo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Na primární email k Tapis účtu bude odeslán odkaz pro reset hesla.</p>
          <Form className="mb-3">
            <Form.Group>
              <Form.Label><FontAwesomeIcon icon={faUser} /> Uživatelské jméno / primární email:</Form.Label>
              <Form.Control type="text" placeholder="Tretrak" value={username} onChange={(e) => setUsername(e.target.value)} />
            </Form.Group>
          </Form>
          <p><i>Pokud je pro identifikaci účtu vybrán email, pak tento email musí být jako primární nastaven <b style={{color: "red"}}>pouze u jednoho účtu</b>!</i></p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={()=>setShowForgotten(false)}>Zavřít</Button>
          <Button variant="primary" onClick={sendNewPassword} disabled={processing}>Odeslat email</Button>
        </Modal.Footer>
      </Modal>
      <ErrorAlert error={forgottenError} handleClose={()=>setForgottenError("")}/>
      <SuccessAlert title={success} handleClose={()=>setSuccess("")}/>
    </Container>);
}; 
  
Login.propTypes = {
  setUserData: PropTypes.func.isRequired
};
export default Login;
