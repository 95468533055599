import Button from "react-bootstrap/Button";
import Form from 'react-bootstrap/Form';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
  editEvent,
  getQuestionsEdit,
  postPutQuestion,
  putMoveQuestion,
  deleteQuestion,
} from "../../dao";
import React, { useState, useEffect } from 'react';
import {
  faArrowsUpDown,
  faCircleDot, faFont,
  faPencil,
  faQuestionCircle,
  faSquareCheck,
  faTrash
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ErrorAlert from "../../components/overlays/ErrorAlert";
import SuccessAlert from "../../components/overlays/SuccessAlert";
import {handleErrorLoading, handleErrorSubmit, IndicesArray} from "../../helpers/Functions";
import FormField from "../../components/form/FormField";
import FormSelectDict from "../../components/form/FormSelectDict";
import {
  PunchingOptions, QuestionTypeOptions,
  TernaryOptionsTransport
} from "../../helpers/Constants";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import {SelectOptionsArray, SelectOptionsDict} from "../../components/form/SelectOptions";
import ErrorAlertFullscreen from "../../components/overlays/ErrorAlertFullscreen";
import useUserData from "../../helpers/Auth";
import {Link} from "react-router-dom";
import FormCoordinates from "../../components/form/FormCoordinates";
import MapPositionSelect from "../../components/parts/MapPositionSelect";
import FormCheckbox from "../../components/form/FormCheckbox";

const EditCamp = (props) => {
  const { userData, setUserData } = useUserData();
  const [questions, setQuestions] = useState({});
  const [error, setError] = useState("");
  const [errorRemote, setErrorRemote] = useState("");
  const [success, setSuccess] = useState("");
  const [showMap, setShowMap] = useState(false);

  const [title, setTitle] = useState(props.event.title);
  const [date, setDate] = useState(props.event.date);
  const [dateTo, setDateTo] = useState(props.event.date_to);
  const [place, setPlace] = useState(props.event.place);

  const [info, setInfo] = useState(props.event.info);
  const [eventInfo, setEventInfo] = useState(props.event.event_info);
  const [warning, setWarning] = useState(props.event.event_warning);

  const [termFirst, setTermFirst] = useState(props.event.term_first);
  const [allowLate, setAllowLate] = useState(props.event.allow_late);
  const [allowAnonymous, setAllowAnonymous] = useState(props.event?.allow_anonym);
  const [visible, setVisible] = useState(props.event.visible);
  const [punching, setPunching] = useState(props.event.punching);

  const [GPSlong, setGPSlong] = useState(props.event?.GPS_longitude !== undefined ? props.event?.GPS_longitude : '');
  const [GPSlat, setGPSlat] = useState(props.event?.GPS_latitude !== undefined ? props.event?.GPS_latitude : '');
  const [transport, setTransport] = useState(props.event.transport);
  const [transportInfo, setTransportInfo] = useState(props.event.transport_info);
  const [highlighted, setHighlighted] = useState(props.event.highlighted);

  const [questionToEditID, setQuestionToEditID] = useState(null);
  const [questionToMove, setQuestionToMove] = useState(null);
  const [questionToRemove, setQuestionToRemove] = useState(null);
  const [questionIndex, setQuestionIndex] = useState(0);

  const [questionTitle, setQuestionTitle] = useState("");
  const [questionNote, setQuestionNote] = useState("");
  const [questionRequired, setQuestionRequired] = useState(false);
  const [questionOtherChoice, setQuestionOtherChoice] = useState(false);
  const [questionChoices, setQuestionChoices] = useState("");
  const [questionType, setQuestionType] = useState(0);

  useEffect(() => {
    loadQuestions();
  }, []);

  function loadQuestions(){
    getQuestionsEdit(props.event.id)
      .then((response) => {
        if (response.ok)
          return response.json();
        setErrorRemote(handleErrorLoading(response));
      })
      .then((response) => {
        setQuestions(response);
      });
  }

  let handleSubmit = async (e) => {
    e.preventDefault();
    const formData = {
      "title": title,
      "date": date,
      "date_to": dateTo,
      "place" : place,
      "info": info,
      "event_info": eventInfo,
      "event_warning": warning,
      "punching": punching,
      "transport": transport,
      "transport_info": transportInfo,
      "visible": visible,
      "term_first": termFirst,
      "allow_late" : allowLate,
      "allow_anonym": allowAnonymous,
      "highlighted": highlighted,
      "GPS_longitude": GPSlong,
      "GPS_latitude": GPSlat
    }
    const res = await editEvent(props.event.id, formData);
    if(res.ok)
      setSuccess("Akce upravena");
    else
      setError(handleErrorSubmit(res, "Nepodařilo se upravit"));
  };

  if (errorRemote !== "")
    return <ErrorAlertFullscreen error={errorRemote} />

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col lg>
            <FormField label={"Název"} value={title} setValue={setTitle} valueLimit={512} controlId={"title"} />
          </Col>
          <Col xs lg={2}>
            <FormField label={"Datum od"} type={"date"} value={date} setValue={setDate} controlId={"date"} />
          </Col>
          <Col xs lg={2}>
            <FormField label={"Datum do"} type={"date"} value={dateTo} setValue={setDateTo} controlId={"dateTo"} />
          </Col>
          <Col lg>
            <FormField label={"Termín přihlášek"} type={"datetime-local"} value={termFirst} setValue={setTermFirst} controlId={"termFirst"} />
          </Col>
        </Row>
        <Row>
          <Col lg>
            <FormField label={"Místo"} value={place} setValue={setPlace} valueLimit={512} controlId={"place"} />
          </Col>
          <Col lg>
            <FormCoordinates GPSlat={GPSlat} GPSlong={GPSlong} setGPSlat={setGPSlat} setGPSlong={setGPSlong} setShowMap={setShowMap}/>
          </Col>
          <Col lg>
            <Form.Group className="mb-3" controlId="highlighted">
              <Form.Label title="Otázka s tímto číslem bude zobrazena v sekci Administrace akce">Admin - vybraná otázka:</Form.Label>
              <Form.Select onChange={(e) => setHighlighted(Number(e.target.value))} value={highlighted}>
                <SelectOptionsArray options={IndicesArray(questions.count)}/>
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md>
            <FormCheckbox label={"Viditelná pro běžné uživatele"} value={visible} setValue={setVisible} controlId={"visible"}/>
          </Col>
          <Col md>
            <FormCheckbox label={"Anonymní přihlášky možné"} value={allowAnonymous} setValue={setAllowAnonymous} controlId={"allowAnonymous"}/>
          </Col>
          <Col md>
            {userData.is_superuser && <FormCheckbox label={"Přihlášky trenérů po termínu"} value={allowLate} setValue={setAllowLate} controlId={"allowLate"}/>}
          </Col>
          <hr/>
        </Row>
        <Row>
          <Col md>
            <FormSelectDict label={"Ražení"} value={punching} setValue={setPunching} options={PunchingOptions} controlId={"punching"} />
          </Col>
          <Col md>
            <FormSelectDict label={"Klubová doprava"} value={transport} setValue={setTransport} options={TernaryOptionsTransport} controlId={"transport"} />
          </Col>
          <Col lg>
            <FormField label={"Info o dopravě"} value={transportInfo} setValue={setTransportInfo} valueLimit={128} controlId={"transportInfo"} />
          </Col>
        </Row>
        <Row>
          <Col lg>
            <FormField label={"Informace"} value={info} setValue={setInfo} valueLimit={2048} controlId={"info"} />
          </Col>
          <Col lg>
            <FormField label={"Modré info"} value={eventInfo} setValue={setEventInfo} valueLimit={2048} controlId={"eventInfo"} />
          </Col>
          <Col lg>
            <FormField label={"Varování"} value={warning} setValue={setWarning} valueLimit={2048} controlId={"eventWarning"} />
          </Col>
        </Row>
        <Row>
          <Col className="text-end mb-3">
            <Button variant="primary" type="submit">Upravit informace</Button>
          </Col>
          <hr/>
        </Row>
      </Form>
      <Row>
        <Col xs="auto" className="mb-3">
          <Button variant="primary" onClick={() => {
            setQuestionToEditID(-1);
            setQuestionTitle("");
            setQuestionNote("");
            setQuestionRequired(true);
            setQuestionType(0);
            setQuestionOtherChoice(false);
            setQuestionChoices("");
          }}>+ Přidat otázku</Button>
        </Col>
        <Col xs="auto" className="mb-3">
          <Link to={"/akce/"+props.event.id+"/kopirovat"}><Button variant="outline-primary">Zkopírovat formulář</Button></Link>
        </Col>
      </Row>
      <QuestionTable />
      <Modal show={questionToRemove != null} onHide={() => setQuestionToRemove(null)}>
        <Modal.Header closeButton>
          <FontAwesomeIcon icon={faQuestionCircle} size='3x' bounce/> &nbsp;
          <Modal.Title>Smazat otázku?</Modal.Title>
        </Modal.Header>
        <Modal.Body><b>#{questionToRemove?.index}</b> {questionToRemove?.title}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setQuestionToRemove(null)}>Zavřít</Button>
          <Button variant="primary" onClick={() => removeQuestion(questionToRemove.id)}>Smazat</Button>
        </Modal.Footer>
      </Modal>
      <Modal show={questionToMove != null} onHide={() => setQuestionToMove(null)}>
        <Modal.Header closeButton>
          <FontAwesomeIcon icon={faQuestionCircle} size='3x' bounce/> &nbsp;
          <Modal.Title>Přesunout otázku?</Modal.Title>
        </Modal.Header>
        <Modal.Body><b>#{questionToMove?.index}</b> {questionToMove?.title}
          <hr/>
          <Form.Group>
            <Form.Label>Nová pozice:</Form.Label>
            <Form.Select id="index" size="sm" onChange={(e) => setQuestionIndex(Number(e.target.value))} value={questionIndex}>
              <SelectOptionsArray options={IndicesArray(questions.count)}/>
            </Form.Select>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setQuestionToMove(null)}>Zavřít</Button>
          <Button variant="primary" onClick={() => moveQuestion(questionToMove.id)}>Přesunout</Button>
        </Modal.Footer>
      </Modal>
      <Modal show={questionToEditID != null} onHide={() => setQuestionToEditID(null)}>
        <Modal.Header closeButton>
          <Modal.Title>{questionToEditID === -1 ? "Přidat" : "Editovat"} otázku:</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <FormField label={"Otázka"} value={questionTitle} setValue={setQuestionTitle} valueLimit={256} controlId={"qTitle"} />
            <FormField label={"Poznámka k otázce"} value={questionNote} setValue={setQuestionNote} valueLimit={256} controlId={"qNote"} />
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="qRequired">
                  <Form.Check type="checkbox" label="Povinná" defaultChecked={questionRequired}
                              onChange={(e) => {setQuestionRequired(e.target.checked)}}/>
                </Form.Group>
                {questionType > 0 && <Form.Group className="mb-3" controlId="qOther">
                  <Form.Check type="checkbox" label="Odpověď Jiné" defaultChecked={questionOtherChoice}
                              onChange={(e) => {setQuestionOtherChoice(e.target.checked)}}/>
                </Form.Group>}
              </Col>
              <Col><Form.Group className="mb-3" controlId="qType">
                <Form.Label>Typ:</Form.Label>
                <Form.Select
                  value={questionType} onChange={(e) => setQuestionType(Number(e.target.value))}>
                  <SelectOptionsDict options={QuestionTypeOptions} />
                </Form.Select>
              </Form.Group></Col>
            </Row>
            {questionType > 0 && <FormField label={"Možnosti oddělené středníkem ; (přejmenování Old>New)"} value={questionChoices} setValue={setQuestionChoices} valueLimit={1024} controlId={"qChoices"}/>}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setQuestionToEditID(null)}>Zavřít</Button>
          <Button variant="primary" onClick={() => addEditQuestion(questionToEditID)}>{questionToEditID === -1 ? "Přidat" : "Změnit"}</Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showMap} onHide={() => setShowMap(false)}>
        <Modal.Header closeButton>
          <Modal.Body>Vyber místo kliknutím do mapy</Modal.Body>
        </Modal.Header>
        <MapPositionSelect GPSlat={GPSlat} GPSlong={GPSlong} setGPSlat={setGPSlat} setGPSlong={setGPSlong} setShowMap={setShowMap}/>
      </Modal>
      <ErrorAlert error={error} handleClose={() => setError("")}/>
      <SuccessAlert title={success} handleClose={() => setSuccess("")}/>
    </>
  );

  function addEditQuestion(id) {
    const method = id === -1 ? "POST" : "PUT";
    const resource = id === -1 ? "event=" + props.event.id : "id=" + id;
    postPutQuestion(method, resource, questionTitle, questionNote, questionRequired, questionType === 2, questionOtherChoice, questionChoices)
      .then((res) => {
        if (res.ok) {
          setSuccess(id === -1 ? "Přidáno" : "Upraveno");
          loadQuestions();
        } else {
          setError(handleErrorSubmit(res, "Nezdařilo se " + (id === -1 ? "přidat" : "upravit")));
        }
      })
    setQuestionToEditID(null);
  }

  function moveQuestion(id){
    putMoveQuestion(id, questionIndex)
      .then((res) => {
        if (res.ok) {
          setSuccess("Přesunuto na #" + questionIndex);
          loadQuestions();
        } else {
          setError(handleErrorSubmit(res, "Nezdařilo se přesunout"));
        }
      })
    setQuestionToMove(null);
  }

  function removeQuestion(id){
    deleteQuestion(id)
      .then((res) => {
        if (res.ok) {
          setSuccess("Smazáno");
          loadQuestions();
        } else {
          setError(handleErrorSubmit(res, "Nezdařilo se smazat"));
        }
      })
    setQuestionToRemove(null);
  }

  function QuestionTable(){
    if(questions.data === undefined){
      return(<p><i>Žádné otázky</i></p>)
    }
    if(questions.data.length < 1){
      return(<p><i>Žádné otázky</i></p>)
    }
    const rows = [];
    const QuestionTypeMarker = (question) => {
      if (!question.closed_ended)
        return <FontAwesomeIcon icon={faFont} />
      if (!question.multichoice)
        return <FontAwesomeIcon icon={faCircleDot} />
      else
        return <FontAwesomeIcon icon={faSquareCheck} />
    }
    questions.data.forEach((question)=>{
      rows.push(<tr key={question.id}>
        <td>{question.index}</td>
        <td>{question.title}{question.required && <b style={{color: "red"}}>*</b>}<br/>
          {question.closed_ended ? <i>{question.choices.data}</i> : <> </>}</td>
        <td>{QuestionTypeMarker(question)} {question.closed_ended && <>({question.choices.count}{question.other_choice && "+1"})</>}</td>
        <td className="text-center">
          <FontAwesomeIcon icon={faArrowsUpDown} onClick={() => {
            setQuestionToMove(question);
            setQuestionIndex(question.index);
          }} className="link-like"/>
        </td>
        <td className="text-center">
          <FontAwesomeIcon icon={faPencil} onClick={() => {
            setQuestionToEditID(question.id);
            setQuestionTitle(question.title);
            setQuestionNote(question.note);
            setQuestionRequired(question.required);
            setQuestionType((question.closed_ended ? 1 : 0) + (question.multichoice ? 1 : 0));
            setQuestionOtherChoice(question.other_choice);
            setQuestionChoices(question.choices.data);
          }} className="link-like"/>
        </td>
        <td className="text-center">
          <FontAwesomeIcon icon={faTrash} onClick={() => setQuestionToRemove(question)} className="link-like"/>
        </td>
      </tr>);
    });

    return (
      <Table striped hover responsive>
        <thead>
        <tr>
          <th>#</th>
          <th>Otázka</th>
          <th>Typ</th>
          <th></th>
          <th></th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        {rows}
        </tbody>
      </Table>
    );
  }
};

export default EditCamp;