import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import React from "react";


const ModalContentGroups = () => {
  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Nápověda</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Modal.Title>Skupiny</Modal.Title>
        <Table bordered>
          <tbody>
          <tr>
            <td>--</td>
            <td>hodnota pokud nemá být přiřazena žádná ze skupin</td>
          </tr>
          <tr>
            <td>DO</td>
            <td>Dorost</td>
          </tr>
          <tr>
            <td>SZ</td>
            <td>Starší žáci</td>
          </tr>
          <tr>
            <td>MZ</td>
            <td>Mladší žáci</td>
          </tr>
          <tr>
            <td>DE</td>
            <td>Děti</td>
          </tr>
          <tr>
            <td>AD</td>
            <td>Aktivní dospělí</td>
          </tr>
          <tr>
            <td>PD</td>
            <td>Pasivní dospělí / rodiče</td>
          </tr>
          <tr>
            <td>VE</td>
            <td>Veteráni</td>
          </tr>
          <tr>
            <td>NN</td>
            <td>nezvolena</td>
          </tr>
          <tr>
            <td>UN</td>
            <td>universal; člen všech skupin (z hlediska whitelistů jsou členy všech skupin všichni s rolí T/S)</td>
          </tr>
          </tbody>
        </Table>
      </Modal.Body>
    </>
  )
}

export default ModalContentGroups;