import Table from "react-bootstrap/Table";
import React, { useState, useEffect } from 'react'
import {getClubRanking} from "../../dao";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faAngleDown, faAngleUp} from '@fortawesome/free-solid-svg-icons';
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Loader from "../../components/overlays/Loader";
import { Link } from "react-router-dom";
import {handleErrorLoading, parseDate, parseDateShortYear, setBrowserTabText} from "../../helpers/Functions";
import PageContainer from "../../layout/PageContainer";
import {MobileWidthLimit, ORIS_URL} from "../../helpers/Constants";
import ErrorAlertFullscreen from "../../components/overlays/ErrorAlertFullscreen";

const ClubRank = () => {
  const [men, setMen] = useState([]);
  const [women, setWomen] = useState([]);
  const [date, setDate] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errorRemote, setErrorRemote] = useState("");

  const [isMobile, setIsMobile] = useState(window.innerWidth <= MobileWidthLimit);

  useEffect(() => {
    const handleOrientationChange = () => {setIsMobile(window.innerWidth <= MobileWidthLimit)};
    window.addEventListener('resize', handleOrientationChange);
    return () => {window.removeEventListener('resize', handleOrientationChange)};
  }, []);

  useEffect(() => {
    setLoading(true);
    getClubRanking()
      .then((response) => {
        if (!response.ok) {
          setErrorRemote(handleErrorLoading(response));
          return []
        }
        return response.json()
      })
      .then((response) => {
        setMen(response?.men);
        setWomen(response?.women);
        setDate(response?.ranking_to);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  if (loading)
    return <Loader />;
  if (errorRemote !== "")
    return <ErrorAlertFullscreen error={errorRemote} />;

  setBrowserTabText('Klubový ranking');
  return (
    <PageContainer>
      <Row className="align-items-baseline">
        <Col md><h1>Ranking členů</h1></Col>
        <Col className="text-end"><Link to={ORIS_URL + "Ranking"}>Ranking ORIS</Link></Col>
        <Col className="text-end" xs="auto"><p>Stav k datu: {parseDate(date)}</p></Col>
      </Row>
      <h3>Ženy</h3>
      <RankTable members={women}/>
      <h3>Muži</h3>
      <RankTable members={men}/>
    </PageContainer>
  );

  function RankTable(props){
    const TableContent = (props) =>{
      function RankEvents(props){
        const events = [];
        let idx = 0;
        props.data.forEach(element => {
          idx++;
          const style = idx > 8 ? {fontStyle: "italic"} : {};
          events.push(
            <Row key={element.id} style={style} className="m-0">
              <Col>{parseDateShortYear(element.date)}</Col>
              <Col><Link to={"/akce/"+element.id}>{isMobile ? element.discipline : element.organiser}</Link></Col>
              {isMobile ? (<></>) : <><Col>{element.discipline}</Col><Col>{element.place}</Col></> }
              <Col><Link target={"_blank"} to={ORIS_URL + "Vysledky?id=" + element.oris_id + "&class=" + element.class_id}>{element.class_name}</Link></Col>
              <Col>{element.ranking}</Col>
            </Row>);
        });
        return (events);
      }
      const TableRow = ({element}) =>{
        const [showEvents, setShowEvents] = useState(false);
        function changeState(){
          setShowEvents(current => !current);
        }
        return(
          <>
            <tr>
              <td>{element.place}</td>
              {isMobile ?
                (<td><Link to={"/ucet/"+element.id+"/zavody"} className="hide-link">{element.full_name}</Link><br/><i>{element.reg_number}</i></td>) :
                (<><td>{element.reg_number}</td><td><Link to={"/ucet/"+element.id+"/zavody"} className="hide-link">{element.full_name}</Link></td></>)
              }
              <td>{element.points}</td>
              <td>{element.coef}</td>
              <td onClick={changeState} className={isMobile ? "pointing align-center" : "pointing td-w-icon"}><FontAwesomeIcon icon={showEvents ? faAngleUp : faAngleDown}/></td>
            </tr>
            {showEvents ?
              (<tr><td colSpan="6" className="p-0 pt-1 pb-1"><RankEvents data={element.events}/></td></tr>)
              : (<></>)
            }
          </>
        )
      }
      const rows = [];
      props.members.forEach(element => {
        rows.push(<TableRow element={element} key={element.reg_number}/>);
      });
      return(rows);
    }

    return (<>
      <Table striped bordered responsive>
        <thead>
        <tr>
          <th>#</th>
          {isMobile ? <th>Osoba</th> : (<><th>Registrace</th><th>Jméno</th></>)}
          <th>Body</th>
          <th>Koef.</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <TableContent members={props.members}/>
        </tbody>
      </Table>
    </>);
  }
};


export default ClubRank;