import { Link } from "react-router-dom";
import { fa1, fa2, fa3, faK, faP, faS, faV } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {parseDateTime, parseDateWithoutYear} from "../../helpers/Functions";
import React from "react";
import {HomeClub, ORIS_URL} from "../../helpers/Constants";

export default function TermIndicator(props) {
  const term = props.event.term;
  // const withDate = props.date !== undefined;  TODO remove if not necessary
  const withDate = true;
  const i_style = {fontSize: "0.7rem", textAlign: "justify"};
  switch (term) {
    case 0:
      return (<><FontAwesomeIcon icon={faP} style={{color: "DeepSkyBlue"}} title={parseDateTime(props.event.term_preliminary)}/> {withDate &&
        <i style={i_style}>{parseDateWithoutYear(props.event.term_preliminary)}</i>}</>);
    case 1:
      let termFirst = (props.event.term_manual != null ? props.event.term_manual : props.event.term_first);
      const termIcon = (props.event.term_manual != null ? faK : fa1);
      return (<><FontAwesomeIcon icon={termIcon} style={{color: "LimeGreen"}} title={parseDateTime(termFirst)}/> {withDate &&
        <i style={i_style}>{parseDateWithoutYear(termFirst)}</i>}</>);
    case 2:
      return (<><FontAwesomeIcon icon={fa2} style={{color: "Orange"}} title={parseDateTime(props.event.term_second)}/> {withDate &&
        <i style={i_style}>{parseDateWithoutYear(props.event.term_second)}</i>}</>);
    case 3:
      return (<><FontAwesomeIcon icon={fa3} style={{color: "Crimson"}} title={parseDateTime(props.event.term_third)}/> {withDate &&
        <i style={i_style}>{parseDateWithoutYear(props.event.term_third)}</i>}</>);
    default:
      if (props.event.results && props.event.club_entries > 0)
        return (<Link to={ORIS_URL + (props.event?.is_relay ? "VysledkyStafet?id=" : "Vysledky?id=") + props.event.oris_id + "&club=" + HomeClub}><FontAwesomeIcon icon={faV} style={{color: "DarkViolet"}}/></Link>);
      if (props.event.startlists && props.event.club_entries > 0)
        return (<Link to={"/akce/" + props.event.id + "/klub"}><FontAwesomeIcon icon={faS} style={{color: "DeepPink"}}/></Link>);
  }
}
