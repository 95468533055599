import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PageContainer from "../../layout/PageContainer";
import { getEasterPair } from "../../dao";
import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

const Easter = () => {
  const { eventId } = useParams();
  const [name, setName] = useState("ne");
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(true);
    getEasterPair(eventId)
     .then((response) => response.json())
     .then((response) => {
        var matches = response.girl.full_name+" a "+response.boy.full_name;
        if(response.third!=null){
          matches = matches +" a "+ response.third;
        }
        setName(matches);
      })
      .catch((err) => console.log(err));
       setTimeout(() => {
          setLoading(false);
      }, 8000);
    }, 
  []);

    return (
        <PageContainer>
          <div align="center">
            {!loading && (<>
            <h1 style={{fontFamily:"Brush Script MT", fontSize:"5em"}}>It's a Match!</h1>
            <p>{name} poběží společně pomlázkáč!</p></>)
            }
            <FontAwesomeIcon style={{color:"red"}} icon={faHeart} size="6x" beat/>
          </div>
        </PageContainer>
    );
  };
  
  export default Easter;