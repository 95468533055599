import {getBilling} from "../../dao";
import Loader from "../../components/overlays/Loader";
import React, { useState, useEffect } from 'react';
import Table from "react-bootstrap/Table";
import {
  YearOptions, handleErrorLoading,
  parseDateBreakYear,
  parseDateWithoutYear, setBrowserTabText
} from "../../helpers/Functions";
import {Link, useParams} from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFeather, faFileInvoiceDollar, faHotel, faPersonRunning, faReceipt, faTents } from "@fortawesome/free-solid-svg-icons";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {isMobileDevice} from "../../helpers/Device";
import ErrorAlertFullscreen from "../../components/overlays/ErrorAlertFullscreen";
import PageContainer from "../../layout/PageContainer";
import useUserData from "../../helpers/Auth";
import FormSelectArray from "../../components/form/FormSelectArray";
import FormCheckbox from "../../components/form/FormCheckbox";

const Billing = () => {
  const { user } = useParams();
  const { userData, setUserData } = useUserData();
  const { year } = useParams();
  const [errorRemote, setErrorRemote] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [stable, setStable] = useState(true);
  const [yearShown, setYearShown] = useState(year == null ? (new Date()).getFullYear().toString() : year);

  useEffect(() => {
    setLoading(true);
    getBilling(user, yearShown, stable)
      .then((response) => {
        if (!response.ok) {
          setErrorRemote(handleErrorLoading(response));
          return [];
        }
        return response.json();
      })
      .then((response) => {
        setData(response);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, [user, yearShown, stable]);

  if (loading)
    return <Loader loading={loading} />
  if (errorRemote !== "")
    return <ErrorAlertFullscreen error={errorRemote} />

  setBrowserTabText(user == null ? 'Vyúčtování' : 'Vyúčtování | ' + data?.profile?.full_name);
  return (
    <PageContainer>
      {user == null ? <h1><FontAwesomeIcon icon={faReceipt} /> Detail vyúčtování</h1> : <h1><FontAwesomeIcon icon={faReceipt} /> Detail vyúčtování: {data?.profile?.full_name}</h1>}
      <br/>
      {userData?.is_accountant ?
        <Row className="align-items-center mb-3">
          <Col>
            <FormSelectArray label={"Rok"} inline={true} size="sm" value={yearShown} setValue={setYearShown} options={YearOptions()} className={"mb-2"} controlId={"yearShown"} />
          </Col>
          <Col md>
            <FormCheckbox type="switch" label={"Stabilizované vyúčtování"} value={stable} setValue={setStable} condensed={true} controlId={"stable"}/>
          </Col>
        </Row> :
        <Row className="mb-3">
          <Col md={3} sm={0}/>
          <Col>
            <FormSelectArray label={"Rok"} inline={true} size="sm" value={yearShown} setValue={setYearShown} options={YearOptions()} className={"mb-2"} controlId={"yearShown"} />
          </Col>
          <Col md={3} sm={0}/>
        </Row>
      }
      <Summary />
      <br/>
      <TableSection />
    </PageContainer>
  );

  function Summary() {
    const SummaryRow = ({element}) => {
      return (
        <tr>
          {!isMobileDevice() && <td>{element.period}.</td>}
          <td>{parseDateWithoutYear(element.date_from)} – {parseDateWithoutYear(element.date_to)}</td>
          <td>{element.sum_oris}</td>
          <td>{element.sum_dns_fines + element.sum_late_term}</td>
          <td>{element.sum_services}</td>
          <td>{element.sum_camps}</td>
          <td>{element.sum_club}</td>
          <td><b>{element.expenses}</b></td>
        </tr>
      )
    }

    const SummaryTable = () => {
      const rows = [];
      for (const i in data?.periods) {
        rows.push(<SummaryRow element={data?.periods[i]}/>);
      }
      return rows;
    }

    return (
      <Table bordered responsive>
        <thead>
        <tr>
          {!isMobileDevice() && <th></th>}
          <th>od – do</th>
          <th>{isMobileDevice() ? <FontAwesomeIcon icon={faPersonRunning} title="Startovné"/> : <>Startovné</>}</th>
          <th>{isMobileDevice() ? <FontAwesomeIcon icon={faFileInvoiceDollar}/> : <>Doplatky</>}</th>
          <th>{isMobileDevice() ? <FontAwesomeIcon icon={faHotel}/> : <>Služby</>}</th>
          <th>{isMobileDevice() ? <FontAwesomeIcon icon={faTents}/> : <>Soustředění</>}</th>
          <th>{isMobileDevice() ? <FontAwesomeIcon icon={faFeather}/> : <>Ost. klubové</>}</th>
          <th>{isMobileDevice() ? <>Σ</> : <>Celkem</>}</th>
        </tr>
        </thead>
        <tbody>
        <SummaryTable/>
        </tbody>
      </Table>
    )
  }

  function TableSection() {
    return (<>
      <FeeSection />
      <ServiceSection />
      <CampSection />
      <ClubReqSection />
    </>)

    function EventTable({data, noRecords=""}) {
      const rows = [];
      for (const i in data) {
        const element = data[i];
        rows.push(
          <tr key={element.id + "/" + element.fee}>
            <td className="td-w-15">{parseDateBreakYear(element.date)}</td>
            <td>{element.id != null ? <Link to={`/akce/${element.id}`} className="no-decoration">{element.title}</Link> : element.title}</td>
            <td className="td-w-15">{element.fee}</td>
          </tr>);
      }

      if (rows.length > 0) {
        return (
          <Table striped bordered hover responsive>
            <thead>
            <tr>
              <th className="td-w-15">Datum</th>
              <th>Název</th>
              <th className="td-w-15">Cena</th>
            </tr>
            </thead>
            <tbody>
            {rows}
            </tbody>
          </Table>
        );
      }
      return (<p><i>{noRecords}</i></p>);
    }

    function FeeSection() {
      return (<>
        <h3><FontAwesomeIcon icon={faPersonRunning}/> Startovné</h3>
        <EventTable data={data?.fees} noRecords="Žádná startovné" />
      </>)
    }

    function CampSection() {
      return (<>
        <h3><FontAwesomeIcon icon={faTents}/> Soustředění</h3>
        <EventTable data={data?.camps} noRecords="Žádná soustředění" />
      </>)
    }

    function ServiceSection() {
      return (<>
        <h3><FontAwesomeIcon icon={faHotel}/> Doplňkové služby</h3>
        <ServiceTable />
      </>)

      function ServiceTable() {
        const rows = [];
        for (const i in data?.orders) {
          const element = data?.orders[i];
          rows.push(
            <tr key={element.id}>
              <td>{parseDateBreakYear(element.date)}</td>
              <td>{element.service_title}</td>
              <td>{element.event_id != null ? <Link to={`/akce/${element.event_id}/sluzby`} className="no-decoration">{element.event_title}</Link> : element.event_title}</td>
              <td>{element.fee}</td>
            </tr>);
        }

        if (rows.length > 0) {
          return (
            <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th className="td-w-15">Datum</th>
                <th>Název</th>
                <th>Akce</th>
                <th className="td-w-15">Cena</th>
              </tr>
              </thead>
              <tbody>
              {rows}
              </tbody>
            </Table>
          );
        }
        return (<p><i>Žádné doplňkové služby</i></p>);
      }
    }


    function ClubReqSection() {
      return (<>
        <h3><FontAwesomeIcon icon={faFeather}/> Ostatní klubové</h3>
        <EventTable data={data?.club_reqs} noRecords="Žádné ostatní klubové pohledávky" />
      </>)
    }
  }
};

export default Billing;