import { useState } from "react";
import Container from "react-bootstrap/Container";
import { useEffect } from "react";
import { getClothes } from "../../dao";
import Card from 'react-bootstrap/Card';
import { useParams } from "react-router-dom";
import useUserData from "../../helpers/Auth";
import Loader from "../../components/overlays/Loader";
import Table from "react-bootstrap/Table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartPlus, faClipboardCheck } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";
import { Navigate } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

const Clothes = () => {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [serviceToBuy, setServiceToBuy] = useState("");
  const { userData, setUserData } = useUserData();

  useEffect(() => {
    setLoading(true);
    /*getServices(id)
      .then((response) => {
        if (!response.ok) {
          setErrorRemote(handleErrorRemote(response));
          return [];
        }
        return response.json()}) 
      .then((response) => {*/
        setData({clothes:[{
            id:1,
            title:"Bundička",
            img:"https://www.alpinepro.cz/data/cache/thumb_532-760-12/products/1042301/1689862665/mjcb628602pb-1689862665.jpg",
            price:3000
        },
        {
            id:2,
            title:"Dres",
            img:"https://d2j6dbq0eux0bg.cloudfront.net/images/17420198/1177073919.jpg",
            price:1500
        },
        {
            id:1,
            title:"Bundička",
            img:"https://www.alpinepro.cz/data/cache/thumb_532-760-12/products/1042301/1689862665/mjcb628602pb-1689862665.jpg",
            price:3000
        },
        {
            id:2,
            title:"Dres",
            img:"https://d2j6dbq0eux0bg.cloudfront.net/images/17420198/1177073919.jpg",
            price:1500
        }]});
        setLoading(false);
      /*})
    .catch((err) => console.log(err));*/
  }, []);
  if(data?.clothes == undefined){
    return (<p>Žádné oblečení</p> );
  }
  return(
  <>
    <br/>
    {loading ?
        (<Loader />)
        :
        (<Container>
        <h1>Klubové oblečení</h1>
        {
          data.clothes.length == 0 ?
          (
            <p>Žádné obečení</p>
          ):(
            <ClothesList clothes={data.clothes}/>
          )
        }    
      
      </Container>
    )}
  </>);

  function ClothesList(props){
    function ClothesItem(props){
        var item = props.item;
        return(
        <Col md>
            <Card>
                <Card.Body>
                            <Row>
                                <img src={item.img} alt={item.title} style={{height: '220px', width: '100%', 'object-fit': 'contain'}}/>
                            </Row>
                            <Row className="mb-1">
                                <h2>{item.title}</h2>
                            </Row> 
                            <Row>
                                <Col className="col">
                                <h4 className="ms-1" style={{width:"auto"}}>
                                    Cena: {item.price}Kč
                                </h4>
                                </Col>
                                <Col className="col-auto">
                                <Link to={'/obleceni/detail/'+item.id}><Button ><FontAwesomeIcon icon={faCartPlus}/> Objednat</Button></Link>
                                </Col>
                            </Row>
                </Card.Body>
            </Card>
            <br />
          </Col>);
    }
    function ClothesRow(props){
        var items = [];
        for(var i = props.index; i < props.index+props.length; i++){
            items.push(<ClothesItem item={props.clothes[i]}/>)
        }
        while(items.length < 3){
            items.push(<Col md/>);
        }
        return(
            <Row>
                {items}
            </Row>
        )
    }
    var rows = [];
    for(var i = 0; i < props.clothes.length; i+=3){
        rows.push(<ClothesRow clothes={props.clothes} index={i} length={i+3 <= props.clothes.length ? 3 : props.clothes.length-i}/>)
    }
    return (<>{rows}</>);
  }
};
  
export default Clothes;