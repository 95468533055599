import Overlay from "react-bootstrap/Overlay";
import React from "react";


const OverlayTooltip = ({content, target, show}) => {
  return (
    <Overlay target={target} show={show} placement="top">
      {({
          placement: _placement,
          arrowProps: _arrowProps,
          show: _show,
          popper: _popper,
          hasDoneInitialMeasure: _hasDoneInitialMeasure,
          ...props
        }) => (
        <div
          {...props}
          style={{
            padding: '2px 10px',
            backgroundColor: 'gold',
            color: 'black',
            borderRadius: 3,
            ...props.style,
          }}
        >
          {content}
        </div>
      )}
    </Overlay>
  )
}

export default OverlayTooltip;