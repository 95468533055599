import PageContainer from "../../layout/PageContainer";
import {handleErrorSubmit, setBrowserTabText} from "../../helpers/Functions";
import React, {useState} from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import SuccessAlert from "../../components/overlays/SuccessAlert";
import ErrorAlert from "../../components/overlays/ErrorAlert";
import {useNavigate} from "react-router-dom";
import useUserData from "../../helpers/Auth";
import { FormGroup } from "react-bootstrap";
import {editMatches} from "../../dao";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router-dom";
import { getMatches } from "../../dao";
import { useEffect } from "react";


const EasterAddPair = () => {
  const { eventId } = useParams();
  const { userData, setUserData } = useUserData();
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [matches, setMatches] = useState([]);
  const [unassigned, setUnassigned] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getMatches(eventId)
     .then((response) => response.json())
     .then((response) => {
        setMatches(response.matches);
        setUnassigned(response.options);
      })
      .catch((err) => console.log(err));
    }, [eventId]);

  let handleSubmit = async (e) => {
    e.preventDefault();
    editMatches(eventId, matches)
      .then((response) => {
        if(response.ok){
          setSuccess("Dvojice nahrány!");
        }else{
          setError(handleErrorSubmit(response, "Nezdařilo se nahrát"));
        }
      })
  };

  const handleMatchChange = (matchId, personType, id) => {
    let tempMatches = JSON.parse(JSON.stringify(matches));
    tempMatches[matchId][personType] = id;
    setMatches(tempMatches);
  };
  const addMatch = () =>{
    let tempMatches = JSON.parse(JSON.stringify(matches));
    tempMatches.push({girl:"", boy:""});
    setMatches(tempMatches);
  }

  function MatchesEditor(){
    var res = [];
    matches.forEach((match, matchId) => {
      res.push(
        <Row>
          <Col>
          <FormGroup>
            <Form.Label>Holka:</Form.Label>
            <Form.Select value={match.girl}
              onChange={(e) => {
                handleMatchChange(matchId, "girl", e.target.value);
              }}>
              <option value="">Nikdo</option>
              <PairOptions />
            </Form.Select>
          </FormGroup>
          </Col>
          <Col>
          <FormGroup>
            <Form.Label>Kluk:</Form.Label>
            <Form.Select value={match.boy}
              onChange={(e) => {
                handleMatchChange(matchId, "boy", e.target.value);
              }}>
              <option value="">Nikdo</option>
              <PairOptions />
            </Form.Select>
          </FormGroup>
          </Col>
          <Col>
          <FormGroup>
            <Form.Label>Trojice:</Form.Label>
            <Form.Select value={match.third}
              onChange={(e) => {
                handleMatchChange(matchId, "third", e.target.value);
              }}>
              <option value="">Nikdo</option>
              <PairOptions />
            </Form.Select>
          </FormGroup>
          </Col>
          <Col xs="auto">
            <br/>
            <Button className="btn btn-secondary"><FontAwesomeIcon icon={faTrash} /></Button>
          </Col>
        </Row>);
    });
    return res;
  }

  function PairOptions(){
    var res = [];
    unassigned.forEach(person =>{
      res.push(<option value={person.id}>{person.full_name}</option>)
    });
    return res;
  }

  return (
    <PageContainer>
      <Row>
        <Col><h1>Nahrát rozdělení dvojic</h1></Col>
      </Row>
      <br/>
      {userData?.is_staff && 
        <Form onSubmit={handleSubmit}>
          <Button onClick={addMatch}>Přidat dvojici</Button>
          {MatchesEditor()}
          <Row className="mt-3">
            <Col className="text-end">
              <Button variant="primary" type="submit">Nahrát</Button>
            </Col>
          </Row>
        </Form>
      }
      <ErrorAlert error={error} handleClose={()=> setError("")}/>
      <SuccessAlert title={success} handleClose={()=> navigate("/")}/>
    </PageContainer>
  )
}

export default EasterAddPair;