import Table from "react-bootstrap/Table";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import React, {useEffect, useRef} from "react";
import { useState } from "react";
import {getMembersAdmin} from "../../dao";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faUserPlus, faCoins, faGavel, faCog, faPeopleArrows, faChartSimple, faUserGear} from '@fortawesome/free-solid-svg-icons';
import useUserData from "../../helpers/Auth";
import {isMobileDevice} from "../../helpers/Device";
import Card from "react-bootstrap/Card";
import {handleErrorLoading, setBrowserTabText} from "../../helpers/Functions";
import PageContainer from "../../layout/PageContainer";
import ErrorAlertFullscreen from "../../components/overlays/ErrorAlertFullscreen";
import {AdminAccountTypeOptions, AdminRoleOptions, API_URL} from "../../helpers/Constants";
import {handleCsvExport} from "../../helpers/Exports";
import LoadingCard from "../../components/overlays/LoadingCard";
import FormSelectDict from "../../components/form/FormSelectDict";
import SearchBar from "../../components/form/SearchBar";
import LoadingIcon from "../../components/overlays/LoadingIcon";

const ClubAdmin = () => {
  const { userData, setUserData } = useUserData();
  const [loading, setLoading] = useState(true);
  const [errorRemote, setErrorRemote] = useState("");
  const [members, setMembers] = useState([]);
  const [accountType, setAccountType] = useState("*");
  const [role, setRole] = useState("*");
  const [isExporting, setIsExporting] = useState(false);

  useEffect(() => {
    setLoading(true);
    getMembersAdmin(accountType, role)
      .then((response) => {
        if (!response.ok) {
          setErrorRemote(handleErrorLoading(response));
          return []
        }
        return response.json()
      })
      .then((response) => {
        setMembers(response);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, [accountType, role]);

  if (errorRemote !== "")
    return <ErrorAlertFullscreen error={errorRemote} />
  if (isExporting)
    return <LoadingCard text={"Exportování dat"} />

  setBrowserTabText('Administrace')
  return (
    <PageContainer>
      <Row className="mb-2">
        <Col><h1>Administrace</h1></Col>
        {userData.is_superuser && <Col xs="auto"><Link to={`/admin/server`}><Button variant="outline-secondary"><FontAwesomeIcon icon={faCog}/> Server</Button></Link></Col>}
      </Row>
      <Row>
        {userData.is_staff ? <Col lg className="mb-3"><EventCard/></Col> : <></>}
        {userData.is_superuser ? <Col lg={5} className="mb-3"><UserCard/></Col> : <></>}
        <Col lg className="mb-3"><TableCard/></Col>
      </Row>
      <MemberTable/>
    </PageContainer>
  );

  function EventCard() {
    return (
      <Card className="mb-3 h-100">
        <Card.Body>
          <Card.Title>Nová akce</Card.Title>
          <Row>
            <Col xs="auto">
              <Link to={`/admin/pridat/trenink`}><Button className="mb-2">Trénink</Button></Link>
            </Col>
            <Col xs="auto">
              <Link to={`/admin/pridat/soustredeni`}><Button className="mb-2" variant="secondary">Soustředění</Button></Link>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    )
  }

  function UserCard() {
    return (
      <Card className="mb-3 h-100">
        <Card.Body>
          <Row className="align-items-center">
            <Card.Title>Členové klubu</Card.Title>
            <Col xs="auto"><Link to={`/admin/ucty/pridat`}><Button className="mb-2"><FontAwesomeIcon icon={faUserPlus} /> Přidat</Button></Link></Col>
            <Col xs="auto"><Link to={`/admin/ucty/rozsirene`}><Button className="mb-2" variant="secondary"><FontAwesomeIcon icon={faUserGear} /> Rozšířená správa</Button></Link></Col>
            <Col xs="auto"><Link to={`/admin/ucty/upravit`}>Upravit z csv</Link></Col>
          </Row>
        </Card.Body>
      </Card>
    )
  }

  function TableCard() {
    const downloadLinkRef = useRef(null);
    return (
      <Card className="mb-3 h-100">
        <Card.Body>
          <Row className="align-items-end">
            <Col xs={4} lg={6} xl={4}>
              <FormSelectDict label={"Typ účtu"} value={accountType} setValue={setAccountType} options={AdminAccountTypeOptions} disabled={loading} className={"mb-2"} controlId={"account"} />
            </Col>
            <Col xs={4} lg={6} xl={4}>
              {(accountType === '*' || accountType === 'A') &&
                <FormSelectDict label={"Role"} value={role} setValue={setRole} options={AdminRoleOptions} disabled={loading} className={"mb-2"} controlId={"role"} />
              }
            </Col>
            <Col xs="auto">
              <a href="#" ref={downloadLinkRef} style={{display: 'none'}} download="file.csv">Download</a>
              <a href="#" onClick={() => {
                handleCsvExport(API_URL + "export-club?account=" + accountType + "&role=" + role, 'ucty_' + (new Date()).toISOString() + '.csv', setIsExporting);
              }}>Export</a>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    )
  }

  function MemberTable() {
    const [filteredPeople, setFilteredPeople] = useState(members);

    if (loading)
      return <LoadingIcon />;

    const TableRow = ({element}) => {
      return (
        <tr>
          <td><Link to={`/ucet/${element.id}/statistiky/`} className="no-decoration">{element.reg_number}</Link></td>
          <td><Link to={`/ucet/${element.id}/nastaveni/`} className="no-decoration">{element.full_name}</Link></td>
          {!isMobileDevice() && <td>{element.mail1}</td>}
          <td>{element.account}</td>
          <td>{element.role}</td>
          <td>{element.membership}</td>
          <td align={"center"}><Link to={`/ucet/${element.id}/opravneni/`}><FontAwesomeIcon icon={faPeopleArrows} size="lg"/></Link></td>
          {!isMobileDevice() && <td align={"center"}><Link to={`/ucet/${element.id}/prava/`}><FontAwesomeIcon icon={faGavel} size="lg"/></Link></td>}
          {(!isMobileDevice() && userData.is_accountant) &&
            <td align={"center"}><Link to={`/ucet/${element.id}/vyuctovani/`}><FontAwesomeIcon icon={faCoins} size="lg"/></Link></td>}
        </tr>
      )
    }

    const rows = [];
    for (let i in filteredPeople) {
      const obj = filteredPeople[i];
      rows.push(<TableRow element={obj} key={i}/>);
    }

    return (<>
      <SearchBar data={members} setFilteredPeople={setFilteredPeople}/>
      <Table striped bordered hover responsive>
        <thead>
        <tr>
          <th>Reg. <FontAwesomeIcon icon={faChartSimple}/></th>
          <th>Jméno <FontAwesomeIcon icon={faCog}/></th>
          {!isMobileDevice() && <th>Email primární</th>}
          <th title="Typ účtu">T</th>
          <th title="Role">R</th>
          <th title="Členství">Č</th>
          <th title="Oprávnění">Opr.</th>
          {!isMobileDevice() && <th>Práva</th>}
          {(!isMobileDevice() && userData.is_accountant) && <th>Vyúč.</th>}
        </tr>
        </thead>
        <tbody>
        {rows}
        </tbody>
      </Table>
      <p><i>Filtru odpovídá <b>{rows.length}</b> záznamů</i></p>
    </>)
  }
};


export default ClubAdmin;