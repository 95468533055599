import React, {useEffect, useState} from "react";
import {getPayments, putPayment, deletePayment, postPaymentCsv} from "../../dao";
import Button from "react-bootstrap/Button";
import ErrorAlert from "../../components/overlays/ErrorAlert";
import SuccessAlert from "../../components/overlays/SuccessAlert";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {isMobileDevice} from "../../helpers/Device";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faCoins, faExclamationTriangle, faFileLines, faPencil, faQuestion, faQuestionCircle, faTrash,
} from "@fortawesome/free-solid-svg-icons";
import Table from "react-bootstrap/Table";
import Modal from "react-bootstrap/Modal";
import {
  YearOptions, handleErrorLoading, parseDate,
  parseDateShortYear, verifyFileIsCsv, handleErrorSubmit, setBrowserTabText
} from "../../helpers/Functions";
import ErrorAlertFullscreen from "../../components/overlays/ErrorAlertFullscreen";
import Form from "react-bootstrap/Form";
import {SelectOptionsArray, SelectOptionsDict} from "../../components/form/SelectOptions";
import InputGroup from "react-bootstrap/InputGroup";
import PageContainer from "../../layout/PageContainer";
import LoadingIcon from "../../components/overlays/LoadingIcon";
import LoadingCard from "../../components/overlays/LoadingCard";
import PageHeading from "../../layout/PageHeading";
import {MonthOptions, MonthOptionsPrevious} from "../../helpers/Constants";
import SearchBar from "../../components/form/SearchBar";
import {Link} from "react-router-dom";


const Payments = () => {
  const [year, setYear] = useState((new Date()).getFullYear());
  const [month, setMonth] = useState(0);
  const [unassignedOnly, setUnassignedOnly] = useState(false);
  const [data, setData] = useState({});
  const [payments, setPayments] = useState(undefined);
  const [error, setError] = useState("");
  const [errorRemote, setErrorRemote] = useState("");
  const [success, setSuccess] = useState("");
  const [paymentToEdit, setPaymentToEdit] = useState(null);
  const [user, setUser] = useState(null);
  const [changingAssigned, setChangingAssigned] = useState(false);
  const [paymentToRemove, setPaymentToRemove] = useState(null);
  const [addPayments, setAddPayments] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [isSelected, setIsSelected] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [response, setResponse] = useState(null);
  const [uploadSuccess, setUploadSuccess] = useState("");

  useEffect(() => {
    loadData(year, month, unassignedOnly);
  }, [year, month, unassignedOnly]);

  function loadData(year, month, unassignedOnly){
    setPayments(undefined);
    getPayments(year, month, unassignedOnly)
      .then((response) => {
        if (!response.ok) {
          setErrorRemote(handleErrorLoading(response));
          return [];
        }
        return response.json();
      })
      .then((response) => {
        setData(response);
        setPayments(response.payments);
      });
  }

  const changeHandler = (event) => {
    if(verifyFileIsCsv(event.target.files[0])){
      setSelectedFile(event.target.files[0]);
      setIsSelected(true);
    } else {
      setError("Špatný typ souboru");
    }
  };

  const handleSubmission = () => {
    const formData = new FormData();
    formData.append('File', selectedFile);
    setProcessing(true);
    postPaymentCsv(formData)
      .then((response) => {
        if(response.ok){
          response.json().then(
            (result) => {
              setUploadSuccess("Nahráno");
              setResponse(result);
            });
        }else{
          setError(handleErrorSubmit(response, "Nezdařilo se nahrát"));
        }
        setProcessing(false);
        setAddPayments(false);
      });
  };

  const PaymentInfoTable = ({payment, user}) => {
    return (
      <table>
        <tbody>
        {user !== undefined && <>
          <tr>
            <td>Uživatel:</td>
            <td><b>{paymentToRemove?.full_name == null ? "???" : paymentToRemove?.reg_number + " – " + paymentToRemove?.full_name}</b></td>
          </tr>
        </>}
        <tr>
          <td>Datum:</td>
          <td>{parseDate(payment?.date)}</td>
        </tr>
        <tr>
          <td>Částka:</td>
          <td>{payment?.amount} Kč</td>
        </tr>
        <tr>
          <td> </td>
          <td></td>
        </tr>
        <tr>
          <td>Zpráva pro příjemce:</td>
          <td>{payment?.reference}</td>
        </tr>
        <tr>
          <td>Poznámka:</td>
          <td>{payment?.note}</td>
        </tr>
        <tr>
          <td>Odesílatel:</td>
          <td>{payment?.sender}</td>
        </tr>
        </tbody>
      </table>
    )
  }

  if (processing)
    return <LoadingCard/>;
  if (errorRemote !== "")
    return <ErrorAlertFullscreen error={errorRemote}/>

  setBrowserTabText('Platby');
  return (
    <PageContainer>
      <PageHeading heading={"Platby"} link={"/ucetnictvi/"} label={"Účetnictví"}/>
      <Row><ControlPanel/></Row>
      <hr/>
      <PaymentTable/>
      <Modal show={paymentToRemove != null} onHide={() => setPaymentToRemove(null)}>
        <Modal.Header closeButton>
          <FontAwesomeIcon icon={faQuestionCircle} size='3x' bounce/> &nbsp;
          <Modal.Title>Smazat platbu?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PaymentInfoTable payment={paymentToRemove} user={true}/>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setPaymentToRemove(null)}>
            Zavřít
          </Button>
          <Button variant="primary" onClick={() => removePayment(paymentToRemove.id)}>
            Smazat
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={paymentToEdit != null} onHide={() => setPaymentToEdit(null)}>
        <Modal.Header closeButton>
          <Modal.Title>Editovat{!isMobileDevice() && " platbu"}:</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PaymentInfoTable payment={paymentToEdit} />
          <br/>
          <Form>
            <Form.Group className="mb-3" controlId="form">
              <Form.Label>Uživatel:</Form.Label>
              <InputGroup>
                <Form.Control type="text" style={{ maxWidth: '80%' }} value={user == null ? "NEPŘIŘAZEN!" : user?.reg_number + " – " + user?.full_name}/>
                <Button style={{ width: '20%' }} onClick={() => setChangingAssigned(true)}>Vybrat</Button>
              </InputGroup>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setPaymentToEdit(null)}>
            Zavřít
          </Button>
          <Button variant="primary" onClick={() => editPayment(paymentToEdit.id)}>
            Změnit
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={addPayments} onHide={() => setAddPayments(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Nahrát platby</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="formFile" className="mb-3">
            <p>Zkontroluj, zda jsou v záhlaví tabulky označení sloupců <i>ID operace</i>, <i>Objem</i>, <i>VS</i>, <i>Datum</i>, <i>Název protiúčtu</i>, <i>Poznámka</i>, <i>Zpráva pro příjemce</i>. Na pořadí sloupců nezáleží.</p>
            <Row className="align-items-center">
              <Col xs="auto">
                <Button variant="outline-primary" onClick={() => document.getElementById('csvFileSelect').click()}>Vyber CSV</Button>
                <input className="form-control d-none" id='csvFileSelect' type="file" onChange={changeHandler}></input>
              </Col>
              {isSelected && <Col><b>{selectedFile.name}</b>, velikost: {selectedFile.size} B</Col>}
            </Row>
          </Form.Group>
          <div>
            <Button className="btn btn-primary" disabled={!isSelected} onClick={handleSubmission}>Nahrát</Button>
          </div>
        </Modal.Body>
      </Modal>
      <ChangeAssigned
        show={changingAssigned}
        onHide={() => {setChangingAssigned(false);}}
        people={data.members}
      />
      <ErrorAlert error={error} handleClose={() => setError("")}/>
      <SuccessAlert title={success} handleClose={() => setSuccess("")}/>
      <SuccessAlert title={uploadSuccess} body={<>přidáno: {response?.added}<br/>přeskočeno: {response?.ignored}<br/>nepřiřazeno: {response?.missed}</>} handleClose={()=> setUploadSuccess("")}/>
    </PageContainer>
  )

  function ControlPanel() {
    function UnassignedFormatter() {
      return data?.summary?.unassigned > 0 ? <b style={{color: "red"}}>{data?.summary?.unassigned}</b> : data?.summary?.unassigned;
    }
    return (<>
      <Col md={8}><Card className="mb-3">
        <Card.Body>
          <Form.Group controlId="form">
            <Row>
              <Col md>
                <Form.Label size="sm">Rok:</Form.Label>
                <Form.Select className="mb-3" size="sm" onChange={(e) => setYear(e.target.value)} value={year}>
                  <SelectOptionsArray options={YearOptions()}/>
                </Form.Select>
              </Col>
              <Col md>
                <Form.Label size="sm">Měsíc:</Form.Label>
                <Form.Select className="mb-3" size="sm" onChange={(e) => setMonth(e.target.value)} value={month}>
                  <SelectOptionsDict options={year === (new Date()).getFullYear() ? MonthOptionsPrevious : MonthOptions} />
                </Form.Select>
              </Col>
              <Col md>
                <Form.Label size="sm">Pouze nepřiřazené (<UnassignedFormatter />):</Form.Label>
                <Form.Check size="sm" type="switch"
                            defaultChecked={unassignedOnly} onChange={(e) => {
                  setUnassignedOnly(e.target.checked)
                }}/>
              </Col>
            </Row>
          </Form.Group>
        </Card.Body>
      </Card></Col>
      <Col md={4}>
        <Card className="mb-3">
          <Card.Body>
            <Card.Title>Nahrát platby</Card.Title>
            <Row><Col xs="auto">
              <Button className="mb-2" onClick={() => setAddPayments(true)}><FontAwesomeIcon icon={faFileLines}/> Nahrát</Button>
            </Col></Row>
          </Card.Body>
        </Card>
      </Col>
    </>)
  }

  function editPayment(id) {
    putPayment(id, user.id+"")
      .then((res) => {
        if (res.ok) {
          setSuccess("Upraveno");
          loadData(year, month, unassignedOnly);
        } else {
          setError(handleErrorSubmit(res, "Nezdařilo se upravit"));
        }
        setPaymentToEdit(null);
      })
  }

  function removePayment(id){
    deletePayment(id)
      .then((res) => {
        if (res.ok) {
          setSuccess("Smazáno");
          loadData(year, month, unassignedOnly);
        } else {
          setError(handleErrorSubmit(res, "Nezdařilo se smazat"));
        }
        setPaymentToRemove(null);
      })
  }

  function PaymentTable(){
    const [filteredPayments, setFilteredPayments] = useState(payments);

    if(payments === undefined){
      return(<LoadingIcon />)
    }
    if(payments.length < 1){
      return(<p><i>Žádné nahrané platby v daném období</i></p>)
    }

    const rows = [];
    filteredPayments.forEach((payment)=>{
      const data = payment.user == null ? <i title={payment.reference}><FontAwesomeIcon icon={faQuestion} /> {payment.sender}</i> :
        <>{payment.user.full_name}{payment.user.difference < 0 && <FontAwesomeIcon icon={faExclamationTriangle} style={{marginLeft: "5px"}} color="LimeGreen" title={"dluh " + payment.user.difference}/>}</>;
      const regNumberLink = payment.user == null ? <></> : <Link to={"/ucet/" + payment.user.id + "/vyuctovani"} target="_blank">{payment.user?.reg_number}</Link>;
      rows.push(<tr key={payment.id}>
        <td>{parseDateShortYear(payment.date)}</td>
        {isMobileDevice() ?
          (<td>{data}<br/><i>{regNumberLink}</i></td>) :
          (<>
            <td>{regNumberLink}</td>
            <td>{data}</td>
            <td><i>{payment.reference}</i></td>
          </>)
        }
        <td>{payment.amount}</td>
        <td onClick={() => {
          setPaymentToEdit(payment);
          setUser(payment.user);
        }} className="td-w-icon pointing"><FontAwesomeIcon icon={faPencil} className="link-like" size="lg"/></td>
        <td onClick={() => setPaymentToRemove(payment)} className="td-w-icon pointing">
          <FontAwesomeIcon icon={faTrash} className="link-like" size="lg"/></td>
      </tr>);
    });

    return (<>
      {!unassignedOnly && <SearchBar data={data.payments} setFilteredPeople={setFilteredPayments} topLevel={false} placeholder={"Vyhledat v přiřazených dle jména"} />}
      <Table striped bordered hover responsive>
        <thead>
        <tr>
          <th>Datum</th>
          {isMobileDevice() ? <th>Osoba <FontAwesomeIcon icon={faCoins}/></th> : (<>
            <th>Reg. <FontAwesomeIcon icon={faCoins}/></th>
            <th>Jméno</th>
            <th>Zpráva pro příjemce</th>
          </>)}
          <th>Částka</th>
          <th></th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        {rows}
        </tbody>
      </Table>
    </>);
  }

  function ChangeAssigned(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Přiřadit platbu uživateli
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PeopleTable people={props.people}/>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setChangingAssigned(false)}>Zavřít</Button>
        </Modal.Footer>
      </Modal>
    );

    function PeopleTable(props){
      const [filteredPeople, setFilteredPeople] = useState(props.people);

      if(props.people.length > 0){
        const rows = [];
        for(const i in filteredPeople){
          const person = filteredPeople[i];
          rows.push(
            <tr key={person.id}>
              <td>{person.reg_number}</td>
              <td>{person.full_name}</td>
              <td>
                <Button onClick={()=> {
                  setUser(person);
                  setChangingAssigned(false);
                }}>Vybrat</Button>
              </td>
            </tr>
          );
        }
        return(
          <>
            <SearchBar data={props.people} setFilteredPeople={setFilteredPeople} />
            <Table>
              <tbody>
              {rows}
              </tbody>
            </Table>
          </>
        )
      }
      return(<p>Nenalezeny žádné možnosti.</p>);
    }
  }
};

export default Payments;