import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {getCampsToCopy, putCampCopy} from "../../dao";
import {handleErrorLoading, handleErrorSubmit, parseDate, setBrowserTabText, YearOptions} from "../../helpers/Functions";
import Loader from "../../components/overlays/Loader";
import ErrorAlertFullscreen from "../../components/overlays/ErrorAlertFullscreen";
import PageContainer from "../../layout/PageContainer";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleDot, faFont, faSquareCheck} from "@fortawesome/free-solid-svg-icons";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import {SelectOptionsArray} from "../../components/form/SelectOptions";
import {useNavigate} from "react-router-dom";
import ErrorAlert from "../../components/overlays/ErrorAlert";
import PageHeading from "../../layout/PageHeading";


const CopyCampForm = () => {
  const { eventId } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [errorRemote, setErrorRemote] = useState("");
  const [year, setYear] = useState((new Date()).getFullYear());
  const [data, setData] = useState([]);
  const [event, setEvent] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    getCampsToCopy(eventId, year)
      .then((response) => {
        if (!response.ok) {
          setErrorRemote(handleErrorLoading(response));
          return [];
        }
        return response.json();
      })
      .then((response) => {
        setData(response?.data);
        setEvent(response?.event);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, [eventId, year])

  if (loading)
    return <Loader loading={loading} />
  if (errorRemote !== "")
    return <ErrorAlertFullscreen error={errorRemote} />

  setBrowserTabText('Kopírovat formulář | ' + event?.title);
  return (
    <PageContainer>
      <PageHeading heading={"Kopírovat formulář: " + event?.title} link={"/akce/"+eventId+"/upravit"} label={"Úprava akce"} />
      <Form.Group controlId="year">
        <Row className="mb-2">
          <Col xs="auto"><Form.Label size="sm">Filtrovaný rok:</Form.Label></Col>
          <Col>
            <Form.Select size="sm" onChange={(e) => setYear(e.target.value)} value={year}>
              <SelectOptionsArray options={YearOptions()} />
            </Form.Select>
          </Col>
        </Row>
      </Form.Group>
      <p><i>Zkopírovaný formulář bude připojen na konec stávajícího formuláře.</i></p>
      <CampsTablesSection />
      <ErrorAlert error={error} handleClose={()=>setError("")}/>
    </PageContainer>
  )

  function copyQuestions(source){
    putCampCopy(eventId, source)
      .then((res) => {
        if (res.ok) {
          navigate("/akce/"+eventId+"/upravit");
        } else {
          setError(handleErrorSubmit(res, "Nezdařilo se zkopírovat"));
        }
      })
  }

  function CampsTablesSection() {
    const QuestionTable = ({questions}) => {
      if (questions === undefined) {
        return (<p><i>Žádné otázky</i></p>)
      }
      if (questions.length < 1) {
        return (<p><i>Žádné otázky</i></p>)
      }
      const rows = [];
      const QuestionTypeMarker = (question) => {
        if (!question.closed_ended)
          return <FontAwesomeIcon icon={faFont}/>
        if (!question.multichoice)
          return <FontAwesomeIcon icon={faCircleDot}/>
        else
          return <FontAwesomeIcon icon={faSquareCheck}/>
      }
      questions.forEach((question) => {
        rows.push(<tr key={question.id}>
          <td>{question.index}</td>
          <td>{question.title}{question.required && <b style={{color: "red"}}>*</b>}<br/>
            {question.closed_ended ? <i>{question.choices.data}</i> : <> </>}</td>
          <td>{QuestionTypeMarker(question)} {question.closed_ended && <>({question.choices.count}{question.other_choice && "+1"})</>}</td>
        </tr>);
      });

      return (
        <Table striped hover responsive>
          <thead>
          <tr>
            <th>#</th>
            <th>Otázka</th>
            <th>Typ</th>
          </tr>
          </thead>
          <tbody>
          {rows}
          </tbody>
        </Table>
      );
    }

    let camps = [];
    data.forEach((camp) => {
      camps.push(<div key={camp.id}>
        <hr/>
        <Row><h4>{parseDate(camp.date)} – {camp.title}</h4></Row>
        <Row><QuestionTable questions={camp.questions} /></Row>
        <Row><Col className="text-end"><Button variant="secondary" onClick={() => {copyQuestions(camp.id)}}>Kopírovat</Button></Col></Row>
      </div>)
    })
    if (camps.length === 0)
      return <p><i>Žádné akce s formulářem v roce {year}.</i></p>
    return camps;
  }
}

export default CopyCampForm;