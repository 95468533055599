import {isMobileDevice} from "../../helpers/Device";
import L from "leaflet";
import marker from "../../static/marker.png";
import markerPlain from "../../static/markerPlain.png"


function MapMarker(plain=false) {
  const isMobile = isMobileDevice();

  const iconSize = isMobile ? [28, 42] : [32, 48];
  const iconAnchor = isMobile ? [14, 42] : [16, 48];  // Set the anchor point of the icon (centered, bottom)
  const popupAnchor = isMobile ? [0, -42] : [0, -48];  // Set the anchor point for popups (top, centered)
  const tooltipAnchor = isMobile ? [14, -21] : [16, -24];

  return new L.Icon({
    iconUrl: plain ? markerPlain : marker,
    iconSize: iconSize,
    iconAnchor: iconAnchor,
    popupAnchor: popupAnchor,
    tooltipAnchor: tooltipAnchor,
  });
}


export {MapMarker}