import {getEditEvent, getMirroring} from "../../dao";
import React, { useState, useEffect } from 'react';
import {faPencil } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ErrorAlert from "../../components/overlays/ErrorAlert";
import SuccessAlert from "../../components/overlays/SuccessAlert";
import Loader from "../../components/overlays/Loader";
import EditTraining from "./EditTraining";
import EditCamp from "./EditCamp";
import EditOrisEvent from "./EditOrisEvent";
import { useParams } from "react-router-dom";
import {handleErrorLoading, parseTimezone, setBrowserTabText} from "../../helpers/Functions";
import PageContainer from "../../layout/PageContainer";
import PageHeading from "../../layout/PageHeading";

const EditEvent = (props) => {
  const { eventId } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [event, setEvent] = useState({});
  const [mirrorData, setMirrorData] = useState({});

  useEffect(() => {
    setError("");
    setLoading(true);
    getEditEvent(eventId)
      .then((response) => {
        if(response.ok){
          return response.json();
        }
        setError(handleErrorLoading(response));
      })
      .then((response) => {
        response.term_first = parseTimezone(response.term_first);
        setEvent(response);
        if (response.type === 0) {
          getMirroring(eventId)
            .then((response) => {
              if(response.ok){
                return response.json();
              }
              setError(handleErrorLoading(response));
            })
            .then((response) => setMirrorData(response))
        }
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, [eventId]);

  if (loading)
    return <Loader />;

  setBrowserTabText('Upravit | ' + event?.title);
  return (
    <PageContainer>
      <PageHeading heading={<><FontAwesomeIcon icon={faPencil}/> Upravit: {event?.title}</>} id={eventId} />
      <br/>
      <EditEventBody event={event} />

      <ErrorAlert error={error} handleClose={()=>setError("")}/>
      <SuccessAlert title={success} handleClose={()=>setSuccess("")}/>
    </PageContainer>
  );

  function EditEventBody(props){
    if (props.event === undefined)
      return;
    if(props.event.type === 0){
      return <EditOrisEvent event={props.event} mirrorData={mirrorData}/>;
    }else if(props.event.type === 1){
      return <EditTraining event={props.event}/>;
    }else if(props.event.type === 2){
      return <EditCamp event={props.event}/>;
    }
  }
};
    
export default EditEvent;