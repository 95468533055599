import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faRotate} from "@fortawesome/free-solid-svg-icons";
import React from "react";


export default function ExportOnBackground(props) {
  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <FontAwesomeIcon icon={faRotate} size='3x' spin/> &nbsp;&nbsp;
        <Modal.Title id="contained-modal-title-vcenter">
          Exportování dat
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <i>Stahování bude zahájeno automaticky, neopouštěj tuto stránku, můžeš pokračovat v prohlížení</i>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Zavřít</Button>
      </Modal.Footer>
    </Modal>
  );
}