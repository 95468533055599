import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import AppPragis from "./AppPragis";
import AppTapis from './AppTapis';
import './scss/_nightmode.scss';

const root = ReactDOM.createRoot(document.getElementById('root'));

if (process.env.REACT_APP_THEME === 'PGP') {
  import('./scss/pragis.scss').then(() => {
    root.render(<AppPragis />);
  });
} else {
  import('./scss/tapis.scss').then(() => {
    root.render(<AppTapis />);
  });
}
