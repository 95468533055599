function SelectOptionsArray(props){
  const res = [];
  if(props.options != null){
    props.options.forEach(option => {
      res.push(<option key={option} value={option}>{option}</option>);
    });
  }
  return res;
}

function SelectOptionsSet(props){
  const res = [];
  if(props.options != null){
    for(var i in props.options){
      res.push(<option key={i} value={props.options[i].value}>{props.options[i].name}</option>);
    }
  }
  return res;
}

function SelectOptionsClasses(props){
  const res = [];
  if(props.options != null){
    if (props.type === 0) {
      for (let i in props.options)
        res.push(<option key={i} value={props.options[i].id}>{props.options[i].name}</option>);
    } else {
      props.options.forEach(option => {
        const note = option?.note != null ? " (" + option?.note + ")" : "";
        res.push(<option key={option.name} value={option.name}>{option.name} {note}</option>);
      });
    }
  }
  return res;
}

function SelectOptionsDict(props){
  const res = [];
  if(props.options != null){
    for(let i in props.options){
      res.push(<option key={i} value={i}>{props.options[i]}</option>);
    }
  }
  return res;
}

export {SelectOptionsArray, SelectOptionsSet, SelectOptionsClasses, SelectOptionsDict};