import {faRotate} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Card from 'react-bootstrap/Card';

const LoadingCard = (props) => {
  let text = props.text;
  if (props.text == null || props.text === "")
    text = "Zpracování";
  return (
    <div className="loader-container" style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      <Card>
        <Card.Body style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
          <FontAwesomeIcon icon={faRotate} size='3x' spin/>
          <h3 style={{marginLeft: "5px"}}>{text}</h3>
        </Card.Body>
      </Card>
    </div>
  )
};

export default LoadingCard;