import InputGroup from "react-bootstrap/InputGroup";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMagnifyingGlass} from "@fortawesome/free-solid-svg-icons";
import Form from "react-bootstrap/Form";
import {filterByFullName} from "../../helpers/Functions";
import React from "react";


const SearchBar = ({data, setFilteredPeople, topLevel=true, placeholder="Vyhledat dle jména"}) => {
  return ( <InputGroup className="mb-3">
    <InputGroup.Text id="search-icon"> <FontAwesomeIcon icon={faMagnifyingGlass}/></InputGroup.Text>
    <Form.Control
      id="searchbar"
      placeholder={placeholder}
      defaultValue={sessionStorage.getItem('filterSearchBar') ? sessionStorage.getItem('filterSearchBar') : ""}
      aria-label="Vyhledat"
      aria-describedby="search-icon"
      onChange={(e) => {
        sessionStorage.setItem('filterSearchBar', e.target.value);
        setFilteredPeople(filterByFullName(e.target.value, data, topLevel));
      }}
      onSelect={() => {
        const value = sessionStorage.getItem('filterSearchBar') ? sessionStorage.getItem('filterSearchBar') : "";
        setFilteredPeople(filterByFullName(value, data, topLevel));
      }}
    />
  </InputGroup>)
}

export default SearchBar;