import { faCompass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PageContainer from "../../layout/PageContainer";

const NoPage = () => {
    return (
        <PageContainer>
          <div align="center">
            <h1><FontAwesomeIcon icon={faCompass} spin/> 404</h1>
            <p>Zadaná URL adresa nebyla nalezena. Koukni na buzolu a zkus to znovu.</p>
          </div>
        </PageContainer>
    );
  };
  
  export default NoPage;