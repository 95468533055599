import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleNotch} from "@fortawesome/free-solid-svg-icons";


const LevelIndicator = ({event, lineBreak=false}) => {
  if (event.level == null)
    return;
  switch (event.level) {
    case 0:
      if (event?.is_relay)
        return <>{lineBreak && <br/>}<FontAwesomeIcon icon={faCircleNotch} color="magenta" title="Štafety/Družstva"/></>
      return <>{lineBreak && <br/>}<FontAwesomeIcon icon={faCircleNotch} color="red" title="Republikové"/></>
    case 1:
      return <>{lineBreak && <br/>}<FontAwesomeIcon icon={faCircleNotch} color="blue" title="Béčka"/></>
    default:
      return <></>
  }
}

export default LevelIndicator;