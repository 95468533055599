import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Link, useParams} from "react-router-dom";
import {editWhitelistGroups, editWhitelistPeople, getWhitelist, getWhitelistGroups, getWhitelistPeople} from "../../dao";
import React, { useState, useEffect } from 'react';
import {faMagnifyingGlass, faCircleLeft, faCircleRight, faSquarePen} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loader from "../../components/overlays/Loader";
import ErrorAlertFullscreen from "../../components/overlays/ErrorAlertFullscreen";
import {handleErrorLoading, setBrowserTabText} from "../../helpers/Functions";
import {InputGroup, Form, Button} from "react-bootstrap";
import PageContainer from "../../layout/PageContainer";
import ErrorAlert from "../../components/overlays/ErrorAlert";
import PageHeading from "../../layout/PageHeading";
import Table from "react-bootstrap/Table";
import FormCheckbox from "../../components/form/FormCheckbox";
import Card from "react-bootstrap/Card";

const EventWhiteList = () => {
  const { eventId } = useParams();
  const [loading, setLoading] = useState(true);
  const [errorRemote, setErrorRemote] = useState("");
  const [error, setError] = useState("");
  const [step, setStep] = useState(0);

  const [DO, setDO] = useState(true);
  const [SZ, setSZ] = useState(true);
  const [MZ, setMZ] = useState(true);
  const [DE, setDE] = useState(true);
  const [AD, setAD] = useState(true);
  const [PD, setPD] = useState(true);
  const [VE, setVE] = useState(true);
  const [event, setEvent] = useState({});
  const [whitelistedByGroups, setWhitelistedByGroups] = useState([]);
  const [whitelistedPeople, setWhitelistedPeople] = useState([]);
  const [people, setPeople] = useState([]);
  const [filter, setFilter] = useState("");

  useEffect(() => {
    setLoading(true);
    getWhitelist(eventId)
      .then((response) => {
        if (!response.ok) {
          setErrorRemote(handleErrorLoading(response));
          return [];
        }
        return response.json();
      })
      .then((response) => {
        setEvent(response.event);
        setWhitelistedPeople(response.data);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, [eventId]);

  async function loadPeople(){
    setLoading(true);
    getWhitelistPeople(eventId)
      .then((response)=>{
        if (!response.ok) {
          setErrorRemote(handleErrorLoading(response));
          return [];
        }
        return response.json();
      }).then((response)=>{
        setPeople(response.members);
        setWhitelistedByGroups(response.by_group);
        setLoading(false);
      })
  }

  async function loadGroups() {
    setLoading(true);
    getWhitelistGroups(eventId)
      .then((response) => {
        if (!response.ok) {
          setErrorRemote(handleErrorLoading(response));
          return [];
        }
        return response.json();
      })
      .then((response) => {
        setDO(response?.data?.DO);
        setSZ(response?.data?.SZ);
        setMZ(response?.data?.MZ);
        setDE(response?.data?.DE);
        setAD(response?.data?.AD);
        setPD(response?.data?.PD);
        setVE(response?.data?.VE);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }

  const StepIndicator = () => {
    return (
      <Row className="align-items-baseline">
        <Col xs="auto">
          <Button disabled={step <= 1} onClick={() => setStep(step - 1)}><FontAwesomeIcon icon={faCircleLeft}/> Zpět</Button>
        </Col>
        <Col>
          <div className="step-container">
            {Array.from({length: 4}, (_, index) => (
              <div
                key={index}
                className={`step ${step > index ? 'completed' : ''} ${step === index ? 'current' : ''}`}
              >
                {index}
              </div>
            ))}
          </div>
        </Col>
        <Col xs="auto">
          {step >= 3 ?
            <Link to={"/akce/"+eventId+"/admin"}><Button>Konec <FontAwesomeIcon icon={faCircleRight}/></Button></Link> :
            <Button onClick={stepToNext}>{step === 0 ? <>Edit <FontAwesomeIcon size="lg" icon={faSquarePen}/></> : <>Další <FontAwesomeIcon icon={faCircleRight}/></>}</Button>
          }
        </Col>
      </Row>
    );
  };

  const submitGroups = async () => {
    editWhitelistGroups(eventId, DO, SZ, MZ, DE, AD, PD, VE)
      .then((response) => {
        if(response.ok)
          loadPeople();
        else {
          if (response.status === 401)
            setErrorRemote(handleErrorLoading(response));
          setError("Nezdařilo se uložit");
        }
      });
  }

  const submitPeople = async () => {
    var ids = [];
    people.forEach(person => {
      if(person.whitelisted){
        ids.push(person.id);
      }
    });
    editWhitelistPeople(eventId, ids)
      .then((response) => {
        if(response.ok){
          response.json()
            .then((res) => setWhitelistedPeople(res));
        }else{
          setError("Nezdařilo se uložit");
        }
        setLoading(false);
      });
  }

  if (loading)
    return <Loader loading={loading} />
  if (errorRemote !== "")
    return <ErrorAlertFullscreen error={errorRemote} />

  const StepSelect = () => {
    switch (step) {
      case 1:
        return <>{SelectGroups()}</>;
      case 2:
        return <>{SelectPeople()}</>;
      case 3:
        return <>{ReviewWhitelist()}</>
      default:
        return <>{ViewWhitelist()}</>;
    }
  }

  setBrowserTabText("Whitelist | " + event.title);
  return (
    <PageContainer>
      <PageHeading heading={"Whitelist: " + event.title} link={"/akce/"+eventId+"/admin"} label={"Admin akce"} />
      <StepIndicator/>
      {StepSelect()}
      <ErrorAlert error={error} handleClose={()=>setError("")}/>
    </PageContainer>
  );

  function stepToNext() {
    switch (step) {
      case 0:
        setLoading(true);
        loadGroups().then();
        break;
      case 1:
        setLoading(true);
        submitGroups().then();
        break;
      case 2:
        setLoading(true);
        submitPeople()
          .then(() => {})
        break;
      default:
        break;
    }
    setStep(step + 1);
  }

  function ViewWhitelist() {
    return (<Card style={{minHeight: "400px"}}>
      <Card.Body>
        <h3 className="mt-2 mb-4">Krok 0: Aktuální nastavení</h3>
        <Row>
          <Col md>
            <PeopleWhitelistedTable/>
          </Col>
        </Row>
      </Card.Body>
    </Card>);
  }

  function SelectGroups(){
    return(<Card style={{minHeight: "400px"}}>
      <Card.Body>
        <h3 className="mt-2 mb-4">Krok 1: Výběr skupin</h3>
        <Row>
          <Col>
            <h4>Skupiny k výběru</h4>
            <Form className="ms-3">
              <FormCheckbox label={"Dorost"} value={DO} setValue={setDO} condensed={true} controlId={"checkDO"}/>
              <FormCheckbox label={"Starší žáci"} value={SZ} setValue={setSZ} condensed={true} controlId={"checkSZ"}/>
              <FormCheckbox label={"Mladší žáci"} value={MZ} setValue={setMZ} condensed={true} controlId={"checkMZ"}/>
              <FormCheckbox label={"Děti"} value={DE} setValue={setDE} condensed={true} controlId={"checkDE"}/>
              <FormCheckbox label={"Aktivní dospělí"} value={AD} setValue={setAD} condensed={true} controlId={"checkAD"}/>
              <FormCheckbox label={"Pasivní dospělí"} value={PD} setValue={setPD} condensed={true} controlId={"checkPD"}/>
              <FormCheckbox label={"Veteráni"} value={VE} setValue={setVE} condensed={true} controlId={"checkVE"}/>
            </Form>
          </Col>
        </Row>
      </Card.Body>
    </Card>);
  }

  function SelectPeople() {
    return (<Card style={{minHeight: "400px"}}>
      <Card.Body>
        <h3 className="mt-2 mb-4">Krok 2: Výběr jednotlivců</h3>
        <Row>
          <Col sm className="mb-1">
            <h4>Jednotlivci k výběru</h4>
            <Form>
              <InputGroup className="mb-3">
                <InputGroup.Text id="search-icon"> <FontAwesomeIcon icon={faMagnifyingGlass}/></InputGroup.Text>
                <Form.Control
                  placeholder="Vyhledat dle jména/reg."
                  aria-label="Vyhledat"
                  aria-describedby="search-icon"
                  value={filter}
                  onChange={(e) => setFilter(e.target.value)}
                />
              </InputGroup>
              <PeopleList/>
            </Form>
          </Col>
          <Col sm>
            <h4>Whitelistováni skupinou</h4>
            <PeopleWhitelistedByGroupTable/>
          </Col>
        </Row>
      </Card.Body>
    </Card>);
  }

  function ReviewWhitelist() {
    return (<Card style={{minHeight: "400px"}}>
      <Card.Body>
        <h3 className="mt-2 mb-4">Krok 3: Kontrola</h3>
        <Row>
          <Col md>
            <PeopleWhitelistedTable/>
          </Col>
        </Row>
      </Card.Body>
    </Card>);
  }

  function PeopleList() {
    let res = [];
    people.forEach(person => {
      if (person.full_name.includes(filter) || person.reg_number.includes(filter)) {
        res.push(
          <InputGroup className="ms-3" key={person.id}>
            <Form.Check label={person.reg_number + " " + person.full_name} checked={person.whitelisted}
                        onChange={(e) => handlePersonEdit(person.id, e.target.checked)}/>
          </InputGroup>
        );
      }
    });
    return res;
  }

  function PeopleWhitelistedByGroupTable() {
    if (whitelistedByGroups.length < 1) {
      return (<p><i>Žádní uživatelé na whitelistu skupin</i></p>);
    }
    const rows = [];
    for (const i in whitelistedByGroups) {
      const user = whitelistedByGroups[i];
      rows.push(<tr key={user.id}>
        <td>{user.reg_number}</td>
        <td>{user.full_name}</td>
      </tr>);
    }

    return (
      <Table size="sm" striped responsive>
        <thead>
        <tr>
          <th>Reg.</th>
          <th>Jméno</th>
        </tr>
        </thead>
        <tbody>
        {rows}
        </tbody>
      </Table>
    );
  }

  function PeopleWhitelistedTable() {
    if (whitelistedPeople.length < 1) {
      return (<p><i>Žádní uživatelé na whitelistu</i></p>);
    }
    const rows = [];
    for (const i in whitelistedPeople) {
      const user = whitelistedPeople[i];
      rows.push(<tr key={user.id}>
        <td>{user.reg_number}</td>
        <td>{user.full_name}</td>
        <td>{user.origin}</td>
      </tr>);
    }

    return (<>
      <h4>Uživatelé na whitelistu</h4>
      <Table striped bordered responsive>
        <thead>
        <tr>
          <th>Reg.</th>
          <th>Jméno</th>
          <th title="skupina, prázdné pro jednotlivce">Sk.</th>
        </tr>
        </thead>
        <tbody>
        {rows}
        </tbody>
      </Table>
    </>);
  }

  function handlePersonEdit(id, whitelisted){
    var tempPeople = JSON.parse(JSON.stringify(people));
    for(var i = 0; i < tempPeople.length; i++){
      if(tempPeople[i].id === id){
        tempPeople[i].whitelisted = whitelisted;
      }
    }
    setPeople(tempPeople);
  }

};
export default EventWhiteList;